import React from "react";
import { Component } from "react";
import { Row, Col, Card, CardHeader, CardBody, Alert, Table } from "reactstrap";
import Statistiche from "app/components/Statistiche.js";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import { getLoginSessions } from "app/actions";
import { map, get } from "lodash";
import moment from "moment";
import { connect } from "react-redux";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Row>
        <Col sm={12}>
          <div className="home-page-titolo-studio">
            <img
              style={{ width: 250, maxWidth: "80%", marginBottom: 30 }}
              src="/icon/diapason-logo.png"
            />

            <h1>DiAPAson </h1>

            <h4>
              Daily Activities, Physical Activities and Interpersonal
              relationships{" "}
            </h4>

            <h5>
              USO DEL TEMPO QUOTIDIANO, ATTIVITÀ FISICA, QUALITÀ DELLE CURE E
              RELAZIONI INTERPERSONALI IN PERSONE CON DISTURBI DELLO SPETTRO
              DELLA SCHIZOFRENIA: UNO STUDIO MULTICENTRICO ITALIANO{" "}
            </h5>
          </div>
        </Col>

        <Col sm={12} xl={7}>
          <Card>
            <CardHeader>Statistiche </CardHeader>

            <CardBody>
              <Statistiche />
            </CardBody>
          </Card>
        </Col>

        <Col sm={12} xl={5}>
          <Card>
            <CardHeader>Login effettuati </CardHeader>

            <CardBody className="table-login-effettuati">
              <AsyncData loaderAction={this.props.getLoginSessions}>
                {({ loading, loadingError, data }) => {
                  console.log({ loading, loadingError, data });
                  if (loading) {
                    return <Loading />;
                  }
                  if (loadingError) {
                    return <Alert color="danger">{loadingError.message}</Alert>;
                  }
                  return (
                    <Table responsive={true} bordered={true}>
                      <thead>
                        <tr>
                          <th>Data </th>
                          <th>Ip </th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(data, (row, rowIndex) => (
                          <tr key={rowIndex}>
                            <td>
                              {get(row, "loginTimestamp")
                                ? moment(get(row, "loginTimestamp")).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  )
                                : null}
                            </td>
                            <td>{row.ip}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  );
                }}
              </AsyncData>

              <div className="login-anomalo-info">
                Se risultasse un accesso anomalo si prega di scrivere a:{" "}
                <div>
                  sara.agosta@gmail.com, letizia.casiraghi@gmail.com,
                  czarbo@fatebenefratelli.eu{" "}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = {
  getLoginSessions: getLoginSessions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
