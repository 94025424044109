// <script>

import {
  LOGIN_REQUEST,
  MOBILE_LOGIN_REQUEST,
  LOGIN_SUCCESS,
  MOBILE_LOGIN_SUCCESS,
  LOGIN_FAILURE,
  MOBILE_LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE
} from "app/actions";

export default function authReducer(state = {}, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loggingIn: true
      };

    case MOBILE_LOGIN_REQUEST:
      return {
        loggingIn: true
      };

    case LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.response.data
      };

    case MOBILE_LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.response.data
      };

    case LOGIN_FAILURE:
      return {};

    case MOBILE_LOGIN_FAILURE:
      return {};

    case LOGOUT_REQUEST:
      return {
        ...state,
        loggingOut: true
      };

    case LOGOUT_SUCCESS:
      return {};

    case LOGOUT_FAILURE:
      return {
        ...state,
        loggingOut: undefined
      };

    default:
      return state;
  }
}
