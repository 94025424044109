import React, { Component, Suspense } from "react";
import { Container } from "reactstrap";

import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppBreadcrumb2 as AppBreadcrumb,
  AppSidebarNav2 as AppSidebarNav
} from "@coreui/react";

class Page extends Component {
  render() {
    const { header, sidebar, content } = this.props;

    let containerFluid = !!sidebar;
    let rootClassName = header ? "app" : "app flex-row align-items-center";

    let appBodyStyle = header ? null : { marginTop: 0 };
    let containerStyle = { marginTop: "1.5rem" };

    return (
      <div className={rootClassName}>
        {header && <AppHeader fixed>{header}</AppHeader>}
        <div className="app-body" style={appBodyStyle}>
          {sidebar && (
            <AppSidebar fixed display="lg">
              <AppSidebarHeader />
              <AppSidebarForm />
              {sidebar}
              <AppSidebarFooter />
              <AppSidebarMinimizer />
            </AppSidebar>
          )}
          <main className="main">
            <Container fluid={containerFluid} style={containerStyle}>
              <div className="animated fadeIn">{content}</div>
            </Container>
          </main>
          {/* <AppAside fixed>
            <Suspense fallback={this.loading()}>
              <DefaultAside />
            </Suspense>
          </AppAside> */}
        </div>
        {/* <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter> */}
      </div>
    );
  }
}

export default Page;
