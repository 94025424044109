import React from "react";
import ReactDom from "react-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";

class CreatedPopup extends React.Component {
  redirectToUser = () => {
    this.props.history.push(this.props.userUrl);
    this.props.onEnd();
  };

  redirectToLista = () => {
    this.props.history.push(this.props.listaUrl);
    this.props.onEnd();
  };

  render() {
    const { nameSing, namePlur, user } = this.props;

    return (
      <Modal isOpen={true}>
        <ModalBody>
          Il codice soggetto creato è:
          <h4>{user.codiceUtente}</h4>
          Si prega di prendere nota di tale codice e segnarlo nella checklist
          soggetti e sopra ogni strumento somministrato.
          {user.userType === "Operatore" && user.adminCentro && (
            <div style={{ marginTop: 30 }}>
              Queste sono le credenziali associate all'operatore da usare per il
              login:
              <h4>{user.email}</h4>
              <h4>{user.password}</h4>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.redirectToUser}>
            Vai alla pagina {nameSing}
          </Button>
          {/* <Button color="secondary" onClick={this.redirectToLista}>
          Ritorna alla lista {namePlur}
        </Button> */}
        </ModalFooter>
      </Modal>
    );
  }
}

export default function onCreateRedirect(user, history) {
  let url;
  let nameSing, namePlur;
  if (user.userType === "Controllo") {
    url = "/controlli/";
    nameSing = "controllo";
    namePlur = "controlli";
  } else if (user.userType === "Operatore") {
    url = "/operatori/";
    nameSing = "operatore";
    namePlur = "operatori";
  } else {
    url = "/pazienti/";
    nameSing = "paziente";
    namePlur = "pazienti";
  }

  const listaUrl = url;

  url += user.id + "/dettaglio";

  const div = document.createElement("div");
  document.body.appendChild(div);

  const onEnd = () => {
    ReactDom.unmountComponentAtNode(div);
    div.remove();
  };

  const el = (
    <CreatedPopup
      user={user}
      history={history}
      userUrl={url}
      listaUrl={listaUrl}
      nameSing={nameSing}
      namePlur={namePlur}
      onEnd={onEnd}
    />
  );

  ReactDom.render(el, div);
}
