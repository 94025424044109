import React, { Component } from "react";
import * as router from "react-router-dom";

import { AppSidebarNav2 as AppSidebarNav } from "@coreui/react";

class Sidebar extends Component {
  render() {
    const { items, ...otherProps } = this.props;
    return <AppSidebarNav navConfig={items} {...otherProps} router={router} />;
  }
}

export default Sidebar;
