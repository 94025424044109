import React from "react";
import { Component } from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";
import {
  questionariPerOperatore,
  getQuestionariPerPaziente
} from "../../common/utils/questionari";
import { Link } from "react-router-dom";
import { questionariFacoltativi } from "../../common/utils/questionarioCheck";

import "./StatoQuestionari.scss";

class StatoQuestionari extends Component {
  static propTypes = {
    questionariCompletamento: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  };

  render() {
    const { questionariCompletamento, user } = this.props;
    const userType = user.userType;
    const isPaziente = userType !== "Operatore" && userType !== "Admin";

    if (user.ritirato) {
      return <div className="text-info">Paziente/soggetto ritirato</div>;
    }

    const questionariAll = isPaziente
      ? getQuestionariPerPaziente(user)
      : questionariPerOperatore;

    //TODO: filtra per userType
    const questionari = questionariAll;

    return questionari.map((questionario, index) => {
      const questionarioType = questionario.type;
      const completamento =
        questionariCompletamento && questionariCompletamento[questionarioType];
      const perc =
        (completamento && completamento.percentualeCompletamento) || 0;

      const percString =
        ("" + Math.round(perc * 100) / 100).replace(".", ",") + "%";

      let color;
      let title;

      // let content = questionario.name
      // usa il type perche cosi è piu corto!
      let content = questionario.type;

      if (perc === 0) {
        if (questionariFacoltativi[questionario.type]) {
          color = "secondary";
          title = "Non obbligatorio";
        } else {
          color = "danger";
          title = "Non iniziato";
        }
      } else if (perc === 100) {
        color = "success";
        title = "Completato";
      } else {
        color = "warning";
        title = percString;
      }

      const link = `/user/${user.id}/questionario/${questionarioType}`;

      return (
        <Link key={index} to={link}>
          <Badge className="stato-questionario-item" color={color}>
            <span title={content + ": " + title}>{content}</span>
          </Badge>
        </Link>
      );
    });
  }
}

export default StatoQuestionari;
