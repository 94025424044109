import React, { Component } from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import { Input } from "reactstrap";

const defaultMaxDecimalDigits = 10;

const zeroString = "00000000000000000000000000000000000000000000000000";

function getNumberFormat(decimalDigits) {
  var optionalDecimals = false;
  if (typeof decimalDigits !== "number") {
    decimalDigits = defaultMaxDecimalDigits;
    optionalDecimals = true;
  }
  decimalDigits = Math.min(decimalDigits, 50);

  var decimalPart;
  if (decimalDigits === 0) {
    decimalPart = "";
  } else {
    decimalPart = zeroString.substring(0, decimalDigits);
    if (optionalDecimals) {
      decimalPart = ".[" + decimalPart + "]";
    } else {
      decimalPart = "." + decimalPart;
    }
  }
  return "0" + decimalPart;
}

function toFormattedString(number, format, storeNumberAsString) {
  if (storeNumberAsString) {
    if (!number) {
      return "";
    }
    try {
      number = parseFloat(number);
    } catch (e) {
      number = null;
    }
  }
  if (typeof number !== "number") {
    return "";
  }
  return numeral(number).format(format);
}

function toNumberValue(text, format) {
  return numeral(text).value();
}

function generateFormat(props) {
  var decimalDigits = props.decimalDigits;
  return getNumberFormat(decimalDigits);
}

export default class NumberInput extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    storeNumberAsString: PropTypes.bool,
    placeholder: PropTypes.string,
    decimalDigits: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);

    const format = generateFormat(props);
    this.state = {
      focused: false,
      format: format,
      value: toFormattedString(props.value, format, props.storeNumberAsString),
      numericValue: props.value
    };
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};
    // Prevent changing value via props when input is focused.
    if (!state.focused && "value" in props) {
      const format = generateFormat(props);
      (newState.format = format),
        (newState.value = toFormattedString(
          props.value,
          format,
          props.storeNumberAsString
        ));
      newState.numericValue = props.value;
    }
    return newState;
  }

  // like format, but without the thousands separator
  editingValueFormat() {
    const { format } = this.state;
    return format; //.substring(2);
  }

  toInitialEditingString(value) {
    if (typeof value !== "number") {
      value = "";
    } else {
      const format = this.editingValueFormat();
      value = numeral(value).format(format);
    }
    return value;
  }

  constrainedValue(number) {
    const { min, max } = this.props;
    if (typeof number !== "number") {
      return null;
    }
    if (typeof min === "number" && number < min) {
      return min;
    }
    if (typeof max === "number" && number > max) {
      return max;
    }
    return number;
  }

  toReturnValue(number) {
    if (typeof number !== "number") {
      return null;
    }
    if (this.props.storeNumberAsString) {
      var decimalDigits = this.props.decimalDigits;
      if (typeof decimalDigits !== "number") {
        decimalDigits = defaultMaxDecimalDigits;
      }
      return number.toFixed(decimalDigits);
    }
    return number;
  }

  onBlur(event) {
    const { storeNumberAsString, onBlur } = this.props;
    const { format, numericValue } = this.state;

    const stringValue = toFormattedString(
      numericValue,
      format,
      storeNumberAsString
    );

    this.setState({
      focused: false,
      value: stringValue
    });

    if (onBlur) {
      onBlur(event);
    }
  }

  onFocus(event) {
    const { onFocus } = this.props;
    const { format, value } = this.state;

    let initialValue = toNumberValue(value, format);

    this.setState({
      focused: true,
      value: this.toInitialEditingString(initialValue)
    });

    if (onFocus) {
      onFocus(event);
    }
  }

  onChange(event) {
    const value = event.target.value;
    const { onChange } = this.props;

    const { format } = this.state;
    const numberValue = toNumberValue(value, format);
    const constrainedValue = this.constrainedValue(numberValue);
    const returnValue = this.toReturnValue(constrainedValue);

    this.setState({
      value: value,
      numericValue: returnValue
    });

    if (onChange) {
      onChange(event, returnValue);
    }
  }

  render() {
    const {
      className,
      value: originalValue,
      storeNumberAsString,
      onChange,
      onFocus,
      onBlur,
      decimalDigits,
      min,
      max,
      ...otherProps
    } = this.props;
    const { value } = this.state;

    const displayValue = value || "";

    return (
      <Input
        type="text"
        className={className}
        value={displayValue}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        {...otherProps}
      />
    );
  }
}
