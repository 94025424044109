import React from "react";
import { Component } from "react";
import PropTypes from "prop-types";
import { Button, Progress } from "reactstrap";
import { Prompt } from "react-router-dom";

import "./QuestionarioBottomStateBar.scss";

const AUTOSAVE_DELTA_TIMEOUT = 5 * 1000;

const PREVENT_CLOSE_MESSAGE =
  "Sei sicuro di voler uscire? Controlla prima che tutti i dati siano stati salvati";

class QuestionarioBottomStateBar extends Component {
  static propTypes = {
    userId: PropTypes.any.isRequired,
    questionarioKey: PropTypes.any.isRequired,
    data: PropTypes.object.isRequired,
    hasChanges: PropTypes.bool,
    saveData: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    savingError: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.onbeforeunload = this.onWindowsBeforeUnload;
  }

  componentWillUnmount() {
    if (window.onbeforeunload === this.onWindowsBeforeUnload) {
      window.onbeforeunload = null;
    }
  }

  shouldPreventClose() {
    const { hasChanges } = this.props;
    return hasChanges;
  }

  onWindowsBeforeUnload = () => {
    const isBlockingClose = this.shouldPreventClose();
    if (!isBlockingClose) {
      return;
    }
    return PREVENT_CLOSE_MESSAGE;
  };

  componentDidUpdate(prevProps) {
    if (!this.props.saving && this.props.hasChanges) {
      if (!this._autosaveTimeout) {
        this.startAutosave();
      }
    } else {
      clearTimeout(this._autosaveTimeout);
      this._autosaveTimeout = null;
    }
  }

  startAutosave = () => {
    clearTimeout(this._autosaveTimeout);
    this._autosaveTimeout = setTimeout(
      this.onAutosaveTimeout,
      AUTOSAVE_DELTA_TIMEOUT
    );
  };

  onAutosaveTimeout = () => {
    this._autosaveTimeout = null;
    if (this.props.saving || this.props.disableAutosave) {
      return;
    }
    console.log("Autosaving!");
    this.saveNow();
  };

  saveNow = () => {
    const { userId, questionarioKey, data, saveData } = this.props;
    saveData(userId, questionarioKey, data);
  };

  render() {
    const { data, hasChanges, saving, savingError } = this.props;

    let info;

    if (!hasChanges) {
      info = <div className="text-muted">Tutti i dati sono stati salvati</div>;
    } else if (saving) {
      info = (
        <div className="text-muted">Salvataggio automatico in corso...</div>
      );
    } else if (savingError) {
      info = (
        <div className="text-danger">
          Salvataggio non riuscito:
          <pre>{savingError.message}</pre>
        </div>
      );
    } else {
      info = <div className="text-muted">Ci sono modifiche non salvate</div>;
    }

    const percentualeCompletamento = data.percentualeCompletamento;
    const isCompletato = percentualeCompletamento === 100 && !hasChanges;
    const progressBarColor = isCompletato ? "success" : "primary";
    const progressBarContent = isCompletato
      ? "Completato!"
      : Math.round(percentualeCompletamento) + "%";

    const isBlockingClose = this.shouldPreventClose();

    return (
      <div className="questionario-bottom-state-bar">
        <Prompt
          when={isBlockingClose}
          message={location => PREVENT_CLOSE_MESSAGE}
        />
        <div className="progress-bar-container">
          <Progress
            striped
            color={progressBarColor}
            value={Math.max(10, percentualeCompletamento)}
          >
            {progressBarContent}
          </Progress>
        </div>
        <div className="questionario-bottom-state">
          <div className="info-container">{info}</div>
          <div className="buttons-container">
            <Button
              color="success"
              disabled={!hasChanges || saving}
              onClick={this.saveNow}
            >
              Salva
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default QuestionarioBottomStateBar;
