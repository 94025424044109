import React from "react";
import { Component } from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";

class HaDatiActigrafo extends Component {
  static propTypes = {
    user: PropTypes.object
  };

  render() {
    const { user, asInput } = this.props;

    let content = null;

    if (user && user.fileActigrafoGrezziPath) {
      content = (
        <span style={{ display: "block", textAlign: "center", fontSize: 18 }}>
          ☑
        </span>
      );
    }

    // if(asInput) {
    //   return <Input disabled value={content || ' - '} />
    // }

    return content;
  }
}

export default HaDatiActigrafo;
