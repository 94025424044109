import React from "react";
import { Component } from "react";
import { get, map } from "lodash";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import { Alert, Table } from "reactstrap";
import { getStatsTotali, getStats } from "app/actions";
import { connect } from "react-redux";

class Statistiche extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        {get(this.props, "user.userType") === "Admin" ? (
          <div style={{ marginBottom: 40 }}>
            <span style={{ fontWeight: "bold", marginBottom: 5 }}>
              TOTALE:{" "}
            </span>

            <AsyncData loaderAction={this.props.getStatsTotali}>
              {({ loading, loadingError, data }) => {
                console.log({ loading, loadingError, data });
                if (loading) {
                  return <Loading />;
                }
                if (loadingError) {
                  return <Alert color="danger">{loadingError.message}</Alert>;
                }
                return (
                  <Table responsive={true} bordered={true} striped={true}>
                    <thead>
                      <tr>
                        <th style={{}}>Tipo di soggetto </th>
                        <th style={{}}>Soggetti in reclutamento </th>
                        <th style={{}}>Soggetti ritirati </th>
                        <th style={{}}>Soggetti esclusi </th>
                        <th style={{}}>Soggetti completati </th>
                        <th style={{}}>Soggetti ESM/Actigrafo </th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(data, (row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td style={{}}>{row.userType}</td>
                          <td style={{}}>{row.creati}</td>
                          <td style={{}}>{row.ritirati}</td>
                          <td style={{}}>{row.esclusi}</td>
                          <td style={{}}>
                            {get(row, "completati")} /{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {get(row, "nonCompletati")}
                            </span>
                          </td>
                          <td style={{}}>
                            {get(row, "userType") == "Operatore" ? (
                              " "
                            ) : (
                              <>
                                {get(row, "esm_completati")} /{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {get(row, "esm")}
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                );
              }}
            </AsyncData>
          </div>
        ) : null}

        <AsyncData loaderAction={this.props.getStats}>
          {({ loading, loadingError, data }) => {
            console.log({ loading, loadingError, data });
            if (loading) {
              return <Loading />;
            }
            if (loadingError) {
              return <Alert color="danger">{loadingError.message}</Alert>;
            }
            return (
              <Table responsive={true} bordered={true} striped={true}>
                <thead>
                  <tr>
                    <th style={{}}>Centro </th>
                    <th style={{}}>Tipo di soggetto </th>
                    <th style={{}}>Soggetti in reclutamento </th>
                    <th style={{}}>Soggetti ritirati </th>
                    <th style={{}}>Soggetti completati </th>
                  </tr>
                </thead>
                <tbody>
                  {map(data, (row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td style={{}}>{row.codiceCentro}</td>
                      <td style={{}}>{row.userType}</td>
                      <td style={{}}>{row.creati}</td>
                      <td style={{}}>{row.ritirati}</td>
                      <td style={{}}>
                        {get(row, "completati")} /{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {get(row, "nonCompletati")}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            );
          }}
        </AsyncData>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: get(state, "auth.user")
  };
};
const mapDispatchToProps = {
  getStatsTotali: getStatsTotali,
  getStats: getStats
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Statistiche);
