import React from "react";
import { Component } from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";

class LinkDownloadFileActigrafo extends Component {
  static propTypes = {
    user: PropTypes.object
  };

  render() {
    const { user, asInput } = this.props;

    let content = null;

    if (user && user.fileActigrafoGrezziPath) {
      let lastSlashIndex = user.fileActigrafoGrezziPath.lastIndexOf("/") + 1;
      const fileName = user.fileActigrafoGrezziPath.substring(lastSlashIndex);
      content = (
        <a href={user.fileActigrafoGrezziPath} target="_blank">
          {fileName}
        </a>
      );
    }

    return content;
  }
}

export default LinkDownloadFileActigrafo;
