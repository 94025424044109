import React from "react";
import { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  ModalFooter
} from "reactstrap";
import { get, map } from "lodash";
import fpSet from "lodash/fp/set";
import { getCodiciCentroPossibilePerUser } from "common/utils/codiceCentro.js";
import DatetimePicker from "app/elements/DatetimePicker";
import NumberInput from "app/elements/NumberInput";
import { updatePasswordOperatore } from "app/actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class OperatoreForm extends Component {
  static propTypes = {
    operatore: PropTypes.object.isRequired,
    updateOperatore: PropTypes.func,
    operatoreErrors: PropTypes.object,
    isCreate: PropTypes.bool,
    isEditable: PropTypes.bool
  };

  static defaultProps = {
    isCreate: false,
    isEditable: true
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  creaNuovaPassword = () => {
    this.props
      .updatePasswordOperatore(get(this.props, "operatore.id"))
      .then(action => {
        if (action.callApiSuccess) {
          this.setState(
            fpSet(
              "operatorePasswordModal.result",
              get(action, "response"),
              this.state
            )
          );
        } else {
          this.setState(
            fpSet(
              "operatorePasswordModal.error",
              get(action, "response"),
              this.state
            )
          );
        }
      });
  };

  render() {
    return (
      <Row>
        <Col sm="12">
          <Form>
            <FormGroup>
              <Label>Codice operatore </Label>

              <Input
                type="text"
                placeholder="Autogenerato..."
                disabled={{
                  elementType: "variable",
                  context: "constant",
                  value: true
                }}
                value={get(this.props, "operatore.codiceUtente") || ""}
                onChange={event => {
                  this.props.updateOperatore(
                    fpSet(
                      "codiceUtente",
                      event.target.value,
                      this.props.operatore
                    )
                  );
                }}
                invalid={
                  !!get(this.props, "operatoreErrors.errors.codiceUtente")
                }
              />
            </FormGroup>

            <FormGroup>
              <Label>Centro </Label>

              <Input
                type="select"
                value={get(this.props, "operatore.codiceCentro") || ""}
                onChange={event => {
                  this.props.updateOperatore(
                    fpSet(
                      "codiceCentro",
                      event.target.value,
                      this.props.operatore
                    )
                  );
                }}
                invalid={
                  !!get(this.props, "operatoreErrors.errors.codiceCentro")
                }
              >
                <option></option>
                {map(
                  getCodiciCentroPossibilePerUser(get(this.props, "user")),
                  (option, index) => {
                    const value = get(option, "codiceCentro");
                    const label = option.label;
                    return (
                      <option key={index} value={value}>
                        {label}
                      </option>
                    );
                  }
                )}
              </Input>

              <FormFeedback>
                {get(this.props, "operatoreErrors.errors.codiceCentro")}
              </FormFeedback>
            </FormGroup>

            {get(this.props, "operatore.id") &&
            get(this.props, "operatore.adminCentro") ? (
              <>
                <hr />

                <h5>Password operatore </h5>

                <FormGroup>
                  <Button
                    color="warning"
                    onClick={() => {
                      this.setState(
                        fpSet("operatorePasswordModal", {}, this.state)
                      );
                    }}
                  >
                    Modifica password{" "}
                  </Button>

                  <FormText>
                    Una nuova password verrà generata e la vecchia password
                    smetterà di funzionare. La nuova password verrà mostrata
                    solo una volta.{" "}
                  </FormText>
                </FormGroup>

                <Modal
                  size="md"
                  isOpen={!!get(this.state, "operatorePasswordModal")}
                >
                  <ModalHeader
                    toggle={() => {
                      this.setState(
                        fpSet("operatorePasswordModal", false, this.state)
                      );
                    }}
                  >
                    {!!get(this.state, "operatorePasswordModal") && (
                      <>Nuova password operatore </>
                    )}
                  </ModalHeader>

                  <ModalBody>
                    {!!get(this.state, "operatorePasswordModal") && (
                      <>
                        {!get(this.state, "operatorePasswordModal.result") ? (
                          "Clicca il pulsante per generare una nuova password. La vecchia password non sarà più valida."
                        ) : (
                          <Row>
                            <Col sm="12">
                              <div style={{ marginBottom: 20 }}>
                                Le nuove credenziali sono:{" "}
                              </div>
                              <Alert color="success">
                                Nome utente:{" "}
                                {get(
                                  this.state,
                                  "operatorePasswordModal.result.data.user.email"
                                ) || ""}
                                <div />
                                Password:{" "}
                                {get(
                                  this.state,
                                  "operatorePasswordModal.result.data.password"
                                ) || ""}
                              </Alert>
                              Prendine nota. Non potrà più essere visualizzata.{" "}
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  </ModalBody>

                  <ModalFooter>
                    {!!get(this.state, "operatorePasswordModal") && (
                      <>
                        {!get(this.state, "operatorePasswordModal.result") ? (
                          <>
                            {get(this.state, "operatorePasswordModal.error") &&
                            get(this.state, "operatorePasswordModal.error")
                              .message ? (
                              <div className="text-danger">
                                {
                                  get(
                                    this.state,
                                    "operatorePasswordModal.error"
                                  ).message
                                }
                              </div>
                            ) : null}

                            <Button
                              color="secondary"
                              onClick={() => {
                                this.setState(
                                  fpSet(
                                    "operatorePasswordModal",
                                    false,
                                    this.state
                                  )
                                );
                              }}
                            >
                              Annulla{" "}
                            </Button>

                            <Button
                              color="primary"
                              onClick={() => {
                                this.creaNuovaPassword();
                              }}
                            >
                              Crea nuova password{" "}
                            </Button>
                          </>
                        ) : (
                          <Button
                            color="secondary"
                            onClick={() => {
                              this.setState(
                                fpSet(
                                  "operatorePasswordModal",
                                  false,
                                  this.state
                                )
                              );
                            }}
                          >
                            Ok{" "}
                          </Button>
                        )}
                      </>
                    )}
                  </ModalFooter>
                </Modal>
              </>
            ) : null}

            <hr />

            <h5>Dati operatore </h5>

            <FormGroup>
              <Label>Data di nascita </Label>

              <DatetimePicker
                date={true}
                value={get(this.props, "operatore.dataDiNascita") || null}
                onChange={(event, value) => {
                  this.props.updateOperatore(
                    fpSet("dataDiNascita", value, this.props.operatore)
                  );
                }}
                invalid={
                  !!get(this.props, "operatoreErrors.errors.dataDiNascita")
                }
              />
            </FormGroup>

            <FormGroup>
              <Label>Età </Label>

              <NumberInput
                value={get(this.props, "operatore.etaAllInizio") || null}
                onChange={(event, value) => {
                  this.props.updateOperatore(
                    fpSet("etaAllInizio", value, this.props.operatore)
                  );
                }}
                invalid={
                  !!get(this.props, "operatoreErrors.errors.etaAllInizio")
                }
              />
            </FormGroup>

            <FormGroup>
              <Label>Genere </Label>

              <Input
                type="select"
                value={get(this.props, "operatore.genere") || ""}
                onChange={event => {
                  this.props.updateOperatore(
                    fpSet("genere", event.target.value, this.props.operatore)
                  );
                }}
                invalid={!!get(this.props, "operatoreErrors.errors.genere")}
              >
                <option value={""}></option>
                <option value={"Maschio"}>Maschio</option>
                <option value={"Femmina"}>Femmina</option>
              </Input>
            </FormGroup>

            <hr />

            <h5>Dati sperimentazione </h5>

            <FormGroup style={{ marginTop: 20 }} check={true}>
              <Input
                type="checkbox"
                checked={!!get(this.props, "operatore.completato")}
                onChange={event => {
                  this.props.updateOperatore(
                    fpSet(
                      "completato",
                      event.target.checked,
                      this.props.operatore
                    )
                  );
                }}
                invalid={!!get(this.props, "operatoreErrors.errors.completato")}
              />

              <Label check={true}>Raccolta dati completata </Label>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: get(state, "auth.user")
  };
};
const mapDispatchToProps = {
  updatePasswordOperatore: updatePasswordOperatore
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OperatoreForm);
