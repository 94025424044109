import React from "react";
import { Component } from "react";
import PageSidebar from "app/pages/Sidebar";

const sidebarItems = {
  items: [
    {
      name: "Home",
      url: "/",
      icon: "TODO: not-done-yet",
      attributes: {
        exact: true
      }
    },
    {
      name: "Lista pazienti",
      url: "/pazienti",
      icon: "TODO: not-done-yet",
      attributes: {
        exact: true
      }
    },
    {
      name: "Lista controlli",
      url: "/controlli",
      icon: "TODO: not-done-yet",
      attributes: {
        exact: true
      }
    },
    {
      name: "Lista operatori",
      url: "/operatori",
      icon: "TODO: not-done-yet",
      attributes: {
        exact: true
      }
    },
    {
      name: "Esporta dati",
      url: "/dati/esporta",
      icon: "TODO: not-done-yet",
      attributes: {
        exact: true
      }
    },
    {
      name: "Training",
      url: "/training",
      icon: "TODO: not-done-yet",
      attributes: {
        exact: true
      }
    }
  ]
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <PageSidebar items={sidebarItems} />;
  }
}

export default Sidebar;
