import React from "react";
import { Component } from "react";
import { Row, Col, Form } from "reactstrap";
import { map, get } from "lodash";
import DomandaQuestionario from "app/elements/DomandaQuestionario.js";
import PropTypes from "prop-types";

class QuestionarioForm extends Component {
  static propTypes = {
    questionario: PropTypes.object.isRequired,
    questionarioData: PropTypes.object.isRequired,
    updateRisposteQuestionario: PropTypes.func.isRequired,
    updateRisposteErrors: PropTypes.object
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Row>
        <Col sm="12">
          <Form>
            {map(
              get(this.props, "questionario.domande"),
              (domanda, domandaIndex) => (
                <React.Fragment key={domandaIndex}>
                  <DomandaQuestionario
                    questionarioData={get(this.props, "questionarioData")}
                    domanda={domanda}
                    risposte={get(this.props, "questionarioData.risposte")}
                    updateRisposteQuestionario={get(
                      this.props,
                      "updateRisposteQuestionario"
                    )}
                    updateRisposteErrors={get(
                      this.props,
                      "updateRisposteErrors"
                    )}
                  />
                </React.Fragment>
              )
            )}
          </Form>
        </Col>
      </Row>
    );
  }
}

export default QuestionarioForm;
