import { some, each, find } from "lodash";
const pathToRegexp = require("path-to-regexp");

// routes

export const routes = [
  {
    path: "/",
    ui: [
      { userType: false, component: "Login", page: "empty" },
      { component: "Main", page: "main" }
    ]
  },
  {
    path: "/pazienti",
    ui: [
      {
        userType: ["Admin", "Operatore"],
        component: "ListaPazienti",
        page: "main"
      }
    ]
  },
  {
    path: "/pazienti/:id/dettaglio",
    ui: [
      {
        userType: ["Admin", "Operatore"],
        component: "DettaglioPaziente",
        page: "main"
      }
    ]
  },
  {
    path: "/controlli",
    ui: [
      {
        userType: ["Admin", "Operatore"],
        component: "ListaControlli",
        page: "main"
      }
    ]
  },
  {
    path: "/controlli/:id/dettaglio",
    ui: [
      {
        userType: ["Admin", "Operatore"],
        component: "DettaglioControllo",
        page: "main"
      }
    ]
  },
  {
    path: "/operatori",
    ui: [
      {
        userType: ["Admin", "Operatore"],
        component: "ListaOperatori",
        page: "main"
      }
    ]
  },
  {
    path: "/operatori/:id/dettaglio",
    ui: [
      {
        userType: ["Admin", "Operatore"],
        component: "DettaglioOperatore",
        page: "main"
      }
    ]
  },
  {
    path: "/user/:userId/questionario/:questionarioKey",
    ui: [
      {
        userType: ["Admin", "Operatore"],
        component: "Questionario",
        page: "main"
      }
    ]
  },
  {
    path: "/dati/esporta",
    ui: [
      {
        userType: ["Admin", "Operatore"],
        component: "EsportaDati",
        page: "main"
      }
    ]
  },
  {
    path: "/training",
    ui: [
      { userType: ["Admin", "Operatore"], component: "Training", page: "main" }
    ]
  },
  {
    path: "/mobile-app/ui/",
    ui: [{ component: "mobile/MobileUI", page: "mobile" }]
  }
];

export const routeUnauthorized = null;

routes.forEach(function(route) {
  if (route.path) {
    if (Array.isArray(route.path)) {
      route.regexp = route.path.map(p => pathToRegexp(p));
    } else {
      route.regexp = pathToRegexp(route.path);
    }
  }
});

// pages

export const pages = {
  main: { header: "Header", sidebar: "Sidebar" },
  empty: { header: false, sidebar: false },
  mobile: { header: "mobile/Header", sidebar: false }
};
export const defaultPage = "main";

// helpers

function matchRegexp(route, path) {
  if (!route.regexp) {
    return false;
  }
  let regexps = route.regexp;
  if (!Array.isArray(regexps)) {
    regexps = [regexps];
  }
  return some(regexps, regexp => {
    return regexp.test(path);
  });
}

export function getUIForUser(route, user) {
  if (Array.isArray(route.ui)) {
    const userRoute = find(route.ui, ui => {
      if (typeof ui.userType === "undefined") {
        return true;
      }
      if (ui.userType) {
        if (!user) {
          return false;
        }
        if (ui.userType === true) {
          return true;
        }
        let types = ui.userType;
        if (!Array.isArray(types)) {
          types = [types];
        }
        return some(types, t => user.userType === t);
      } else {
        // user not logged
        return !user;
      }
    });
    return userRoute;
  } else {
    return route.ui;
  }
}

export function routeForPath(path, user) {
  for (var i = 0; i < routes.length; i++) {
    var route = routes[i];
    if (!route.path) {
      return route;
    } else if (matchRegexp(route, path)) {
      if (getUIForUser(route, user)) {
        return route;
      } else {
        return routeUnauthorized || null;
      }
    }
  }
  return null;
}
