import React from "react";
import { Component } from "react";
import { Row, Col, Card, CardBody, Form, Input, Button } from "reactstrap";
import { get } from "lodash";
import fpSet from "lodash/fp/set";
import { mobileLogin } from "app/actions";
import { connect } from "react-redux";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  login = () => {
    this.setState(fpSet("loginError", null, this.state));

    this.props
      .mobileLogin(
        get(this.state, "data.codiceUtente"),
        get(this.state, "data.connectionPin"),
        get(this.props, "notificationToken")
      )
      .then(action => {
        if (!action.callApiSuccess) {
          this.setState(
            fpSet("loginError", get(action, "response"), this.state)
          );
        }
      });
  };

  render() {
    return (
      <Row className="justify-content-center" style={{ marginTop: 40 }}>
        <Col md={8}>
          <Card className="p-4">
            <CardBody>
              <Form>
                <h1>Benvenuto! </h1>

                <Input
                  className="mb-3"
                  type="text"
                  bsSize="lg"
                  placeholder="Codice partecipante"
                  value={get(this.state, "data.codiceUtente") || ""}
                  onChange={event => {
                    this.setState(
                      fpSet("data.codiceUtente", event.target.value, this.state)
                    );
                  }}
                  invalid={!!get(this.state, "loginError.errors.codiceUtente")}
                />

                <Input
                  className="mb-3"
                  type="password"
                  bsSize="lg"
                  placeholder="Codice di controllo"
                  value={get(this.state, "data.connectionPin") || ""}
                  onChange={event => {
                    this.setState(
                      fpSet(
                        "data.connectionPin",
                        event.target.value,
                        this.state
                      )
                    );
                  }}
                  invalid={!!get(this.state, "loginError.errors.connectionPin")}
                />

                <Button
                  color="primary"
                  onClick={() => {
                    this.login();
                  }}
                >
                  Collega{" "}
                </Button>

                {!get(this.state, "loginError.success") && (
                  <div className="text-danger">
                    {get(this.state, "loginError.message")}
                  </div>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = {
  mobileLogin: mobileLogin
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
