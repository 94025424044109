import React from "react";
import { Component } from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";
import { questionariFacoltativi } from "../../common/utils/questionarioCheck";

class StatoQuestionario extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    questionarioType: PropTypes.string.isRequired,
    questionariCompletamento: PropTypes.object.isRequired
  };

  render() {
    const { questionarioType, questionariCompletamento, user } = this.props;
    const completamento =
      questionariCompletamento && questionariCompletamento[questionarioType];
    const perc = (completamento && completamento.percentualeCompletamento) || 0;

    const percString =
      ("" + Math.round(perc * 100) / 100).replace(".", ",") + "%";

    let color;
    let content;
    if (perc === 0) {
      if (questionariFacoltativi[questionarioType]) {
        color = "secondary";
        content = "Non obbligatorio";
      } else {
        color = "danger";
        content = "Non iniziato";
      }
    } else if (perc === 100) {
      color = "success";
      content = "Completato";
    } else {
      color = "warning";
      content = percString;
    }

    let otherInfo = null;
    if (user.ritirato) {
      otherInfo = <div className="text-info">Paziente/soggetto ritirato</div>;
    }

    return (
      <div>
        {otherInfo}
        <Badge color={color}>{content}</Badge>
      </div>
    );
  }
}

export default StatoQuestionario;
