import React from "react";
import { Component } from "react";
import {
  Row,
  Col,
  Alert,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import {
  getSmartphoneConnectionForPaziente,
  updateSmartphoneConnection,
  sendTestNotification
} from "app/actions";
import { get, map } from "lodash";
import generateConnectionPin from "app/utils/generateConnectionPin.js";
import moment from "moment";
import fpSet from "lodash/fp/set";
import downloadFile from "app/utils/downloadFile.js";
import CaricaDatiActigrafo from "app/elements/CaricaDatiActigrafo.js";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class Obiettivo2 extends Component {
  static propTypes = {
    paziente: PropTypes.object.isRequired
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Row>
        <Col sm="12">
          <AsyncData
            loaderAction={this.props.getSmartphoneConnectionForPaziente}
            loaderArgs={[get(this.props, "paziente.id")]}
            reloadTimeout={4000}
            saveAction={this.props.updateSmartphoneConnection}
          >
            {({
              loading,
              loadingError,
              data,
              setData,
              hasChanges,
              saveData,
              saving,
              savingError,
              cancelChanges
            }) => {
              console.log({
                loading,
                loadingError,
                data,
                hasChanges,
                saving,
                savingError
              });
              if (loading) {
                return <Loading />;
              }
              if (loadingError) {
                return <Alert color="danger">{loadingError.message}</Alert>;
              }
              return (
                <>
                  {get(savingError, "message") ? (
                    <Alert color="danger">
                      Errore nel salvataggio: <br />
                      {get(savingError, "message")}
                    </Alert>
                  ) : null}

                  <Row>
                    <Col xs="6" sm="4" md="3">
                      Smartphone:{" "}
                    </Col>

                    <Col xs="6" sm="4">
                      {get(data, "connected") ? (
                        <>
                          <div
                            className="text-success"
                            style={{ display: "inline-block" }}
                          >
                            Collegato{" "}
                          </div>

                          <Button
                            className=""
                            style={{ display: "inline-block", marginLeft: 30 }}
                            color="info"
                            outline={true}
                            onClick={() => {
                              saveData(get(this.props, "paziente.id"), {
                                connected: false,
                                connectionPin: null,
                                notificationToken: null
                              });
                            }}
                          >
                            Scollega{" "}
                          </Button>
                        </>
                      ) : (
                        <>
                          <div
                            className="text-warning"
                            style={{ display: "inline-block" }}
                          >
                            Non collegato{" "}
                          </div>

                          {get(data, "connectionPin") ? (
                            <Button
                              style={{
                                display: "inline-block",
                                marginLeft: 30
                              }}
                              color="info"
                              outline={true}
                              onClick={() => {
                                saveData(get(this.props, "paziente.id"), {
                                  connected: false,
                                  connectionPin: null,
                                  notificationToken: null
                                });
                              }}
                            >
                              Annulla{" "}
                            </Button>
                          ) : (
                            <Button
                              style={{
                                display: "inline-block",
                                marginLeft: 30
                              }}
                              color="info"
                              outline={true}
                              onClick={() => {
                                saveData(get(this.props, "paziente.id"), {
                                  connected: false,
                                  connectionPin: generateConnectionPin(),
                                  notificationToken: null
                                });
                              }}
                            >
                              Collega{" "}
                            </Button>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>

                  {get(data, "connected") ? (
                    <Row>
                      <Col>
                        <Button
                          className=""
                          color="info"
                          size="lg"
                          outline={true}
                          onClick={() => {
                            this.props
                              .sendTestNotification(
                                get(this.props, "paziente.id")
                              )
                              .then(action => {
                                if (action.callApiSuccess) {
                                  alert(
                                    "Notifica inviata, verifica sul dispositivo."
                                  );
                                } else {
                                  alert("Errore nell'invio della notifica");
                                }
                              });
                          }}
                        >
                          Test notifica{" "}
                        </Button>
                      </Col>
                    </Row>
                  ) : get(data, "connectionPin") ? (
                    <Row>
                      <Col>
                        <Alert style={{ margin: 40, padding: 40 }} color="info">
                          <h5>
                            Per collegare lo smartphone usa i seguenti dati:{" "}
                          </h5>

                          <Row>
                            <Col>
                              <span
                                style={{
                                  fontSize: "1.1em",
                                  width: 150,
                                  display: "inline-block",
                                  marginLeft: 40
                                }}
                              >
                                Codice partecipante:{" "}
                              </span>

                              <span style={{ fontSize: "1.2em" }}>
                                {get(this.props, "paziente.codiceUtente")}
                              </span>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <span
                                style={{
                                  fontSize: "1.1em",
                                  width: 150,
                                  display: "inline-block",
                                  marginLeft: 40
                                }}
                              >
                                Codice di controllo:{" "}
                              </span>

                              <span style={{ fontSize: "1.2em" }}>
                                {get(data, "connectionPin")}
                              </span>
                            </Col>
                          </Row>
                        </Alert>
                      </Col>
                    </Row>
                  ) : null}
                </>
              );
            }}
          </AsyncData>
        </Col>

        <Col style={{ marginTop: 40 }} sm="12">
          Notifiche:{" "}
        </Col>

        <Col className="app-smartphone-notifiche-tabella" sm="12">
          <Table responsive={true} bordered={true}>
            <thead>
              <tr>
                <th>Data invio (prevista) </th>
                <th>Data invio (effettivo) </th>
                <th>Data ricezione </th>
                <th>Data risposta </th>
              </tr>
            </thead>
            <tbody>
              {map(
                get(this.props, "paziente.notifiche") || [],
                (row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td>
                      {get(row, "dataInvio")
                        ? moment(get(row, "dataInvio")).format(
                            "DD/MM/YYYY HH:mm"
                          )
                        : null}
                    </td>
                    <td>
                      {map(
                        get(row, "dateInvioEffettivo"),
                        (dataInvioEffettivo, index) => (
                          <React.Fragment key={index}>
                            <div>
                              {dataInvioEffettivo
                                ? moment(dataInvioEffettivo).format(
                                    "DD/MM/YYYY HH:mm"
                                  )
                                : null}
                            </div>
                          </React.Fragment>
                        )
                      )}
                    </td>
                    <td>
                      {get(row, "dataAperturaNotifica")
                        ? moment(get(row, "dataAperturaNotifica")).format(
                            "DD/MM/YYYY HH:mm"
                          )
                        : null}
                    </td>
                    <td>
                      {get(row, "dataCompilazione")
                        ? moment(get(row, "dataCompilazione")).format(
                            "DD/MM/YYYY HH:mm"
                          )
                        : null}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </Col>

        <Col sm="12">
          <hr style={{ marginTop: 40, marginBottom: 40 }} />
        </Col>

        <Col sm="12">
          Carica i dati dell&#x27;actigrafo <div style={{ marginTop: 20 }} />
          {!get(this.props, "paziente.fileActigrafoGrezziPath") || null ? (
            <div className="text-warning" style={{ display: "inline-block" }}>
              Non caricati{" "}
              <Button
                style={{ display: "inline-block", marginLeft: 30 }}
                color="primary"
                size="lg"
                outline={true}
                onClick={() => {
                  this.setState(fpSet("caricaDatiActigrafo", true, this.state));
                }}
              >
                Carica dati actigrafo{" "}
              </Button>
            </div>
          ) : (
            <div className="text-success" style={{ display: "inline-block" }}>
              Dati caricati{" "}
              <Button
                style={{ display: "inline-block", marginLeft: 30 }}
                color="primary"
                outline={true}
                onClick={() => {
                  downloadFile(
                    get(this.props, "paziente.fileActigrafoGrezziPath") || null
                  );
                }}
              >
                Download dati grezzi{" "}
              </Button>
              <br />
              <Button
                color="link"
                onClick={() => {
                  this.setState(fpSet("caricaDatiActigrafo", true, this.state));
                }}
              >
                Carica nuovi dati actigrafo{" "}
              </Button>
            </div>
          )}
          <Modal size="lg" isOpen={!!get(this.state, "caricaDatiActigrafo")}>
            <ModalHeader
              toggle={() => {
                this.setState(fpSet("caricaDatiActigrafo", false, this.state));
              }}
            >
              {!!get(this.state, "caricaDatiActigrafo") && (
                <>Carica dati actigrafo </>
              )}
            </ModalHeader>

            <ModalBody>
              {!!get(this.state, "caricaDatiActigrafo") && (
                <>
                  <CaricaDatiActigrafo
                    paziente={get(this.props, "paziente")}
                    updatePaziente={get(this.props, "updatePaziente")}
                    close={() => {
                      this.setState(
                        fpSet("caricaDatiActigrafo", false, this.state)
                      );
                    }}
                  />
                </>
              )}
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = {
  getSmartphoneConnectionForPaziente: getSmartphoneConnectionForPaziente,
  updateSmartphoneConnection: updateSmartphoneConnection,
  sendTestNotification: sendTestNotification
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Obiettivo2);
