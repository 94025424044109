import React, { Component } from "react";
import { connect } from "react-redux";
import Inspector from "react-json-inspector";

import "./DebugInfo.scss";

class DebugInfo extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggle() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    let state = this.props.state;

    return (
      <div
        className={
          this.state.collapsed
            ? "debug-info debug-info-collapsed"
            : "debug-info"
        }
      >
        <div
          className="inspector-content"
          style={{ display: this.state.collapsed ? "none" : "block" }}
        >
          <Inspector data={state} />
        </div>
        <div className="debug-info-close">
          <a className="btn btn-default" onClick={this.toggle}>
            {this.state.collapsed ? "Open" : "Close"}
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    state: state
  };
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebugInfo);
