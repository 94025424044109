import React, { useState, useRef, useEffect, useMemo } from "react";
import { useAsync, globalScope } from "react-async";
import { isEqual } from "lodash";

async function asyncDataFetch(
  { loaderAction, loaderArgs = [] },
  abortController
) {
  const resultAction = await loaderAction(...loaderArgs, abortController);
  if (resultAction.callApiSuccess) {
    let data = resultAction.response.data;
    return data;
  } else {
    const errorMessage =
      resultAction.response.message || resultAction.response.error;
    throw new Error(errorMessage);
  }
}

async function asyncDataSave(props, { saveAction }, abortController) {
  const resultAction = await saveAction(...props, abortController);
  if (resultAction.callApiSuccess) {
    let data = resultAction.response.data;
    return data;
  } else {
    throw resultAction.response;
  }
}

function watchFn(props, prevProps) {
  return !isEqual(props.loaderArgs, prevProps.loaderArgs);
}

export default function AsyncData({
  loaderAction,
  loaderArgs,
  saveAction,
  reloadTimeout,
  children: render
}) {
  const counter = useRef(0);
  const setDataCounter = useRef(0);
  const saveDataCounter = useRef(0);

  const [{ _data, _lastSavedData }, setInternalState] = useState({
    _data: null,
    _lastSavedData: null
  });

  // async get data
  const onLoad = responseData => {
    counter.current++;
    setInternalState({
      _data: responseData,
      _lastSavedData: responseData
    });
  };

  const {
    data: responseData,
    error: loadingError,
    isPending: loading
  } = useAsync({
    promiseFn: asyncDataFetch,
    loaderAction: loaderAction,
    loaderArgs: loaderArgs,
    watchFn: watchFn,
    onResolve: onLoad
  });

  useEffect(() => {
    if (loading) {
      counter.current++;
      setInternalState({
        _data: null,
        _lastSavedData: null
      });
    }
  }, [loading]);

  // this is needed othersize in the first render before setInternalState is called we get data = undefined
  let data = undefined;
  let lastSavedData = undefined;
  if (!loading) {
    data = _data || responseData;
    lastSavedData = _lastSavedData || data;
  }

  const hasChanges = data !== lastSavedData;

  // save data
  const onSave = responseData => {
    setInternalState({
      _data:
        saveDataCounter.current === setDataCounter.current
          ? responseData
          : _data,
      _lastSavedData: responseData
    });
  };

  const {
    data: savedData,
    error: savingError,
    isPending: saving,
    run: runSave,
    setError: setSavingError
  } = useAsync({
    deferFn: asyncDataSave,
    saveAction: saveAction,
    loadingCounter: counter.current,
    watchFn: (props, prevProps) =>
      props.loadingCounter !== prevProps.loadingCounter,
    onResolve: onSave
  });

  useEffect(() => {
    if (reloadTimeout && !loading && !saving) {
      var stop = false;
      let timeout = null;
      var abortController = null;
      if ("AbortController" in globalScope) {
        abortController = new globalScope.AbortController();
      }

      const reloadInBackground = async () => {
        try {
          const newData = await asyncDataFetch(
            { loaderAction, loaderArgs },
            abortController
          );
          if (stop) {
            return;
          }
          setInternalState({
            _data: newData,
            _lastSavedData: newData
          });
        } catch (err) {
          console.log(err);
        }
        timeout = setTimeout(reloadInBackground, reloadTimeout);
      };

      timeout = setTimeout(reloadInBackground, reloadTimeout);

      return () => {
        stop = true;
        if (abortController) {
          abortController.abort();
        }
        clearTimeout(timeout);
      };
    }
  }, [
    reloadTimeout,
    loading,
    saving,
    saveDataCounter.current,
    loaderAction,
    loaderArgs
  ]);

  // handle changes
  const saveData = (...props) => {
    if (loading) {
      console.log("AsyncDataState: can't saveData while loading");
      return;
    }
    if (saving) {
      console.log("AsyncDataState: can't saveData if already saving");
      return;
    }
    saveDataCounter.current = setDataCounter.current;
    setSavingError(null);
    runSave(...props);
  };

  // handle changes
  const setData = data => {
    if (loading) {
      console.log("AsyncDataState: can't setData while loading");
      return;
    }
    setDataCounter.current++;
    setInternalState({
      _data: data,
      _lastSavedData: lastSavedData
    });
  };

  const cancelChanges = () => {
    if (loading) {
      console.log("AsyncDataState: can't cancelChanges while loading");
      return;
    }
    if (saving) {
      console.log("AsyncDataState: can't cancelChanges while saving");
      return;
    }
    setSavingError(null);
    if (data !== lastSavedData) {
      setDataCounter.current++;
      setInternalState({
        _data: lastSavedData,
        _lastSavedData: lastSavedData
      });
    }
  };

  // render
  const renderProps = useMemo(
    () => ({
      loading,
      loadingError,
      data,
      setData,
      hasChanges,
      saveData,
      saving,
      savingError,
      cancelChanges
    }),
    [
      loading,
      loadingError,
      data,
      setData,
      hasChanges,
      saveData,
      saving,
      savingError,
      cancelChanges
    ]
  );

  return render(renderProps);
}
