import React from "react";
import { Component } from "react";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";

class Training extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Row>
        <Col sm={12}>
          <Card>
            <CardHeader>Training </CardHeader>

            <CardBody>
              Di seguito i link per poter visionare i video che spiegano come
              utilizzare gli strumenti del progetto Diapason:{" "}
              <div className="tutorial-container">
                <div className="tutorial-row">
                  <a
                    className=""
                    href="http://www.zinkroo.com/watch/7ZKInBVdhs3leMbJNEW10b98l4HqZPwS"
                    style={{}}
                    target="_blank"
                  >
                    Diapason parte 1: Informazioni generali{" "}
                  </a>
                </div>

                <div className="tutorial-row">
                  <a
                    className=""
                    href="http://www.zinkroo.com/watch/3tHolbreye9XvFKFsx-_Xo43aWu9XZck"
                    style={{}}
                    target="_blank"
                  >
                    Diapason parte 2: Procedure{" "}
                  </a>
                </div>

                <div className="tutorial-row">
                  <a
                    className=""
                    href="http://www.zinkroo.com/watch/tsiZ9RyR_aTioLlpMZd9eL-pPksQn3px"
                    style={{}}
                    target="_blank"
                  >
                    Diapason parte 3: Strumenti{" "}
                  </a>
                </div>

                <div className="tutorial-row">
                  <a
                    className=""
                    href="https://vimeo.com/442615988"
                    style={{}}
                    target="_blank"
                  >
                    Diapason parte 4: Il portale{" "}
                  </a>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Training;
