const minLoadingTime = 1000;

export default function closeLoadingScreen() {
  if (!window.loading_screen) {
    return;
  }

  window.loading_screen_end = new Date();
  const loadingTime =
    window.loading_screen_end.getTime() - window.loading_screen_start.getTime();

  const seconds = loadingTime / 1000;
  console.log("Loading in " + seconds + " s");

  function finishLoading() {
    window.loading_screen.finish();
    window.loading_screen = null;
  }

  if (loadingTime < minLoadingTime) {
    setTimeout(finishLoading, minLoadingTime - loadingTime);
  } else {
    finishLoading();
  }
}
