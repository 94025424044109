import React from "react";
import { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Alert,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { get, map } from "lodash";
import fpSet from "lodash/fp/set";
import LegendaColoriQuestionari from "app/elements/LegendaColoriQuestionari.js";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import {
  getOperatori,
  getCompletamentoQuestionariPerUtente,
  creaOperatore
} from "app/actions";
import RaccoltaDatiCompletata from "app/elements/RaccoltaDatiCompletata.js";
import StatoQuestionari from "app/elements/StatoQuestionari.js";
import { Link } from "react-router-dom";
import OperatoreForm from "app/components/OperatoreForm.js";
import onCreateRedirect from "app/elements/onCreateRedirect";
import { connect } from "react-redux";

class ListaOperatori extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  creaOperatore = () => {
    this.props
      .creaOperatore(get(this.state, "creaOperatoreModal.user"))
      .then(action => {
        if (action.callApiSuccess) {
          onCreateRedirect(
            get(action, "response.data"),
            get(this.props, "history")
          );
        } else {
          this.setState(
            fpSet(
              "creaOperatoreModal.error",
              get(action, "response"),
              this.state
            )
          );
        }
      });
  };

  render() {
    return (
      <Row>
        <Col sm={12}>
          <Card>
            <CardHeader>Lista operatori </CardHeader>

            <CardBody>
              <Row style={{ marginBottom: 10 }}>
                <Col>
                  {get(this.props, "user.userType") === "Admin" ||
                  get(this.props, "user.adminCentro") ? (
                    <Button
                      color="primary"
                      onClick={() => {
                        this.setState(
                          fpSet(
                            "creaOperatoreModal",
                            { user: { userType: "Operatore" } },
                            this.state
                          )
                        );
                      }}
                    >
                      Crea operatore{" "}
                    </Button>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col>
                  <LegendaColoriQuestionari />
                </Col>
              </Row>

              <Row>
                <Col>
                  <AsyncData loaderAction={this.props.getOperatori}>
                    {({ loading, loadingError, data }) => {
                      console.log({ loading, loadingError, data });
                      if (loading) {
                        return <Loading />;
                      }
                      if (loadingError) {
                        return (
                          <Alert color="danger">{loadingError.message}</Alert>
                        );
                      }
                      return (
                        <Table responsive={true} bordered={true}>
                          <thead>
                            <tr>
                              <th>N </th>
                              <th>Codice utente </th>
                              <th>Tipo </th>
                              <th>Età </th>
                              <th>Genere </th>
                              <th>Raccolta completata </th>
                              <th>Stato </th>
                              <th style={{ width: 150 }}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {map(data, (row, rowIndex) => (
                              <tr key={rowIndex}>
                                <td>{rowIndex + 1}</td>
                                <td>{row.codiceUtente}</td>
                                <td>
                                  {get(row, "userType")}{" "}
                                  {get(row, "adminCentro")
                                    ? "(Responsabile)"
                                    : null}
                                </td>
                                <td>{row.etaAllInizio}</td>
                                <td>{row.genere}</td>
                                <td>
                                  <RaccoltaDatiCompletata user={row} />
                                </td>
                                <td>
                                  <AsyncData
                                    loaderAction={
                                      this.props
                                        .getCompletamentoQuestionariPerUtente
                                    }
                                    loaderArgs={[get(row, "id")]}
                                  >
                                    {({ loading, loadingError, data }) => {
                                      console.log({
                                        loading,
                                        loadingError,
                                        data
                                      });
                                      if (loading) {
                                        return <Loading />;
                                      }
                                      if (loadingError) {
                                        return (
                                          <Alert color="danger">
                                            {loadingError.message}
                                          </Alert>
                                        );
                                      }
                                      return (
                                        <StatoQuestionari
                                          questionariCompletamento={data}
                                          user={row}
                                        />
                                      );
                                    }}
                                  </AsyncData>
                                </td>
                                <td>
                                  <Button
                                    className=""
                                    color="primary"
                                    size="sm"
                                    outline={true}
                                    tag={Link}
                                    to={
                                      "/operatori/" +
                                      get(row, "id") +
                                      "/dettaglio"
                                    }
                                  >
                                    Dettaglio operatore{" "}
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      );
                    }}
                  </AsyncData>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Modal size="lg" isOpen={!!get(this.state, "creaOperatoreModal")}>
          <ModalHeader
            toggle={() => {
              this.setState(fpSet("creaOperatoreModal", false, this.state));
            }}
          >
            {!!get(this.state, "creaOperatoreModal") && <>Crea operatore </>}
          </ModalHeader>

          <ModalBody>
            {!!get(this.state, "creaOperatoreModal") && (
              <>
                <OperatoreForm
                  operatore={get(this.state, "creaOperatoreModal.user")}
                  updateOperatore={obj => {
                    this.setState(
                      fpSet("creaOperatoreModal.user", obj, this.state)
                    );
                  }}
                  operatoreErrors={get(this.state, "creaOperatoreModal.error")}
                  isCreate={true}
                />
              </>
            )}
          </ModalBody>

          <ModalFooter>
            {!!get(this.state, "creaOperatoreModal") && (
              <>
                {get(this.state, "creaOperatoreModal.error") &&
                get(this.state, "creaOperatoreModal.error").message ? (
                  <div className="text-danger">
                    {get(this.state, "creaOperatoreModal.error").message}
                  </div>
                ) : null}

                <Button
                  color="secondary"
                  onClick={() => {
                    this.setState(
                      fpSet("creaOperatoreModal", false, this.state)
                    );
                  }}
                >
                  Annulla{" "}
                </Button>

                <Button
                  color="primary"
                  onClick={() => {
                    this.creaOperatore();
                  }}
                >
                  Crea operatore{" "}
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: get(state, "auth.user")
  };
};
const mapDispatchToProps = {
  getOperatori: getOperatori,
  getCompletamentoQuestionariPerUtente: getCompletamentoQuestionariPerUtente,
  creaOperatore: creaOperatore
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListaOperatori);
