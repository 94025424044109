import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Input } from "reactstrap";
import DayPicker from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import moment from "moment";

// When clicking on a day cell, overlay will be hidden after this timeout
const HIDE_TIMEOUT = 100;

const LEFT = 37;
const UP = 38;
const RIGHT = 39;
const DOWN = 40;
const ENTER = 13;
const SPACE = 32;
const ESC = 27;
const TAB = 9;

function _formatDate(date, format) {
  if (!date) {
    return null;
  }
  return moment(date).format(format);
}

function _parseDate(text, format) {
  if (!text || text === "") {
    return null;
  }
  var m = moment(text, format);
  if (!m.isValid()) {
    return null;
  }
  return m.toDate();
}

class DatetimePicker extends Component {
  static propTypes = {
    date: PropTypes.bool,
    time: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    onChange: PropTypes.func,

    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onPickerShow: PropTypes.func,
    onPickerHide: PropTypes.func,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.hideAfterDayClick = this.hideAfterDayClick.bind(this);
    this.handleContainerMouseDown = this.handleContainerMouseDown.bind(this);
    this.handleInputClick = this.handleInputClick.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
    this.handleInputKeyUp = this.handleInputKeyUp.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleTimePickerSelectChange = this.handleTimePickerSelectChange.bind(
      this
    );
    this.handleTimePickerSelectBlur = this.handleTimePickerSelectBlur.bind(
      this
    );

    this.state = {
      focused: false,
      showOverlay: false
    };

    this.clickTimeout = null;
    this.hideTimeout = null;
    this.blurTimeout = null;
    this.clickedInside = false;
    this.clickedInsideSelect = false;
    this.input = null;
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.date !== state.date ||
      props.time !== state.time ||
      (!state.focused && "value" in props && props.value !== state.value)
    ) {
      const { date, time } = props;
      const format =
        date && !time ? "L" : time && !date ? "HH:mm:ss" : "L HH:mm:ss";
      const valueFormat =
        date && !time
          ? "YYYY-MM-DD"
          : time && !date
          ? "HH:mm:ss"
          : "YYYY-MM-DD[T]HH:mm:ssZ";

      const value = props.value;
      const valueString = value ? _formatDate(value, format) : "";
      const month = (value && _parseDate(value, valueFormat)) || new Date();

      return {
        date: props.date,
        time: props.time,
        value,
        valueString,
        month,
        format,
        valueFormat
      };
    } else {
      return null;
    }
  }

  componentWillUnmount() {
    clearTimeout(this.clickTimeout);
    clearTimeout(this.hideTimeout);
    clearTimeout(this.blurTimeout);
  }

  formatDate(date) {
    return _formatDate(date, this.state.format);
  }

  parseDate(text) {
    return _parseDate(text, this.state.format);
  }

  formatDateForValue(date) {
    return _formatDate(date, this.state.valueFormat);
  }

  parseDateFromValue(value) {
    return _parseDate(value, this.state.valueFormat);
  }

  resetOnBlur() {
    let value;
    if ("value" in this.props) {
      value = this.props.value;
    } else {
      value = this.state.value;
    }

    const date = this.parseDateFromValue(value);
    const valueString = this.formatDate(date);

    console.log();
    console.log("resetOnBlur");
    console.log(value);
    console.log(date);
    console.log(valueString);
    console.log();

    this.setState({
      showOverlay: false,
      focused: false,
      value,
      valueString,
      month: date || new Date()
    });
  }

  showDayPicker() {
    this.setState({ showOverlay: true, focused: true });
    if (this.props.onPickerShow) {
      this.props.onPickerShow();
    }
  }

  hideDayPicker() {
    this.setState({ showOverlay: false, focused: false });
    if (this.props.onPickerHide) {
      this.props.onPickerHide();
    }
  }

  hideAfterDayClick() {
    if (this.props.time) {
      return;
    }
    this.hideTimeout = setTimeout(() => this.hideDayPicker(), HIDE_TIMEOUT);
  }

  handleContainerMouseDown(event) {
    this.clickedInside = true;
    if (event.target.tagName.toLowerCase() === "select") {
      this.clickedInsideSelect = true;
    }
    // The input's onBlur method is called from a queue right after the onMouseDown event.
    // setTimeout adds another callback in the queue, which is called after the onBlur event.
    this.clickTimeout = setTimeout(() => {
      this.clickedInside = false;
      this.clickedInsideSelect = false;
    }, 0);
  }

  handleInputClick(e) {
    this.showDayPicker();
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  handleInputFocus(e) {
    this.showDayPicker();
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  handleInputBlur(e) {
    if (this.clickedInside) {
      this.showDayPicker();
      // Force input's focus if blur event was caused by clicking inside the overlay
      if (!this.clickedInsideSelect) {
        this.blurTimeout = setTimeout(() => this.input.focus(), 0);
      }
    } else {
      this.resetOnBlur();
    }
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  handleInputChange(e) {
    const { onChange } = this.props;
    const value = e.target.value;

    if (value.trim() === "") {
      this.setState({
        value: null,
        valueString: value
      });
      if (onChange) {
        onChange(e, null);
      }
      return;
    }

    const date = this.parseDate(value);
    if (!date) {
      this.setState({
        value: null,
        valueString: value
      });
      return;
    }

    var returnValue = this.formatDateForValue(date);
    this.setState({
      month: date,
      value: returnValue,
      valueString: value
    });

    if (onChange) {
      onChange(e, returnValue);
    }
  }

  handleInputKeyDown(e) {
    if (e.keyCode === TAB) {
      this.resetOnBlur();
    } else if (e.keyCode === ENTER) {
      e.preventDefault();
      e.target.blur();
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  }

  handleInputKeyUp(e) {
    // Hide the overlay if the ESC key is pressed
    if (e.keyCode === ESC) {
      this.resetOnBlur();
    }
    if (this.props.onKeyUp) {
      this.props.onKeyUp(e);
    }
  }

  handleMonthChange(month) {
    this.setState({ month });
  }

  handleDayClick(day, modifiers, e) {
    // Do nothing if the day is disabled
    if (modifiers.disabled) {
      return;
    }

    const { onChange } = this.props;

    const date = day;

    if (this.props.time && this.state.value) {
      const currentDate = this.parseDateFromValue(this.state.value);
      date.setHours(currentDate.getHours());
      date.setMinutes(currentDate.getMinutes());
      date.setSeconds(currentDate.getSeconds());
    }

    const valueString = this.formatDate(date);
    const value = this.formatDateForValue(date);

    this.setState(
      {
        value,
        valueString,
        month: date
      },
      this.hideAfterDayClick
    );

    if (onChange) {
      onChange(e, value);
    }
  }

  handleTimePickerSelectChange(what, event) {
    const { onChange } = this.props;
    const whatValue = parseInt(event.target.value, 10);

    let date = this.state.value && this.parseDateFromValue(this.state.value);
    if (!date) {
      date = new Date();
      date.setHours(0, 0, 0);
    }

    switch (what) {
      case "hour":
        date.setHours(whatValue);
        break;
      case "minute":
        date.setMinutes(whatValue);
        break;
      case "second":
        date.setSeconds(whatValue);
        break;
    }
    const valueString = this.formatDate(date);
    const value = this.formatDateForValue(date);

    this.setState({
      month: date,
      value,
      valueString
    });

    if (onChange) {
      onChange(event, value);
    }

    this.input.focus();
  }

  handleTimePickerSelectBlur() {
    if (this.clickedInside) {
      this.showDayPicker();
      // Force input's focus if blur event was caused by clicking inside the overlay
      if (!this.clickedInsideSelect) {
        this.blurTimeout = setTimeout(() => this.input.focus(), 0);
      }
    } else {
      this.resetOnBlur();
    }
  }

  handleTimePickerIncrementClick(event, what, delta) {
    const { onChange } = this.props;

    let date = this.state.value && this.parseDateFromValue(this.state.value);
    if (!date) {
      date = new Date();
      date.setHours(0, 0, 0);
    }

    let whatValue;
    switch (what) {
      case "hour":
        whatValue = date.getHours();
        date.setHours(whatValue + delta);
        break;
      case "minute":
        whatValue = date.getMinutes();
        date.setMinutes(whatValue + delta);
        break;
      case "second":
        whatValue = date.getSeconds();
        date.setSeconds(whatValue + delta);
        break;
    }

    const valueString = this.formatDate(date);
    const value = this.formatDateForValue(date);

    this.setState({
      month: date,
      value,
      valueString
    });

    if (onChange) {
      onChange(event, value);
    }
  }

  renderTimePickerSelect(what) {
    const date = this.state.value && this.parseDateFromValue(this.state.value);

    let values;
    let value;
    switch (what) {
      case "hour":
        values = 24;
        value = date && date.getHours();
        break;
      case "minute":
        values = 60;
        value = date && date.getMinutes();
        break;
      case "second":
        values = 60;
        value = date && date.getSeconds();
        break;
    }

    value = value || 0;
    let valueString = "00" + value;
    valueString = valueString.substring(valueString.length - 2);

    const options = [];
    for (let index = 0; index < values; index++) {
      let indexString = "00" + index;
      indexString = indexString.substring(indexString.length - 2);
      options.push(
        <option key={index} value={index}>
          {indexString}
        </option>
      );
    }

    const upDisabled = value === values - 1;
    const downDisabled = value === 0;

    return (
      <div className="time-picker-select-wrapper">
        <div
          className={classNames(
            "time-picker-arrow",
            "time-picker-arrow-up",
            upDisabled && "time-picker-arrow-disabled"
          )}
          onClick={event =>
            !upDisabled && this.handleTimePickerIncrementClick(event, what, +1)
          }
        />
        <div className="time-picker-label">
          <span>{valueString}</span>
          <select
            value={value}
            onBlur={this.handleTimePickerSelectBlur}
            onChange={event => this.handleTimePickerSelectChange(what, event)}
          >
            {options}
          </select>
        </div>
        <div
          className={classNames(
            "time-picker-arrow",
            "time-picker-arrow-down",
            downDisabled && "time-picker-arrow-disabled"
          )}
          onClick={event =>
            !downDisabled &&
            this.handleTimePickerIncrementClick(event, what, -1)
          }
        />
      </div>
    );
  }

  renderTimePicker() {
    return (
      <div className="time-picker-container">
        {this.renderTimePickerSelect("hour")}
        <span>:</span>
        {this.renderTimePickerSelect("minute")}
        <span>:</span>
        {this.renderTimePickerSelect("second")}
      </div>
    );
  }

  renderOverlay() {
    const { date, time } = this.props;
    const Overlay = this.props.overlayComponent;

    const selectedDays = this.parseDateFromValue(this.state.value);

    return (
      <div className="datetime-picker-input-overlay-wrapper">
        <div className="datetime-picker-input-overlay">
          {date && (
            <DayPicker
              ref={el => (this.daypicker = el)}
              locale={moment.locale()}
              localeUtils={MomentLocaleUtils}
              month={this.state.month}
              selectedDays={selectedDays}
              onDayClick={this.handleDayClick}
              onMonthChange={this.handleMonthChange}
            />
          )}
          {time && this.renderTimePicker()}
        </div>
      </div>
    );
  }

  render() {
    const {
      id,
      date,
      time,
      value,
      onChange,
      className,
      disabled,
      style,
      placeholder,
      size,
      bsSize,
      invalid
    } = this.props;

    return (
      <div
        className={classNames(
          "datetime-picker-input",
          "datetime-picker-input-full-width",
          className
        )}
        onMouseDown={this.handleContainerMouseDown}
        style={style}
      >
        <Input
          id={id}
          innerRef={el => (this.input = el)}
          placeholder={placeholder}
          disabled={disabled}
          size={size}
          bsSize={bsSize}
          invalid={invalid}
          value={this.state.valueString || ""}
          onChange={this.handleInputChange}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputBlur}
          onKeyDown={this.handleInputKeyDown}
          onKeyUp={this.handleInputKeyUp}
          onClick={this.handleInputClick}
        />
        {this.state.showOverlay && this.renderOverlay()}
      </div>
    );
  }
}

export default DatetimePicker;
