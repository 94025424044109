import React, { Component } from "react";
import { Nav } from "reactstrap";
import { AppSidebarToggler } from "@coreui/react";

class Header extends Component {
  render() {
    const { leftMenu, rightMenu, hasSidebar, icon } = this.props;

    return (
      <React.Fragment>
        {hasSidebar && (
          <AppSidebarToggler className="d-lg-none" display="md" mobile />
        )}
        {icon && <a className="navbar-brand">{icon}</a>}
        {hasSidebar && (
          <AppSidebarToggler className="d-md-down-none" display="lg" />
        )}

        <Nav className="d-md-down-none" navbar>
          {leftMenu}
        </Nav>
        <Nav className="ml-auto" navbar>
          {rightMenu}
        </Nav>
      </React.Fragment>
    );
  }
}

export default Header;
