import React, { Suspense } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUIForUser,
  routeUnauthorized,
  routeForPath,
  pages,
  defaultPage
} from "common/routes";
import getComponent from "app/utils/getComponent";
import Page from "./Page";
import Loading from "app/elements/Loading";

class LayoutWrapper extends React.Component {
  render() {
    const { route, user } = this.props;

    const ui =
      getUIForUser(route, user) ||
      (routeUnauthorized && routeUnauthorized.ui) ||
      null;

    if (!ui || !ui.component) {
      return (
        <Redirect
          to={{
            pathname: (ui && ui.redirect) || "/",
            state: { from: this.props.location }
          }}
        />
      );
    }

    const nextProps = Object.assign({}, this.props);
    delete nextProps.dispatch;
    delete nextProps.user;
    delete nextProps.route;

    const Component = getComponent(ui.component);

    if (!Component) {
      return null;
    }

    let header = null;
    let sidebar = null;

    const pageType = ui.page || defaultPage;
    if (pageType) {
      const pageSettings = pages[pageType];
      if (!pageSettings) {
        console.warn("Page settings not found for page: " + pageType);
      } else {
        if (pageSettings.header) {
          const Header = getComponent(pageSettings.header);
          header = (
            <Suspense fallback={<Loading />}>
              <Header hasSidebar={!!pageSettings.sidebar} {...nextProps} />
            </Suspense>
          );
        }
        if (pageSettings.sidebar) {
          const Sidebar = getComponent(pageSettings.sidebar);
          sidebar = (
            <Suspense fallback={<Loading />}>
              <Sidebar hasHeader={!!pageSettings.header} {...nextProps} />
            </Suspense>
          );
        }
      }
    }

    const content = (
      <Suspense fallback={<Loading />}>
        <Component {...nextProps} />
      </Suspense>
    );

    return (
      <Page
        content={content}
        header={header}
        sidebar={sidebar}
        {...nextProps}
      />
    );
  }
}

export default connect((state, ownProps) => {
  return {
    user: state.auth.user
  };
})(LayoutWrapper);
