import React from "react";
import { Component } from "react";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import {
  Alert,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  CardBody
} from "reactstrap";
import { getOperatore, updateOperatore } from "app/actions";
import { get } from "lodash";
import PreventClose from "app/elements/PreventClose.js";
import DeleteUser from "app/elements/DeleteUser.js";
import OperatoreForm from "app/components/OperatoreForm.js";
import LegendaColoriQuestionari from "app/elements/LegendaColoriQuestionari.js";
import ListaQuestionariUtente from "app/components/ListaQuestionariUtente.js";
import { questionariPerOperatore } from "common/utils/questionari.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class DettaglioOperatore extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <AsyncData
        loaderAction={this.props.getOperatore}
        loaderArgs={[get(this.props, "userId")]}
        saveAction={this.props.updateOperatore}
      >
        {({
          loading,
          loadingError,
          data,
          setData,
          hasChanges,
          saveData,
          saving,
          savingError,
          cancelChanges
        }) => {
          console.log({
            loading,
            loadingError,
            data,
            hasChanges,
            saving,
            savingError
          });
          if (loading) {
            return <Loading />;
          }
          if (loadingError) {
            return <Alert color="danger">{loadingError.message}</Alert>;
          }
          return (
            <>
              <Row>
                <Col sm={12}>
                  <Card>
                    <CardHeader>
                      Dettaglio operatore{" "}
                      <div className="float-right">
                        <div style={{ display: "inline-block" }}>
                          {savingError && savingError.message ? (
                            <div className="text-danger">
                              {savingError.message}
                            </div>
                          ) : null}
                        </div>{" "}
                        <Button
                          color="success"
                          size="sm"
                          outline={false}
                          onClick={() => {
                            saveData(get(data, "id"), data);
                          }}
                        >
                          Salva{" "}
                        </Button>{" "}
                        <PreventClose
                          shouldPrevent={hasChanges}
                          message={
                            "Ci sono modifiche non salvate. Continuando le modifiche non salvate andranno perse."
                          }
                        />
                        <Button
                          color="danger"
                          size="sm"
                          outline={false}
                          disabled={!hasChanges}
                          onClick={() => {
                            cancelChanges();
                          }}
                        >
                          Annulla cambiamenti{" "}
                        </Button>
                        <DeleteUser user={data} />
                      </div>
                    </CardHeader>

                    <CardBody>
                      <Row>
                        <Col>
                          <OperatoreForm
                            operatore={data}
                            updateOperatore={setData}
                            operatoreErrors={savingError}
                            isCreate={false}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <Card>
                    <CardHeader>Questionari </CardHeader>

                    <CardBody>
                      <Row>
                        <Col>
                          <LegendaColoriQuestionari />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <ListaQuestionariUtente
                            user={data}
                            listaQuestionari={questionariPerOperatore}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          );
        }}
      </AsyncData>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userId: get(ownProps, "match.params.id")
  };
};
const mapDispatchToProps = {
  getOperatore: getOperatore,
  updateOperatore: updateOperatore
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DettaglioOperatore)
);
