function generate() {
  const from = 100000;
  const to = 999999;

  //TODO: da fare e controllare che non esista
  return Math.round(from + Math.random() * (to - from));
}

export default function() {
  return generate();
}
