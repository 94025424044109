import React from "react";
import { Component } from "react";
import { Row, Col, Card, CardBody, Form, Input, Button } from "reactstrap";
import { get } from "lodash";
import fpSet from "lodash/fp/set";
import { login } from "app/actions";
import { connect } from "react-redux";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  login = () => {
    this.setState(fpSet("loginError", null, this.state));

    this.props
      .login(get(this.state, "data.email"), get(this.state, "data.password"))
      .then(action => {
        if (!action.callApiSuccess) {
          this.setState(
            fpSet("loginError", get(action, "response"), this.state)
          );
        }
      });
  };

  render() {
    return (
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="p-4">
            <CardBody>
              <Form>
                <h1>Login </h1>

                <p>Inserisci le tue credenziali </p>

                <Input
                  className="mb-3"
                  type="text"
                  bsSize="lg"
                  placeholder="Nome utente"
                  value={get(this.state, "data.email") || ""}
                  onChange={event => {
                    this.setState(
                      fpSet("data.email", event.target.value, this.state)
                    );
                  }}
                  invalid={!!get(this.state, "loginError.errors.email")}
                />

                <Input
                  className="mb-3"
                  type="password"
                  bsSize="lg"
                  placeholder="Password"
                  value={get(this.state, "data.password") || ""}
                  onChange={event => {
                    this.setState(
                      fpSet("data.password", event.target.value, this.state)
                    );
                  }}
                  invalid={!!get(this.state, "loginError.errors.password")}
                />

                <Button
                  color="primary"
                  onClick={() => {
                    this.login();
                  }}
                >
                  Login{" "}
                </Button>

                {!get(this.state, "loginError.success") && (
                  <div className="text-danger">
                    {get(this.state, "loginError.message")}
                  </div>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = {
  login: login
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
