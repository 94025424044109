export default [
  {
    type: "SCHEDA SOGGETTO",
    name: "SCHEDA SOGGETTO",
    codiceDataCompletamento: "DATA_00",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Ambulatoriale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Controllo"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Operatore"
      }
    ],
    domande: [
      {
        key: "DATA_00",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "RUOLO",
        label: "Ruolo professionale ricoperto in struttura",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Operatore"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Psichiatra"
          },
          {
            value: 2,
            label: "TERP"
          },
          {
            value: 3,
            label: "Psicologo/psicoterapeuta"
          },
          {
            value: 4,
            label: "Altro"
          }
        ]
      },
      {
        key: "RUOLO_a",
        label: "Specificare il ruolo professionale",
        if: [
          {
            key: "RUOLO",
            comparator: "=",
            value: 4
          }
        ],
        type: "free-text"
      },
      {
        key: "SCHEDA_SOGGETTO_Età",
        label: "Età (in anni)",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "SCHEDA_SOGGETTO_Genere",
        label: "Genere",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Maschio"
          },
          {
            value: 2,
            label: "Femmina"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_Scolarità",
        label: "Scolarità",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Licenza elementare"
          },
          {
            value: 2,
            label: "Licenza media inferiore"
          },
          {
            value: 3,
            label: "Diploma professionale (3 anni)"
          },
          {
            value: 4,
            label: "Diploma media superiore"
          },
          {
            value: 5,
            label: "Laurea triennale"
          },
          {
            value: 6,
            label: "Laurea specialistica"
          },
          {
            value: 7,
            label: "Specializzazione"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_anni_scolarità",
        label: "Anni di scolarità",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 27
        },
        step: "any",
        auto: null
      },
      {
        key: "SCHEDA_SOGGETTO_statocivile",
        label: "Stato civile",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Celibe / nubile, che non convive con un partner stabile"
          },
          {
            value: 2,
            label: "Coniugato/a"
          },
          {
            value: 3,
            label: "Celibe/nubile, che convive con un partner stabile"
          },
          {
            value: 4,
            label: "Separato/a – divorziato/a"
          },
          {
            value: 5,
            label: "Vedovo/a"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_peso",
        label: "Peso attuale",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Ambulatoriale"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Residenziale"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo"
          }
        ],
        type: "number",
        range: {
          from: 30,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "SCHEDA_SOGGETTO_altezza",
        label: "Altezza attuale (in centimetri)",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Ambulatoriale"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Residenziale"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo"
          }
        ],
        type: "number",
        range: {
          from: 120,
          to: 200
        },
        step: "any",
        auto: null
      },
      {
        key: "SCHEDA_SOGGETTO_attfisica",
        label: "Pratica regolarmente attività fisica?",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Ambulatoriale"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Residenziale"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_attfisicaspec",
        label: "Specificare attività fisica svolta",
        if: [
          {
            key: "SCHEDA_SOGGETTO_attfisica",
            comparator: "=",
            value: 1
          }
        ],
        type: "free-text"
      },
      {
        key: "SCHEDA_SOGGETTO_attfisicaquanto",
        label: "Con quale regolarità?",
        if: [
          {
            key: "SCHEDA_SOGGETTO_attfisica",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1  volta a settimana"
          },
          {
            value: 2,
            label: "2 volte a settimana"
          },
          {
            value: 3,
            label: "3 o più volte a settimana"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_attfisicanote",
        label:
          "Praticava regolarmente attività fisica prima delle restrizioni per COVID-19?",
        if: [
          {
            key: "SCHEDA_SOGGETTO_attfisica",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "SCHEDA_SOGGETTO_lavoro",
        label: "Condizione lavorativa attuale",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Ambulatoriale"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Residenziale"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Lavoro a tempo pieno o parziale in ambiente non protetto"
          },
          {
            value: 2,
            label:
              "Lavoro in ambiente protetto (cooperative di solidarietà, laboratorio protetto, etc.)"
          },
          {
            value: 3,
            label: "Corso di formazione lavorativa"
          },
          {
            value: 4,
            label: "Studente/essa"
          },
          {
            value: 5,
            label: "Casalinga/o"
          },
          {
            value: 6,
            label: "Disoccupato o in cerca di prima occupazione"
          },
          {
            value: 7,
            label:
              "Pensionato che non svolge alcuna attività retribuita (compreso fruitore di pensione di invalidità)"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_reddito",
        label: "Qual è il reddito principale?",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Ambulatoriale"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Residenziale"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Lavoro dipendente"
          },
          {
            value: 2,
            label: "Lavoro autonomo"
          },
          {
            value: 3,
            label: "Pensioni"
          },
          {
            value: 4,
            label:
              "Capitale o altri redditi (case in affitto, attività familiari, etc.)"
          },
          {
            value: 5,
            label: "Nessuno"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_conchivive",
        label: "Con chi vive attualmente?",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Ambulatoriale"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Da solo"
          },
          {
            value: 2,
            label: "Coniuge con o senza figli"
          },
          {
            value: 3,
            label: "Altri familiari"
          },
          {
            value: 4,
            label: "Amici"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_circaddo",
        label: "Circonferenza addominale (in cm)",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Ambulatoriale"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Residenziale"
          },
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo"
          }
        ],
        type: "number",
        range: null,
        step: "any",
        auto: null
      }
    ]
  },
  {
    type: "MMSE",
    name: "MINI MENTAL STATE EXAMINATION (MMSE)",
    codiceDataCompletamento: "DATA_01",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Ambulatoriale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      }
    ],
    domande: [
      {
        key: "DATA_01",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "MMSE_01",
        label: "1) ORIENTAMENTO",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 10
        },
        step: "any",
        auto: null
      },
      {
        key: "MMSE_02",
        label: "2) REGISTRAZIONE",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 3
        },
        step: "any",
        auto: null
      },
      {
        key: "MMSE_03",
        label: "3) ATTENZIONE E CALCOLO",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 5
        },
        step: "any",
        auto: null
      },
      {
        key: "MMSE_04",
        label: "4) RICHIAMO",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 3
        },
        step: "any",
        auto: null
      },
      {
        key: "MMSE_05",
        label: "5) LINGUAGGIO",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 8
        },
        step: "any",
        auto: null
      },
      {
        key: "MMSE_06",
        label: "6) PRASSIA COSTRUTTIVA",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 1
        },
        step: "any",
        auto: null
      },
      {
        key: "MMSE_07",
        label:
          "TOTALE GREZZO (il punteggio totale grezzo viene calcolato automaticamente)",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: {
          sum: [
            "MMSE_01",
            "MMSE_02",
            "MMSE_03",
            "MMSE_04",
            "MMSE_05",
            "MMSE_06"
          ]
        }
      },
      {
        key: "MMSE_08",
        label: "PUNTEGGIO CORRETTO (inserire il punteggio corretto)",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 33
        },
        step: "any",
        auto: null
      }
    ]
  },
  {
    type: "Charlson",
    name: "Indice di Comorbidità di Charlson",
    codiceDataCompletamento: "DATA_02",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Ambulatoriale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Controllo"
      }
    ],
    domande: [
      {
        key: "DATA_02",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "Charlson_0",
        label:
          "Qui sotto troverà un elenco di disturbi fisici. Per ogni disturbo vi è uno specifico punteggio da assegnare. Si prega di segnare con una crocetta i disturbi di cui ha sofferto il partecipante nel corso della sua vita. Il punteggio totale è calcolato dalla somma dei singoli punteggi in colonna.",
        if: null,
        type: "description"
      },
      {
        key: "Charlson_1",
        label: "1) Infarto miocardio ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Charlson_2",
        label: "2) Cardiopatia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Charlson_3",
        label: "3) Malattie vascolari",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Charlson_4",
        label: "4) Malattie cerebrovascolari",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Charlson_5",
        label: "5) Demenza",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Charlson_6",
        label: "6) Malattie polmonari croniche",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Charlson_7",
        label: "7) Malattie del tessuto connettivo",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Charlson_8",
        label: "8) Ulcera",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Charlson_9",
        label: "9) Lievi malattie del fegato",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Charlson_10",
        label: "10) Diabete ( senza complicazioni)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Charlson_11",
        label: "11) Diabete con danneggiamento degli organi",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Charlson_12",
        label: "12) Ictus",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Charlson_13",
        label: "13) Moderate o gravi malattie renali",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Charlson_14",
        label: "14) Secondo tumore non metastatico",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Charlson_15",
        label: "15) Leucemia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Charlson_16",
        label: "16) Linfoma, MM",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Charlson_17",
        label: "17) Moderate o gravi malattie del fegato",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 3,
            label: "3= sì"
          }
        ]
      },
      {
        key: "Charlson_18",
        label: "18) Secondo tumore metastatico",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 6,
            label: "6= sì"
          }
        ]
      },
      {
        key: "Charlson_19",
        label: "19) AIDS",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 6,
            label: "6= sì"
          }
        ]
      },
      {
        key: "Charlson_20",
        label: "Età (in anni)",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "Charlson_21",
        label:
          "TOTALE GREZZO (il punteggio totale grezzo viene calcolato automaticamente)",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "Charlson_1",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Charlson_2",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Charlson_3",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Charlson_4",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Charlson_5",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Charlson_6",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Charlson_7",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Charlson_8",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Charlson_9",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Charlson_10",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Charlson_11",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Charlson_12",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Charlson_13",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Charlson_14",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Charlson_15",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Charlson_16",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Charlson_17",
              map: {
                "0": 0,
                "3": 3
              }
            },
            {
              key: "Charlson_18",
              map: {
                "0": 0,
                "6": 6
              }
            },
            {
              key: "Charlson_19",
              map: {
                "0": 0,
                "6": 6
              }
            }
          ]
        }
      },
      {
        key: "Charlson_22",
        label:
          "PUNTEGGIO CORRETTO (il punteggio corretto viene calcolato automaticamente)",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            "Charlson_21",
            {
              custom: "età>=50"
            }
          ]
        }
      }
    ]
  },
  {
    type: "Fumo",
    name: "Questionario sul fumo",
    codiceDataCompletamento: "DATA_fumo",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Ambulatoriale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Controllo"
      }
    ],
    domande: [
      {
        key: "DATA_fumo",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "fumo_1",
        label: "1. Attualmente, Lei fuma sigarette?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= ogni giorno"
          },
          {
            value: 1,
            label: "1= saltuariamente"
          },
          {
            value: 2,
            label: "2= Per nulla"
          }
        ]
      },
      {
        key: "fumo_2",
        label: "2. Se sì, in media, quante sigarette fuma ogni giorno?",
        if: [
          {
            key: "fumo_1",
            comparator: "=",
            value: 0
          },
          {
            key: "fumo_1",
            comparator: "=",
            value: 1
          }
        ],
        type: "free-text"
      },
      {
        key: "fumo_3",
        label: "3. A che età ha iniziato a fumare sigarette ogni giorno? ",
        if: [
          {
            key: "fumo_1",
            comparator: "=",
            value: 0
          },
          {
            key: "fumo_1",
            comparator: "=",
            value: 1
          }
        ],
        type: "free-text"
      }
    ]
  },
  {
    type: "SCHEDA PSICHIATRICA",
    name: "SCHEDA PSICHIATRICA",
    codiceDataCompletamento: "DATA_03",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Ambulatoriale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      }
    ],
    domande: [
      {
        key: "DATA_03",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "PSICH_residenza",
        label:
          "Denominazione struttura residenziale psichiatrica di appartenenza (solo RS)",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Residenziale"
          }
        ],
        type: "free-text"
      },
      {
        key: "PSICH_durata",
        label: "1) Durata approssimativa (in anni) del disturbo mentale ",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 55
        },
        step: "any",
        auto: null
      },
      {
        key: "PSICH_etàesordio",
        label: "2) Età al primo contatto con i servizi psichiatrici pubblici ",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "PSICH_durataricoveri",
        label:
          "3) Durata complessiva approssimativa di tutti i ricoveri psichiatrici della vita (in Ospedali Psichiatrici, SPDC, case di cura psichiatriche, strutture residenziali) (sommare la durata dei ricoveri nelle varie strutture psichiatriche)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "0-2 mesi"
          },
          {
            value: 2,
            label: "da 2 mesi a 1 anno"
          },
          {
            value: 3,
            label: "1-2 anni"
          },
          {
            value: 4,
            label: "3-5 anni"
          },
          {
            value: 5,
            label: "più di 5 anni"
          }
        ]
      },
      {
        key: "PSICH_nricoveri",
        label:
          "4) Numero totale di ricoveri in SPDC nell’ultimo anno dal giorno della rilevazione ",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "PSICH_dataingressoSR",
        label:
          "5) Da quale data il paziente è ospitato nella struttura? (ci si riferisce SOLO all’ULTIMO ingresso nella SR)",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Residenziale"
          }
        ],
        type: "date"
      },
      {
        key: "PSICH_diagncomorb",
        label:
          "6) Il paziente ha una diagnosi psichiatrica in comorbidità con DSS?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "PSICH_6_diagnosiprincipale_comorb_quale",
        label: "6.a) Specificare la diagnosi principale in comorbidità",
        if: [
          {
            key: "PSICH_diagncomorb",
            comparator: "=",
            value: 1
          }
        ],
        type: "free-text"
      },
      {
        key: "PSICH_6__diagnosi_comorb_quale",
        label: "6.b) Altra diagnosi (segnare fino a due diagnosi):",
        if: [
          {
            key: "PSICH_diagncomorb",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Abuso/dipendenza da alcool e/o sostanze stupefacenti"
          },
          {
            value: 2,
            label: "Disturbo bipolare"
          },
          {
            value: 3,
            label:
              "Depressione maggiore, distimia ed altri disturbi affettivi unipolari"
          },
          {
            value: 4,
            label: "Disturbi d’ansia, DOC, disturbi somatoformi"
          },
          {
            value: 5,
            label: "Disturbi del comportamento alimentare"
          },
          {
            value: 6,
            label: "Disturbi di personalità (DSM-5)"
          },
          {
            value: 7,
            label: "Altro"
          }
        ],
        maxRisposte: 2
      },
      {
        key: "PSICH_6__diagnosi_comorb_quale2",
        label: "6.c) Specificare l'altra diagnosi ",
        if: [
          {
            key: "PSICH_6__diagnosi_comorb_quale",
            comparator: "=",
            value: 7,
            isMulti: true
          }
        ],
        type: "free-text"
      },
      {
        key: "PSICH_trattpsico",
        label: "7) Trattamento psicofarmacologico al giorno della rilevazione ",
        if: null,
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Antipsicotici 1a generazione"
          },
          {
            value: 2,
            label: "Antipsicotici 2a generazione"
          },
          {
            value: 3,
            label: "Clozapina"
          },
          {
            value: 4,
            label: "Stabilizzatori dell’umore (qualsiasi, compreso litio)"
          },
          {
            value: 5,
            label: "Antidepressivi (qualsiasi, di 1a e 2a generazione)"
          },
          {
            value: 6,
            label:
              "Benzodiazepine (qualsiasi, anche se usata a scopo ipnoinducente)"
          }
        ]
      },
      {
        key: "PSICH_7_nfarmaci1gen",
        label:
          '7.a) Numero di farmaci prescritti nella classe "antipsicotici 1a generazione"',
        if: [
          {
            key: "PSICH_trattpsico",
            comparator: "=",
            value: 1,
            isMulti: true
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: "4 o più"
          }
        ]
      },
      {
        key: "PSICH_7_nfarmaci2gen",
        label:
          '7.b) Numero di farmaci prescritti nella classe "antipsicotici 2a generazione"',
        if: [
          {
            key: "PSICH_trattpsico",
            comparator: "=",
            value: 2,
            isMulti: true
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: "4 o più"
          }
        ]
      },
      {
        key: "PSICH_7_nclozapina",
        label: '7.c) Numero di farmaci prescritti nella classe "Clozapina"',
        if: [
          {
            key: "PSICH_trattpsico",
            comparator: "=",
            value: 3,
            isMulti: true
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: "4 o più"
          }
        ]
      },
      {
        key: "PSICH_7_nstabumore",
        label:
          '7.d) Numero di farmaci prescritti nella classe "Stabilizzatori dell\'umore"',
        if: [
          {
            key: "PSICH_trattpsico",
            comparator: "=",
            value: 4,
            isMulti: true
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: "4 o più"
          }
        ]
      },
      {
        key: "PSICH_7_nantidep",
        label:
          '7.e) Numero di farmaci prescritti nella classe "antidepressivi"',
        if: [
          {
            key: "PSICH_trattpsico",
            comparator: "=",
            value: 5,
            isMulti: true
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: "4 o più"
          }
        ]
      },
      {
        key: "PSICH_7_nbenzodia",
        label:
          '7.f) Numero di farmaci prescritti nella classe "benzodiazepine"',
        if: [
          {
            key: "PSICH_trattpsico",
            comparator: "=",
            value: 6,
            isMulti: true
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: "4 o più"
          }
        ]
      },
      {
        key: "PSICH_8_freqsostanze",
        label:
          "8) Indicare la frequenza settimanale di utilizzo NEGLI ULTIMI 12 MESI di ciascuna sostanza indicata qui di seguito utilizzando la seguente scala di risposta:",
        if: null,
        type: "description"
      },
      {
        key: "PSICH_8_freqvino",
        label: "8.a) Alcol (vino)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Per niente"
          },
          {
            value: 2,
            label: "Raramente, meno di uno o due giorni"
          },
          {
            value: 3,
            label: "Parecchi giorni"
          },
          {
            value: 4,
            label: "Più della metà dei giorni"
          },
          {
            value: 5,
            label: "Quasi ogni giorno"
          }
        ]
      },
      {
        key: "PSICH_8_freqsuperalcol",
        label: "8.b) Alcol (superalcolici)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Per niente"
          },
          {
            value: 2,
            label: "Raramente, meno di uno o due giorni"
          },
          {
            value: 3,
            label: "Parecchi giorni"
          },
          {
            value: 4,
            label: "Più della metà dei giorni"
          },
          {
            value: 5,
            label: "Quasi ogni giorno"
          }
        ]
      },
      {
        key: "PSICH_8_freqfarmaci",
        label:
          "8.c) Farmaci non prescritti, o utilizzo non terapeutico (stimolanti, antidolorifici, sedativi, tranquillanti)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Per niente"
          },
          {
            value: 2,
            label: "Raramente, meno di uno o due giorni"
          },
          {
            value: 3,
            label: "Parecchi giorni"
          },
          {
            value: 4,
            label: "Più della metà dei giorni"
          },
          {
            value: 5,
            label: "Quasi ogni giorno"
          }
        ]
      },
      {
        key: "PSICH_8_freqdroghe",
        label:
          "8.d) Droghe (marijuana, cocaina o crack, allucinogeni, eroina, inalanti o solventi, metamfetamine)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Per niente"
          },
          {
            value: 2,
            label: "Raramente, meno di uno o due giorni"
          },
          {
            value: 3,
            label: "Parecchi giorni"
          },
          {
            value: 4,
            label: "Più della metà dei giorni"
          },
          {
            value: 5,
            label: "Quasi ogni giorno"
          }
        ]
      },
      {
        key: "PSICH_9_sostegno",
        label: "9) Sistema di sostegno nello scorso anno",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "la famiglia, gli amici, altre persone si interessano del paziente; sono disposti a collaborare e sono capaci di farlo"
          },
          {
            value: 2,
            label:
              "la famiglia, gli amici, altre persone si interessano del paziente, ma ci sono dubbi che riescano a fornire il sostegno necessario"
          },
          {
            value: 3,
            label:
              "sistema di sostegno potenzialmente disponibile, ma ci sono notevoli difficoltà nel mobilizzarlo"
          },
          {
            value: 4,
            label:
              "assenza della famiglia, degli amici e di altre persone. I servizi devono o dovrebbero provvedere al\nnecessario sostegno"
          }
        ]
      },
      {
        key: "PSICH_10_collab",
        label: "10) Capacità di collaborazione nello scorso anno",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "cerca attivamente il trattamento, è ben disposto e capace di collaborare"
          },
          {
            value: 2,
            label:
              "vuole essere aiutato, ma non è del tutto convinto, la motivazione non è forte"
          },
          {
            value: 3,
            label: "accetta passivamente i programmi d’intervento"
          },
          {
            value: 4,
            label:
              "mostra poca attenzione e poca comprensione per gli sforzi che sono stati fatti nel suo interesse"
          },
          {
            value: 5,
            label: "rifiuta attivamente l’intervento"
          }
        ]
      },
      {
        key: "PSICH_11_partecipazione",
        label:
          "11) Modalità di partecipazione dell’ospite alla vita della SR: l’ospite si occupa in maniera continuativa di una o più attività della vita quotidiana? (In caso di partecipazione multipla, SELEZIONARE l’attività che impegna per più ore il paziente)",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Residenziale"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "no"
          },
          {
            value: 2,
            label: "cucina"
          },
          {
            value: 3,
            label: "lavanderia"
          },
          {
            value: 4,
            label: "pulizia"
          },
          {
            value: 5,
            label: "giardinaggio interno"
          },
          {
            value: 6,
            label: "acquisti (spesa) per la SR"
          },
          {
            value: 7,
            label: "gestione amministrativa della SR"
          },
          {
            value: 8,
            label: "attività domestiche a turni"
          }
        ]
      },
      {
        key: "PSICH_diagnosiDSS",
        label: "12) Diagnosi (DSS)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Disturbo Delirante"
          },
          {
            value: 2,
            label: "Disturbo Schizofreniforme"
          },
          {
            value: 3,
            label: "Schizofrenia"
          },
          {
            value: 4,
            label: "Disturbo Schizoaffettivo Tipo Bipolare"
          },
          {
            value: 5,
            label: "Disturbo Schizoaffettivo Tipo Depressivo"
          },
          {
            value: 6,
            label:
              "Disturbo dello Spettro della Schizofrenia e altri disturbi psicotici con altra specificazione"
          },
          {
            value: 7,
            label:
              "Disturbo dello Spettro della Schizofrenia e altri disturbi psicotici senza specificazione"
          },
          {
            value: 8,
            label: "Disturbo schizoaffettivo"
          }
        ]
      }
    ]
  },
  {
    type: "BPRS",
    name: "BRIEF PSYCHIATRIC RATING SCALE (BPRS)",
    codiceDataCompletamento: "DATA_04",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Ambulatoriale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      }
    ],
    domande: [
      {
        key: "DATA_04",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "BPRS_1",
        label: "1) PREOCCUPAZIONI SOMATICHE ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_2",
        label: "2) ANSIA",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_3",
        label: "3) DEPRESSIONE",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_4",
        label: "4) RISCHIO DI SUICIDIO",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_5",
        label: "5) SENTIMENTI DI COLPA",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_6",
        label: "6) OSTILITÀ ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_7",
        label: "7) ELEVAZIONE DEL TONO DELL’UMORE ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_8",
        label: "8) GRANDIOSITÀ ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_9",
        label: "9) SOSPETTOSITÀ ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_10",
        label: "10) ALLUCINAZIONI ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_11",
        label: "11) CONTENUTO INSOLITO DEL PENSIERO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_12",
        label: "12) COMPORTAMENTO BIZZARRO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_13",
        label: "13) TRASCURATEZZA DELLA CURA DI SÉ ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_14",
        label: "14) DISORIENTAMENTO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_15",
        label: "15) DISORGANIZZAZIONE CONCETTUALE ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_16",
        label: "16) APPIATTIMENTO AFFETTIVO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_17",
        label: "17) ISOLAMENTO EMOTIVO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_18",
        label: "18) RALLENTAMENTO MOTORIO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_19",
        label: "19) TENSIONE MOTORIA ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_20",
        label: "20) MANCANZA DI COOPERAZIONE ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_21",
        label: "21) ECCITAMENTO ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_22",
        label: "22) DISTRAIBILITÀ ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_23",
        label: "23) IPERATTIVITÀ MOTORIA ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      },
      {
        key: "BPRS_24",
        label: "24) MANIERISMI E POSTURE ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Non presente"
          },
          {
            value: 2,
            label: "2= Molto lieve"
          },
          {
            value: 3,
            label: "3= Lieve"
          },
          {
            value: 4,
            label: "4= Moderato"
          },
          {
            value: 5,
            label: "5= Moderatamente grave"
          },
          {
            value: 6,
            label: "6= Grave"
          },
          {
            value: 7,
            label: "7= Molto grave"
          }
        ]
      }
    ]
  },
  {
    type: "BNSS",
    name: "BRIEF NEGATIVE SYMPTOM SCALE (BNSS)",
    codiceDataCompletamento: "DATA_05",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Ambulatoriale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      }
    ],
    domande: [
      {
        key: "DATA_05",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "BNSS_1",
        label: "1) Intensità del piacere durante le attività ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Normale"
          },
          {
            value: 1,
            label: "1= Dubbia"
          },
          {
            value: 2,
            label: "2= Lieve"
          },
          {
            value: 3,
            label: "3= Moderata"
          },
          {
            value: 4,
            label: "4= Moderatamente grave"
          },
          {
            value: 5,
            label: "5=Grave"
          },
          {
            value: 6,
            label: "6=Estremamente grave"
          }
        ]
      },
      {
        key: "BNSS_2",
        label: "2) Frequenza del piacere durante le attività ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Normale"
          },
          {
            value: 1,
            label: "1= Dubbia"
          },
          {
            value: 2,
            label: "2= Lieve"
          },
          {
            value: 3,
            label: "3= Moderata"
          },
          {
            value: 4,
            label: "4= Moderatamente grave"
          },
          {
            value: 5,
            label: "5=Grave"
          },
          {
            value: 6,
            label: "6=Estremamente grave"
          }
        ]
      },
      {
        key: "BNSS_3",
        label:
          "3) Intensità dell’anticipazione del piacere derivante da attività future ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Normale"
          },
          {
            value: 1,
            label: "1= Dubbia"
          },
          {
            value: 2,
            label: "2= Lieve"
          },
          {
            value: 3,
            label: "3= Moderata"
          },
          {
            value: 4,
            label: "4= Moderatamente grave"
          },
          {
            value: 5,
            label: "5= Grave"
          },
          {
            value: 6,
            label: "6= Estremamente grave"
          }
        ]
      },
      {
        key: "BNSS_4",
        label: "4) Angoscia (distress) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Normale"
          },
          {
            value: 1,
            label: "1= Dubbia"
          },
          {
            value: 2,
            label: "2= Lieve"
          },
          {
            value: 3,
            label: "3= Moderata"
          },
          {
            value: 4,
            label: "4= Moderatamente grave"
          },
          {
            value: 5,
            label: "5= Grave"
          },
          {
            value: 6,
            label: "6= Estremamente grave"
          }
        ]
      },
      {
        key: "BNSS_5",
        label: "5) Asocialità: comportamento ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Nessuna compromissione"
          },
          {
            value: 1,
            label: "1= Deficit molto lieve"
          },
          {
            value: 2,
            label: "2= Deficit lieve"
          },
          {
            value: 3,
            label: "3= Deficit moderato"
          },
          {
            value: 4,
            label: "4= Deficit moderatamente grave"
          },
          {
            value: 5,
            label: "5= Deficit marcato"
          },
          {
            value: 6,
            label: "6= Deficit grave"
          }
        ]
      },
      {
        key: "BNSS_6",
        label: "6) Asocialità: esperienza interna ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Nessuna compromissione"
          },
          {
            value: 1,
            label: "1= Deficit molto lieve"
          },
          {
            value: 2,
            label: "2= Deficit lieve"
          },
          {
            value: 3,
            label: "3= Deficit moderato"
          },
          {
            value: 4,
            label: "4= Deficit moderatamente grave"
          },
          {
            value: 5,
            label: "5= Deficit marcato"
          },
          {
            value: 6,
            label: "6= Deficit grave"
          }
        ]
      },
      {
        key: "BNSS_7",
        label: "7) Apatia: comportamento",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Nessuna compromissione"
          },
          {
            value: 1,
            label: "1= Deficit molto lieve"
          },
          {
            value: 2,
            label: "2= Deficit lieve"
          },
          {
            value: 3,
            label: "3= Deficit moderato"
          },
          {
            value: 4,
            label: "4= Deficit moderatamente grave"
          },
          {
            value: 5,
            label: "5= Deficit marcato"
          },
          {
            value: 6,
            label: "6= Deficit grave"
          }
        ]
      },
      {
        key: "BNSS_8",
        label: "8) Apatia: esperienza interna ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Nessuna compromissione"
          },
          {
            value: 1,
            label: "1= Deficit molto lieve"
          },
          {
            value: 2,
            label: "2= Deficit lieve"
          },
          {
            value: 3,
            label: "3= Deficit moderato"
          },
          {
            value: 4,
            label: "4= Deficit moderatamente grave"
          },
          {
            value: 5,
            label: "5= Deficit marcato"
          },
          {
            value: 6,
            label: "6= Deficit grave"
          }
        ]
      },
      {
        key: "BNSS_9",
        label: "9) Espressione facciale ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Nessuna compromissione"
          },
          {
            value: 1,
            label: "1= Deficit molto lieve"
          },
          {
            value: 2,
            label: "2= Deficit lieve"
          },
          {
            value: 3,
            label: "3= Deficit moderato"
          },
          {
            value: 4,
            label: "4= Deficit moderatamente grave"
          },
          {
            value: 5,
            label: "5= Deficit marcato"
          },
          {
            value: 6,
            label: "6= Deficit grave"
          }
        ]
      },
      {
        key: "BNSS_10",
        label: "10) Espressione vocale ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Nessuna compromissione"
          },
          {
            value: 1,
            label: "1= Deficit molto lieve"
          },
          {
            value: 2,
            label: "2= Deficit lieve"
          },
          {
            value: 3,
            label: "3= Deficit moderato"
          },
          {
            value: 4,
            label: "4= Deficit moderatamente grave"
          },
          {
            value: 5,
            label: "5= Deficit marcato"
          },
          {
            value: 6,
            label: "6= Deficit grave"
          },
          {
            value: 9,
            label: "9= Non valutabile"
          }
        ]
      },
      {
        key: "BNSS_11",
        label: "11) Gestualità espressiva ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Nessuna compromissione"
          },
          {
            value: 1,
            label: "1= Deficit molto lieve"
          },
          {
            value: 2,
            label: "2= Deficit lieve"
          },
          {
            value: 3,
            label: "3= Deficit moderato"
          },
          {
            value: 4,
            label: "4= Deficit moderatamente grave"
          },
          {
            value: 5,
            label: "5= Deficit marcato"
          },
          {
            value: 6,
            label: "6= Deficit grave"
          }
        ]
      },
      {
        key: "BNSS_12",
        label: "12) Quantità dell’eloquio ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Nessuna compromissione"
          },
          {
            value: 1,
            label: "1= Deficit molto lieve"
          },
          {
            value: 2,
            label: "2= Deficit lieve"
          },
          {
            value: 3,
            label: "3= Deficit moderato"
          },
          {
            value: 4,
            label: "4= Deficit moderatamente grave"
          },
          {
            value: 5,
            label: "5= Deficit marcato"
          },
          {
            value: 6,
            label: "6= Deficit grave"
          },
          {
            value: 9,
            label: "9= Non valutabile"
          }
        ]
      },
      {
        key: "BNSS_13",
        label: "13) Elaborazione spontanea ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Nessuna compromissione"
          },
          {
            value: 1,
            label: "1= Deficit molto lieve"
          },
          {
            value: 2,
            label: "2= Deficit lieve"
          },
          {
            value: 3,
            label: "3= Deficit moderato"
          },
          {
            value: 4,
            label: "4= Deficit moderatamente grave"
          },
          {
            value: 5,
            label: "5= Deficit marcato"
          },
          {
            value: 6,
            label: "6= Deficit grave"
          },
          {
            value: 9,
            label: "9= Non valutabile"
          }
        ]
      }
    ]
  },
  {
    type: "SLOF",
    name: "SPECIFIC LEVELS OF FUNCTIONING SCALE (SLOF) ",
    codiceDataCompletamento: "DATA_06",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Ambulatoriale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      }
    ],
    domande: [
      {
        key: "DATA_06",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "SLOF_0",
        label:
          "Verifichi quale numero meglio descrive il caratteristico livello di funzionamento del soggetto per ogni voce elencata sotto. Sia il più accurato possibile. Se non è sicuro rispetto ad un determinato punteggio, chieda a qualcuno che conosce il paziente o consulti la cartella clinica. Segni un solo numero per ogni voce. Controlli di aver contrassegnato tutte le voci. ",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_a",
        label: "CURA DI Sé - CONDIZIONE FISICA",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_1",
        label: "1) Vista",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Ostacola il funzionamento generale"
          },
          {
            value: 2,
            label: "2= Limita in gran parte il funzionamento generale"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label:
              "4= Crea problematiche, senza effetto sul funzionamento generale"
          },
          {
            value: 5,
            label: "5= nessun problema"
          }
        ]
      },
      {
        key: "SLOF_2",
        label: "2) Udito",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Ostacola il funzionamento generale"
          },
          {
            value: 2,
            label: "2= Limita in gran parte il funzionamento generale"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label:
              "4= Crea problematiche, senza effetto sul funzionamento generale"
          },
          {
            value: 5,
            label: "5= nessun problema"
          }
        ]
      },
      {
        key: "SLOF_3",
        label: "3) Compromissione dell'eloquio",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Ostacola il funzionamento generale"
          },
          {
            value: 2,
            label: "2= Limita in gran parte il funzionamento generale"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label:
              "4= Crea problematiche, senza effetto sul funzionamento generale"
          },
          {
            value: 5,
            label: "5= nessun problema"
          }
        ]
      },
      {
        key: "SLOF_4",
        label: "4) Deambulazione, uso delle gambe",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Ostacola il funzionamento generale"
          },
          {
            value: 2,
            label: "2= Limita in gran parte il funzionamento generale"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label:
              "4= Crea problematiche, senza effetto sul funzionamento generale"
          },
          {
            value: 5,
            label: "5= nessun problema"
          }
        ]
      },
      {
        key: "SLOF_5",
        label: "5) Utilizzo di mani e braccia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Ostacola il funzionamento generale"
          },
          {
            value: 2,
            label: "2= Limita in gran parte il funzionamento generale"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label:
              "4= Crea problematiche, senza effetto sul funzionamento generale"
          },
          {
            value: 5,
            label: "5= nessun problema"
          }
        ]
      },
      {
        key: "SLOF_b",
        label: "CURA DI Sé - COMPETENZE NELLA CURA DI Sé",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_6",
        label:
          "6) Andare alla toilette (usa correttamente la toilette, mantiene puliti sé e lo spazio) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_7",
        label:
          "7) Alimentazione (utilizza gli utensili correttamente, abitudini alimentari) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_8",
        label: "8) Igiene personale (corpo e denti, pulizia generale) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_9",
        label:
          "9) Vestirsi da soli (seleziona capi di abbigliamento adeguatamente; si veste autonomamente) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_10",
        label:
          "10) Cura della propria persona (capelli, trucco, aspetto generale) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_11",
        label: "11) Cura dei propri beni",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_12",
        label: "12) Cura del proprio spazio vitale",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto considerevole"
          },
          {
            value: 3,
            label: "3= Minimo effetto sul funzionamento generale"
          },
          {
            value: 4,
            label: "4= Necessita di un suggerimento verbale o di consigli"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_c",
        label: "FUNZIONAMENTO SOCIALE – RELAZIONI INTERPERSONALI",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_13",
        label:
          "13) Tollera i contatti con gli altri (non si allontana o respinge) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_14",
        label: "14) Stabilisce i contatti con gli altri",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_15",
        label:
          "15) Comunica in modo efficace (discorso e gestualità comprensibili e attinenti) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_16",
        label: "16) Partecipa alle attività senza suggerimenti ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_17",
        label: "17) Partecipa a gruppi ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_18",
        label: "18) Allaccia e mantiene le amicizie ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_19",
        label: "19) Chiede aiuto quando necessita ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_d",
        label: "FUNZIONAMENTO SOCIALE – ACCETTABILITÀ SOCIALE",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_20",
        label: "20) Abusi verbali",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_21",
        label: "21) Abusi fisici",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_22",
        label: "22) Distrugge beni",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_23",
        label: "23) E' aggressivo fisicamente verso se stesso",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_24",
        label: "24) Ha paura, piange, è appiccicoso",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_25",
        label: "25) Si appropria di beni altrui senza autorizzazione",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_26",
        label:
          "26) Reitera i comportamenti (passi, oscillazioni, rumori, ecc.)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Sempre"
          },
          {
            value: 2,
            label: "2= Di frequente"
          },
          {
            value: 3,
            label: "3= Qualche volta"
          },
          {
            value: 4,
            label: "4= Raramente"
          },
          {
            value: 5,
            label: "5= Mai"
          }
        ]
      },
      {
        key: "SLOF_e",
        label: "COMPETENZE IN AMBITO COMUNITARIO - ATTIVITà",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_27",
        label:
          "27) Responsabilità domestiche (pulizia della casa, cucinare, lavare vestiti, ecc.) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_28",
        label:
          "28) Acquisti (selezione di articoli, scelta di negozi, pagamento di cassa)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_29",
        label:
          "29) Gestione delle proprie finanze (gestione del budget, pagamento delle bollette)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_30",
        label:
          "30) Uso del telefono (trovare il numero, digitare il numero, conversazione, ascolto)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_31",
        label: "31) Allontanamento dalla propria abitazione senza perdersi ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_32",
        label:
          "32) Utilizzo dei trasporti pubblici (selezionare percorso, usare gli orari, pagare tariffe, effettuare i trasferimenti) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_33",
        label:
          "33) Impiego del tempo libero (letture, visite agli amici, ascoltare musica, ecc.) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_34",
        label:
          "34) Riconoscere ed evitare pericoli comuni (traffico, incendio, ecc.)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_35",
        label:
          "35) Automedicazione (comprendere lo scopo, assumere come prescritto, riconoscere gli effetti collaterali) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_36",
        label:
          "36) Utilizzo dei revizi medici e di comunità (sapere a chi rivolgersi, come e quando usarli) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_37",
        label:
          "37) Lettura di base, scrittura e calcolo (sufficiente per le necessità quotidiane) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Totalmente dipendente"
          },
          {
            value: 2,
            label: "2= Necessita di un aiuto sostanziale"
          },
          {
            value: 3,
            label: "3= Necessita di un aiuto fisico o di assistenza"
          },
          {
            value: 4,
            label: "4= Necessita di suggerimenti o consigli verbali"
          },
          {
            value: 5,
            label: "5= Totalmente autosufficiente"
          }
        ]
      },
      {
        key: "SLOF_f",
        label: "COMPETENZE IN AMBITO COMUNITARIO - CAPACITà LAVORATIVE",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_38",
        label: "38) Possiede competenze lavorative",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_39",
        label: "39) Lavora con una supervisione minima",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_40",
        label:
          "40) Sostiene gli sforzi lavorativi (non si distrae facilmente, è capace di lavorare sotto stress) ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_41",
        label: "41) Si presenta agli appuntamenti puntuale ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_42",
        label: "42) Segue accuratamente le istruzioni verbali ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_43",
        label: "43) Completa i compiti assegnati",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto atipico per questa persona"
          },
          {
            value: 2,
            label: "2= Generalmente atipico per questa persona"
          },
          {
            value: 3,
            label: "3= Moderatamente caratteristico di questa persona"
          },
          {
            value: 4,
            label: "4= Generalmente caratteristico di questa persona"
          },
          {
            value: 5,
            label: "5= Molto caratteristico di questa persona"
          }
        ]
      },
      {
        key: "SLOF_g",
        label: "ALTRE INFORMAZIONI",
        if: null,
        type: "description"
      },
      {
        key: "SLOF_44",
        label:
          "44) In base alla conoscenza di questa persona, ci sono altre abilità o aree problematiche non contemplate da questo questionario e rilevanti ai fini della capacità di questa persona di operare in modo indipendente? Se è così, si prega di specificare. ",
        if: null,
        type: "free-text"
      },
      {
        key: "SLOF_45",
        label:
          "45) Quanto conosce le abilità e il comportamento della persona che ha appena valutato?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 5,
            label: "5. Molto bene"
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 1,
            label: "1. Per nulla"
          }
        ]
      },
      {
        key: "SLOF_46",
        label: "46) Ha discusso questa valutazione con il soggetto?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 4,
            label: "No"
          },
          {
            value: 5,
            label: "Sì"
          }
        ]
      },
      {
        key: "SLOF_46a",
        label:
          "46.a) Se SÌ, l’individuo concorda generalmente con la valutazione? ",
        if: [
          {
            key: "SLOF_46",
            comparator: "=",
            value: 5
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 4,
            label: "No"
          },
          {
            value: 5,
            label: "Sì"
          }
        ]
      },
      {
        key: "SLOF_46b",
        label: "46.b) Se NO, si prega di commentare ",
        if: [
          {
            key: "SLOF_46",
            comparator: "=",
            value: 4
          }
        ],
        type: "free-text"
      }
    ]
  },
  {
    type: "CAN-P",
    name: "CAMBERWELL ASSESSMENT OF NEED - VERSIONE PAZIENTE (CAN-P)",
    codiceDataCompletamento: "DATA_07",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      }
    ],
    domande: [
      {
        key: "DATA_07",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "CAN-P_0",
        label:
          "La versione clinica della CAN può essere utilizzata per valutare l’opinione degli utenti e dello staff. Prima di applicare la CAN si prega di leggere l’intero strumento, compresi gli esempi che danno indicazioni per le codifiche. Vengono esaminate 22 aree. In ciascuna delle aree si valuta la presenza di un problema/l’aiuto ricevuto da parte di amici o parenti o dallo staff/l’opinione del paziente sui servizi (NELL’ULTIMO MESE). Se è presente un problema grave (indipendentemente da qualsiasi aiuto fornito): codificare 2. Se, grazie all’aiuto fornito, non esiste un problema grave: codificare 1. Se non esiste alcun problema: codificare 0. Se la codifica è 0 o 9 si passa all’area successiva, altrimenti si completano le restanti sezioni. ",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_1",
        label: "1) ALLOGGIO",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_1a",
        label: "1.1) ATTUALMENTE IL SOGGETTO HA UN POSTO DOVE VIVERE?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_1b",
        label:
          "1.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER L'ALLOGGIO?",
        if: [
          {
            key: "CAN-P_1a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_1a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_1c",
        label:
          "1.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER L'ALLOGGIO?",
        if: [
          {
            key: "CAN-P_1a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_1a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_1d",
        label:
          "1.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER L'ALLOGGIO?",
        if: [
          {
            key: "CAN-P_1a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_1a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_2",
        label: "2) ALIMENTAZIONE",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_2a",
        label:
          "2.1) IL SOGGETTO HA DIFFICOLTÀ A PROCURARSI E A PREPARARSI DA MANGIARE?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_2b",
        label:
          "2.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI A PROCURARSI E PREPARARSI DA MANGIARE?",
        if: [
          {
            key: "CAN-P_2a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_2a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_2c",
        label:
          "2.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI\nSERVIZI LOCALI A PROCURARSI E PREPARARSI DA MANGIARE?",
        if: [
          {
            key: "CAN-P_2a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_2a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_2d",
        label:
          "2.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE\nDEI SERVIZI LOCALI A PROCURARSI E PREPARARSI DA MANGIARE?\n",
        if: [
          {
            key: "CAN-P_2a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_2a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_3",
        label: "3) CURA DELLA CASA",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_3a",
        label: "3.1) IL SOGGETTO HA DIFFICOLTÀ A PRENDERSI CURA DELLA CASA?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_3b",
        label:
          "3.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER LA CURA DELLA CASA?",
        if: [
          {
            key: "CAN-P_3a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_3a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_3c",
        label:
          "3.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER LA CURA DELLA CASA?",
        if: [
          {
            key: "CAN-P_3a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_3a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_3d",
        label:
          "3.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER LA CURA DELLA CASA?",
        if: [
          {
            key: "CAN-P_3a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_3a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_4",
        label: "4) CURA DI SÉ",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_4a",
        label: "4.1) IL SOGGETTO HA DIFFICOLTÀ PER LA CURA DI SÉ?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_4b",
        label:
          "4.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER LA CURA DI SÉ?",
        if: [
          {
            key: "CAN-P_4a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_4a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_4c",
        label:
          "4.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER LA CURA DI SÉ?",
        if: [
          {
            key: "CAN-P_4a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_4a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_4d",
        label:
          "4.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER LA CURA DI SÉ?",
        if: [
          {
            key: "CAN-P_4a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_4a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_5",
        label: "5) ATTIVITÀ QUOTIDIANE",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_5a",
        label:
          "5.1) IL SOGGETTO HA DIFFICOLTÀ NELLO SVOLGERE IN MANIERA REGOLARE ED APPROPRIATA LE ATTIVITÀ QUOTIDIANE?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_5b",
        label:
          "5.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER SVOLGERE IN MANIERA REGOLARE ED APPRO- PRIATA LE ATTIVITÀ QUOTIDIANE?",
        if: [
          {
            key: "CAN-P_5a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_5a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_5c",
        label:
          "5.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER SVOLGERE IN MANIERA REGOLARE ED APPROPRIATA LE ATTIVITÀ QUOTIDIANE?",
        if: [
          {
            key: "CAN-P_5a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_5a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_5d",
        label:
          "5.4) DI QUANTO AIUTO HA BISOGNO IL SOGGETTO DA PARTE DEI SERVIZI LOCALI PER SVOLGERE IN MANIERA REGOLARE ED APPROPRIATA LE ATTIVITÀ QUOTIDIANE?",
        if: [
          {
            key: "CAN-P_5a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_5a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_6",
        label: "6) SALUTE FISICA",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_6a",
        label: "6.1) IL SOGGETTO HA QUALCHE DISABILITÀ O MALATTIA FISICA?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_6b",
        label:
          "6.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER I PROBLEMI FISICI?",
        if: [
          {
            key: "CAN-P_6a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_6a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_6c",
        label:
          "6.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER I PROBLEMI FISICI?",
        if: [
          {
            key: "CAN-P_6a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_6a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_6d",
        label:
          "6.4) DI QUANTO AIUTO HA BISOGNO IL SOGGETTO DA PARTE DEI SERVIZI LOCALI PER I PROBLEMI FISICI?",
        if: [
          {
            key: "CAN-P_6a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_6a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_7",
        label: "7) SINTOMI PSICOTICI",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_7a",
        label:
          "7.1) IL SOGGETTO MANIFESTA DEI SINTOMI PSICOTICI, COME CONVINZIONI DELIRANTI, ALLUCINAZIONI, DISTURBO FORMALE DEL PENSIERO O\nP ASSIVITÀ?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_7b",
        label:
          "7.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER I SINTOMI PSICOTICI?",
        if: [
          {
            key: "CAN-P_7a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_7a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_7c",
        label:
          "7.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER I SINTOMI PSICOTICI?",
        if: [
          {
            key: "CAN-P_7a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_7a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_7d",
        label:
          "7.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER I SINTOMI PSICOTICI?",
        if: [
          {
            key: "CAN-P_7a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_7a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_8",
        label: "8) INFORMAZIONI (SU DISTURBI E TRATTAMENTI) ",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_8a",
        label:
          "8.1) IL SOGGETTO HA AVUTO INFORMAZIONI CHIARE, VERBALI O\nSCRITTE, SULLE SUE CONDIZIONI E SUI TRATTAMENTI?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_8b",
        label:
          "8.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER OTTENERE TALI INFORMAZIONI?",
        if: [
          {
            key: "CAN-P_8a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_8a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_8c",
        label:
          "8.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER OTTENERE TALI INFORMAZIONI?",
        if: [
          {
            key: "CAN-P_8a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_8a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_8d",
        label:
          "8.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER OTTENERE TALI INFORMAZIONI?",
        if: [
          {
            key: "CAN-P_8a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_8a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_9",
        label: "9) DISAGIO PSICOLOGICO ",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_9a",
        label: "9.1) ATTUALMENTE IL SOGGETTO PRESENTA UN DISAGIO PSICOLOGICO?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_9b",
        label:
          "9.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER QUESTO DISAGIO?",
        if: [
          {
            key: "CAN-P_9a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_9a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_9c",
        label:
          "9.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER QUESTO DISAGIO?",
        if: [
          {
            key: "CAN-P_9a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_9a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_9d",
        label:
          "9.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER QUESTO DISAGIO?",
        if: [
          {
            key: "CAN-P_9a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_9a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_10",
        label: "10) SICUREZZA PERSONALE",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_10a",
        label: "10.1) IL SOGGETTO RAPPRESENTA UN PERICOLO PER SÉ STESSO?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_10b",
        label:
          "10.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER RIDURRE IL RISCHIO DI GESTI AUTOLESIVI?",
        if: [
          {
            key: "CAN-P_10a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_10a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_10c",
        label:
          "10.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER RIDURRE IL RISCHIO DI GESTI AUTOLESIVI?",
        if: [
          {
            key: "CAN-P_10a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_10a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_10d",
        label:
          "10.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER RIDURRE IL RISCHIO DI GESTI AUTOLESIVI?",
        if: [
          {
            key: "CAN-P_10a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_10a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_11",
        label: "11) SICUREZZA PER GLI ALTRI",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_11a",
        label:
          "11.1) IL SOGGETTO RAPPRESENTA UN RISCHIO ATTUALE O POTENZIALE PER LA SICUREZZA DEGLI ALTRI?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_11b",
        label:
          "11.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER RIDURRE IL RISCHIO DI FAR MALE AGLI ALTRI?",
        if: [
          {
            key: "CAN-P_11a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_11a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_11c",
        label:
          "11.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER RIDURRE IL RISCHIO DI FAR MALE AGLI ALTRI?",
        if: [
          {
            key: "CAN-P_11a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_11a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_11d",
        label:
          "11.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI\nSERVIZI LOCALI PER RIDURRE IL RISCHIO DI FAR MALE AGLI ALTRI?",
        if: [
          {
            key: "CAN-P_11a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_11a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_12",
        label: "12) ABUSO DI ALCOOL",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_12a",
        label:
          "12.1) IL SOGGETTO BEVE ECCESSIVAMENTE OPPURE HA DIFFICOLTÀ A CONTROLLARE IL BERE?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_12b",
        label:
          "12.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER IL BERE?",
        if: [
          {
            key: "CAN-P_12a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_12a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_12c",
        label:
          "12.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER IL BERE?",
        if: [
          {
            key: "CAN-P_12a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_12a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_12d",
        label:
          "12.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER IL BERE?",
        if: [
          {
            key: "CAN-P_12a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_12a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_13",
        label: "13) FARMACI",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_13a",
        label: "13.1) IL SOGGETTO ABUSA DI FARMACI?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_13b",
        label:
          "13.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER L'ABUSO DI FARMACI?",
        if: [
          {
            key: "CAN-P_13a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_13a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_13c",
        label:
          "13.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER L'ABUSO DI FARMACI?",
        if: [
          {
            key: "CAN-P_13a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_13a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_13d",
        label:
          "13.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER L'ABUSO DI FARMACI?",
        if: [
          {
            key: "CAN-P_13a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_13a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_14",
        label: "14) VITA DI RELAZIONE",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_14a",
        label: "14.1) IL SOGGETTO HA BISOGNO DI AIUTO PER I CONTATTI SOCIALI?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_14b",
        label:
          "14.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER I CONTATTI SOCIALI?",
        if: [
          {
            key: "CAN-P_14a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_14a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_14c",
        label:
          "14.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER I CONTATTI SOCIALI?",
        if: [
          {
            key: "CAN-P_14a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_14a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_14d",
        label:
          "14.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER I CONTATTI SOCIALI?",
        if: [
          {
            key: "CAN-P_14a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_14a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_15",
        label: "15) VITA DI COPPIA",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_15a",
        label:
          "15.1) IL SOGGETTO HA DIFFICOLTÀ A TROVARE UN PARTNER O A MANTENERE UNA RELAZIONE INTIMA?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_15b",
        label:
          "15.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER INSTAURARE E MANTENERE RELAZIONI INTIME?",
        if: [
          {
            key: "CAN-P_15a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_15a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_15c",
        label:
          "15.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER INSTAURARE E MANTENERE RELAZIONI INTIME?",
        if: [
          {
            key: "CAN-P_15a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_15a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_15d",
        label:
          "15.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER INSTAURARE E MANTENERE RELAZIONI INTIME?",
        if: [
          {
            key: "CAN-P_15a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_15a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_16",
        label: "16) VITA SESSUALE",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_16a",
        label: "16.1) IL SOGGETTO HA PROBLEMI NELLA VITA SESSUALE?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_16b",
        label:
          "16.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER PROBLEMI LEGATI ALLA VITA SESSUALE?",
        if: [
          {
            key: "CAN-P_16a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_16a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_16c",
        label:
          "16.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER PROBLEMI LEGATI ALLA VITA SESSUALE?",
        if: [
          {
            key: "CAN-P_16a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_16a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_16d",
        label:
          "16.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER PROBLEMI LEGATI ALLA VITA SESSUALE?",
        if: [
          {
            key: "CAN-P_16a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_16a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_17",
        label: "17) CURA DEI FIGLI",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_17a",
        label: "17.1) IL SOGGETTO HA DIFFICOLTÀ A PRENDERSI CURA DEI FIGLI?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_17b",
        label:
          "17.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER LA CURA DEI FIGLI?",
        if: [
          {
            key: "CAN-P_17a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_17a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_17c",
        label:
          "17.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER LA CURA DEI FIGLI?",
        if: [
          {
            key: "CAN-P_17a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_17a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_17d",
        label:
          "17.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER LA CURA DEI FIGLI?",
        if: [
          {
            key: "CAN-P_17a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_17a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_18",
        label: "18) ISTRUZIONE DI BASE",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_18a",
        label:
          "18.1) IL SOGGETTO È PRIVO DI CONOSCENZE ELEMENTARI IN\nARITMETICA, LETTURA E SCRITTURA?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_18b",
        label:
          "18.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER PROBLEMI DI CALCOLO, LETTURA E SCRITTURA?",
        if: [
          {
            key: "CAN-P_18a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_18a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_18c",
        label:
          "18.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER PROBLEMI DI CALCOLO, LETTURA E SCRITTURA?",
        if: [
          {
            key: "CAN-P_18a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_18a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_18d",
        label:
          "18.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER PROBLEMI DI CALCOLO, LETTURA E SCRITTURA?",
        if: [
          {
            key: "CAN-P_18a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_18a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_19",
        label: "19) TELEFONO",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_19a",
        label:
          "19.1) IL SOGGETTO HA DIFFICOLTÀ AD ACCEDERE O AD UTILIZZARE UN TELEFONO?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_19b",
        label:
          "19.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER FARE DELLE TELEFONATE?",
        if: [
          {
            key: "CAN-P_19a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_19a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_19c",
        label:
          "19.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER FARE DELLE TELEFONTE? ",
        if: [
          {
            key: "CAN-P_19a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_19a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_19d",
        label:
          "19.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER FARE DELLE TELEFONATE?",
        if: [
          {
            key: "CAN-P_19a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_19a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_20",
        label: "20) TRASPORTI",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_20a",
        label: "20.1) IL SOGGETTO HA DIFFICOLTÀ AD USARE I TRASPORTI PUBBLICI?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_20b",
        label:
          "20.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER VIAGGIARE?",
        if: [
          {
            key: "CAN-P_20a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_20a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_20c",
        label:
          "20.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER VIAGGIARE?",
        if: [
          {
            key: "CAN-P_20a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_20a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_20d",
        label:
          "20.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER VIAGGIARE?",
        if: [
          {
            key: "CAN-P_20a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_20a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_21",
        label: "21) DENARO",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_21a",
        label: "21.1) IL SOGGETTO HA PROBLEMI PER LA GESTIONE DEL DENARO?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_21b",
        label:
          "21.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER LA GESTIONE DEL DENARO?",
        if: [
          {
            key: "CAN-P_21a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_21a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_21c",
        label:
          "21.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI ASSISTENZIALI PER LA GESTIONE DEL DENARO?",
        if: [
          {
            key: "CAN-P_21a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_21a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_21d",
        label:
          "21.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI ASSISTENZIALI PER LA GESTIONE DEL DENARO?",
        if: [
          {
            key: "CAN-P_21a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_21a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_22",
        label: "22) SUSSIDI",
        if: null,
        type: "description"
      },
      {
        key: "CAN-P_22a",
        label: "22.1) IL SOGGETTO RICEVE TUTTI I SUSSIDI CHE GLI SPETTANO?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_22b",
        label:
          "22.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER OTTENERE I SUSSIDI DI CUI HA DIRITTO?",
        if: [
          {
            key: "CAN-P_22a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_22a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_22c",
        label:
          "22.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER OTTENERE I SUSSIDI DI CUI HA DIRITTO?",
        if: [
          {
            key: "CAN-P_22a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_22a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-P_22d",
        label:
          "22.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER OTTENERE I SUSSIDI DI CUI HA DIRITTO?",
        if: [
          {
            key: "CAN-P_22a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-P_22a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      }
    ]
  },
  {
    type: "CAN-S",
    name: "CAMBERWELL ASSESSMENT OF NEED - VERSIONE STAFF (CAN-S)",
    codiceDataCompletamento: "DATA_08",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      }
    ],
    domande: [
      {
        key: "DATA_08",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "CAN-S_0",
        label:
          "La versione clinica della CAN può essere utilizzata per valutare l’opinione degli utenti e dello staff. Prima di applicare la CAN si prega di leggere l’intero strumento, compresi gli esempi che danno indicazioni per le codifiche. Vengono esaminate 22 aree. In ciascuna delle aree si valuta la presenza di un problema/l’aiuto ricevuto da parte di amici o parenti o dallo staff/l’opinione del paziente sui servizi (NELL’ULTIMO MESE). Se è presente un problema grave (indipendentemente da qualsiasi aiuto fornito): codificare 2. Se, grazie all’aiuto fornito, non esiste un problema grave: codificare 1. Se non esiste alcun problema: codificare 0. Se la codifica è 0 o 9 si passa all’area successiva, altrimenti si completano le restanti sezioni. ",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_1",
        label: "1) ALLOGGIO",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_1a",
        label: "1.1) ATTUALMENTE IL SOGGETTO HA UN POSTO DOVE VIVERE?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_1b",
        label:
          "1.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER L'ALLOGGIO?",
        if: [
          {
            key: "CAN-S_1a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_1a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_1c",
        label:
          "1.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER L'ALLOGGIO?",
        if: [
          {
            key: "CAN-S_1a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_1a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_1d",
        label:
          "1.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER L'ALLOGGIO?",
        if: [
          {
            key: "CAN-S_1a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_1a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_2",
        label: "2) ALIMENTAZIONE",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_2a",
        label:
          "2.1) IL SOGGETTO HA DIFFICOLTÀ A PROCURARSI E A PREPARARSI DA MANGIARE?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_2b",
        label:
          "2.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI A PROCURARSI E PREPARARSI DA MANGIARE?",
        if: [
          {
            key: "CAN-S_2a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_2a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_2c",
        label:
          "2.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI\nSERVIZI LOCALI A PROCURARSI E PREPARARSI DA MANGIARE?",
        if: [
          {
            key: "CAN-S_2a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_2a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_2d",
        label:
          "2.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE\nDEI SERVIZI LOCALI A PROCURARSI E PREPARARSI DA MANGIARE?\n",
        if: [
          {
            key: "CAN-S_2a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_2a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_3",
        label: "3) CURA DELLA CASA",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_3a",
        label: "3.1) IL SOGGETTO HA DIFFICOLTÀ A PRENDERSI CURA DELLA CASA?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_3b",
        label:
          "3.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER LA CURA DELLA CASA?",
        if: [
          {
            key: "CAN-S_3a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_3a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_3c",
        label:
          "3.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER LA CURA DELLA CASA?",
        if: [
          {
            key: "CAN-S_3a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_3a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_3d",
        label:
          "3.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER LA CURA DELLA CASA?",
        if: [
          {
            key: "CAN-S_3a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_3a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_4",
        label: "4) CURA DI SÉ",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_4a",
        label: "4.1) IL SOGGETTO HA DIFFICOLTÀ PER LA CURA DI SÉ?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_4b",
        label:
          "4.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER LA CURA DI SÉ?",
        if: [
          {
            key: "CAN-S_4a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_4a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_4c",
        label:
          "4.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER LA CURA DI SÉ?",
        if: [
          {
            key: "CAN-S_4a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_4a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_4d",
        label:
          "4.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER LA CURA DI SÉ?",
        if: [
          {
            key: "CAN-S_4a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_4a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_5",
        label: "5) ATTIVITÀ QUOTIDIANE",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_5a",
        label:
          "5.1) IL SOGGETTO HA DIFFICOLTÀ NELLO SVOLGERE IN MANIERA REGOLARE ED APPROPRIATA LE ATTIVITÀ QUOTIDIANE?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_5b",
        label:
          "5.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER SVOLGERE IN MANIERA REGOLARE ED APPRO- PRIATA LE ATTIVITÀ QUOTIDIANE?",
        if: [
          {
            key: "CAN-S_5a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_5a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_5c",
        label:
          "5.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER SVOLGERE IN MANIERA REGOLARE ED APPROPRIATA LE ATTIVITÀ QUOTIDIANE?",
        if: [
          {
            key: "CAN-S_5a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_5a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_5d",
        label:
          "5.4) DI QUANTO AIUTO HA BISOGNO IL SOGGETTO DA PARTE DEI SERVIZI LOCALI PER SVOLGERE IN MANIERA REGOLARE ED APPROPRIATA LE ATTIVITÀ QUOTIDIANE?",
        if: [
          {
            key: "CAN-S_5a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_5a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_6",
        label: "6) SALUTE FISICA",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_6a",
        label: "6.1) IL SOGGETTO HA QUALCHE DISABILITÀ O MALATTIA FISICA?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_6b",
        label:
          "6.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER I PROBLEMI FISICI?",
        if: [
          {
            key: "CAN-S_6a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_6a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_6c",
        label:
          "6.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER I PROBLEMI FISICI?",
        if: [
          {
            key: "CAN-S_6a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_6a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_6d",
        label:
          "6.4) DI QUANTO AIUTO HA BISOGNO IL SOGGETTO DA PARTE DEI SERVIZI LOCALI PER I PROBLEMI FISICI?",
        if: [
          {
            key: "CAN-S_6a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_6a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_7",
        label: "7) SINTOMI PSICOTICI",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_7a",
        label:
          "7.1) IL SOGGETTO MANIFESTA DEI SINTOMI PSICOTICI, COME CONVINZIONI DELIRANTI, ALLUCINAZIONI, DISTURBO FORMALE DEL PENSIERO O\nP ASSIVITÀ?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_7b",
        label:
          "7.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER I SINTOMI PSICOTICI?",
        if: [
          {
            key: "CAN-S_7a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_7a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_7c",
        label:
          "7.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER I SINTOMI PSICOTICI?",
        if: [
          {
            key: "CAN-S_7a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_7a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_7d",
        label:
          "7.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER I SINTOMI PSICOTICI?",
        if: [
          {
            key: "CAN-S_7a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_7a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_8",
        label: "8) INFORMAZIONI (SU DISTURBI E TRATTAMENTI) ",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_8a",
        label:
          "8.1) IL SOGGETTO HA AVUTO INFORMAZIONI CHIARE, VERBALI O\nSCRITTE, SULLE SUE CONDIZIONI E SUI TRATTAMENTI?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_8b",
        label:
          "8.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER OTTENERE TALI INFORMAZIONI?",
        if: [
          {
            key: "CAN-S_8a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_8a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_8c",
        label:
          "8.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER OTTENERE TALI INFORMAZIONI?",
        if: [
          {
            key: "CAN-S_8a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_8a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_8d",
        label:
          "8.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER OTTENERE TALI INFORMAZIONI?",
        if: [
          {
            key: "CAN-S_8a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_8a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_9",
        label: "9) DISAGIO PSICOLOGICO ",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_9a",
        label: "9.1) ATTUALMENTE IL SOGGETTO PRESENTA UN DISAGIO PSICOLOGICO?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_9b",
        label:
          "9.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER QUESTO DISAGIO?",
        if: [
          {
            key: "CAN-S_9a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_9a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_9c",
        label:
          "9.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER QUESTO DISAGIO?",
        if: [
          {
            key: "CAN-S_9a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_9a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_9d",
        label:
          "9.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER QUESTO DISAGIO?",
        if: [
          {
            key: "CAN-S_9a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_9a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_10",
        label: "10) SICUREZZA PERSONALE",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_10a",
        label: "10.1) IL SOGGETTO RAPPRESENTA UN PERICOLO PER SÉ STESSO?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_10b",
        label:
          "10.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER RIDURRE IL RISCHIO DI GESTI AUTOLESIVI?",
        if: [
          {
            key: "CAN-S_10a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_10a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_10c",
        label:
          "10.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER RIDURRE IL RISCHIO DI GESTI AUTOLESIVI?",
        if: [
          {
            key: "CAN-S_10a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_10a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_10d",
        label:
          "10.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER RIDURRE IL RISCHIO DI GESTI AUTOLESIVI?",
        if: [
          {
            key: "CAN-S_10a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_10a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_11",
        label: "11) SICUREZZA PER GLI ALTRI",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_11a",
        label:
          "11.1) IL SOGGETTO RAPPRESENTA UN RISCHIO ATTUALE O POTENZIALE PER LA SICUREZZA DEGLI ALTRI?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_11b",
        label:
          "11.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER RIDURRE IL RISCHIO DI FAR MALE AGLI ALTRI?",
        if: [
          {
            key: "CAN-S_11a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_11a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_11c",
        label:
          "11.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER RIDURRE IL RISCHIO DI FAR MALE AGLI ALTRI?",
        if: [
          {
            key: "CAN-S_11a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_11a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_11d",
        label:
          "11.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI\nSERVIZI LOCALI PER RIDURRE IL RISCHIO DI FAR MALE AGLI ALTRI?",
        if: [
          {
            key: "CAN-S_11a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_11a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_12",
        label: "12) ABUSO DI ALCOOL",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_12a",
        label:
          "12.1) IL SOGGETTO BEVE ECCESSIVAMENTE OPPURE HA DIFFICOLTÀ A CONTROLLARE IL BERE?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_12b",
        label:
          "12.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER IL BERE?",
        if: [
          {
            key: "CAN-S_12a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_12a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_12c",
        label:
          "12.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER IL BERE?",
        if: [
          {
            key: "CAN-S_12a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_12a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_12d",
        label:
          "12.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER IL BERE?",
        if: [
          {
            key: "CAN-S_12a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_12a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_13",
        label: "13) FARMACI",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_13a",
        label: "13.1) IL SOGGETTO ABUSA DI FARMACI?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_13b",
        label:
          "13.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER L'ABUSO DI FARMACI?",
        if: [
          {
            key: "CAN-S_13a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_13a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_13c",
        label:
          "13.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER L'ABUSO DI FARMACI?",
        if: [
          {
            key: "CAN-S_13a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_13a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_13d",
        label:
          "13.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER L'ABUSO DI FARMACI?",
        if: [
          {
            key: "CAN-S_13a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_13a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_14",
        label: "14) VITA DI RELAZIONE",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_14a",
        label: "14.1) IL SOGGETTO HA BISOGNO DI AIUTO PER I CONTATTI SOCIALI?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_14b",
        label:
          "14.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER I CONTATTI SOCIALI?",
        if: [
          {
            key: "CAN-S_14a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_14a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_14c",
        label:
          "14.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER I CONTATTI SOCIALI?",
        if: [
          {
            key: "CAN-S_14a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_14a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_14d",
        label:
          "14.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER I CONTATTI SOCIALI?",
        if: [
          {
            key: "CAN-S_14a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_14a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_15",
        label: "15) VITA DI COPPIA",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_15a",
        label:
          "15.1) IL SOGGETTO HA DIFFICOLTÀ A TROVARE UN PARTNER O A MANTENERE UNA RELAZIONE INTIMA?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_15b",
        label:
          "15.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER INSTAURARE E MANTENERE RELAZIONI INTIME?",
        if: [
          {
            key: "CAN-S_15a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_15a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_15c",
        label:
          "15.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER INSTAURARE E MANTENERE RELAZIONI INTIME?",
        if: [
          {
            key: "CAN-S_15a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_15a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_15d",
        label:
          "15.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER INSTAURARE E MANTENERE RELAZIONI INTIME?",
        if: [
          {
            key: "CAN-S_15a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_15a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_16",
        label: "16) VITA SESSUALE",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_16a",
        label: "16.1) IL SOGGETTO HA PROBLEMI NELLA VITA SESSUALE?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_16b",
        label:
          "16.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER PROBLEMI LEGATI ALLA VITA SESSUALE?",
        if: [
          {
            key: "CAN-S_16a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_16a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_16c",
        label:
          "16.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER PROBLEMI LEGATI ALLA VITA SESSUALE?",
        if: [
          {
            key: "CAN-S_16a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_16a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_16d",
        label:
          "16.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER PROBLEMI LEGATI ALLA VITA SESSUALE?",
        if: [
          {
            key: "CAN-S_16a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_16a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_17",
        label: "17) CURA DEI FIGLI",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_17a",
        label: "17.1) IL SOGGETTO HA DIFFICOLTÀ A PRENDERSI CURA DEI FIGLI?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_17b",
        label:
          "17.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER LA CURA DEI FIGLI?",
        if: [
          {
            key: "CAN-S_17a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_17a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_17c",
        label:
          "17.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER LA CURA DEI FIGLI?",
        if: [
          {
            key: "CAN-S_17a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_17a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_17d",
        label:
          "17.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER LA CURA DEI FIGLI?",
        if: [
          {
            key: "CAN-S_17a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_17a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_18",
        label: "18) ISTRUZIONE DI BASE",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_18a",
        label:
          "18.1) IL SOGGETTO È PRIVO DI CONOSCENZE ELEMENTARI IN\nARITMETICA, LETTURA E SCRITTURA?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_18b",
        label:
          "18.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER PROBLEMI DI CALCOLO, LETTURA E SCRITTURA?",
        if: [
          {
            key: "CAN-S_18a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_18a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_18c",
        label:
          "18.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER PROBLEMI DI CALCOLO, LETTURA E SCRITTURA?",
        if: [
          {
            key: "CAN-S_18a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_18a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_18d",
        label:
          "18.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER PROBLEMI DI CALCOLO, LETTURA E SCRITTURA?",
        if: [
          {
            key: "CAN-S_18a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_18a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_19",
        label: "19) TELEFONO",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_19a",
        label:
          "19.1) IL SOGGETTO HA DIFFICOLTÀ AD ACCEDERE O AD UTILIZZARE UN TELEFONO?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_19b",
        label:
          "19.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER FARE DELLE TELEFONATE?",
        if: [
          {
            key: "CAN-S_19a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_19a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_19c",
        label:
          "19.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER FARE DELLE TELEFONTE? ",
        if: [
          {
            key: "CAN-S_19a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_19a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_19d",
        label:
          "19.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER FARE DELLE TELEFONATE?",
        if: [
          {
            key: "CAN-S_19a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_19a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_20",
        label: "20) TRASPORTI",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_20a",
        label: "20.1) IL SOGGETTO HA DIFFICOLTÀ AD USARE I TRASPORTI PUBBLICI?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_20b",
        label:
          "20.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER VIAGGIARE?",
        if: [
          {
            key: "CAN-S_20a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_20a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_20c",
        label:
          "20.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER VIAGGIARE?",
        if: [
          {
            key: "CAN-S_20a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_20a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_20d",
        label:
          "20.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER VIAGGIARE?",
        if: [
          {
            key: "CAN-S_20a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_20a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_21",
        label: "21) DENARO",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_21a",
        label: "21.1) IL SOGGETTO HA PROBLEMI PER LA GESTIONE DEL DENARO?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_21b",
        label:
          "21.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER LA GESTIONE DEL DENARO?",
        if: [
          {
            key: "CAN-S_21a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_21a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_21c",
        label:
          "21.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI ASSISTENZIALI PER LA GESTIONE DEL DENARO?",
        if: [
          {
            key: "CAN-S_21a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_21a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_21d",
        label:
          "21.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI ASSISTENZIALI PER LA GESTIONE DEL DENARO?",
        if: [
          {
            key: "CAN-S_21a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_21a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_22",
        label: "22) SUSSIDI",
        if: null,
        type: "description"
      },
      {
        key: "CAN-S_22a",
        label: "22.1) IL SOGGETTO RICEVE TUTTI I SUSSIDI CHE GLI SPETTANO?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessun problema"
          },
          {
            value: 1,
            label: "1= Nessun/moderato problema grazie all'aiuto fornito"
          },
          {
            value: 2,
            label: "2= Grave problema"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_22b",
        label:
          "22.2) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DI AMICI O PARENTI PER OTTENERE I SUSSIDI DI CUI HA DIRITTO?",
        if: [
          {
            key: "CAN-S_22a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_22a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_22c",
        label:
          "22.3) QUANTO AIUTO IL SOGGETTO RICEVE DA PARTE DEI SERVIZI LOCALI PER OTTENERE I SUSSIDI DI CUI HA DIRITTO?",
        if: [
          {
            key: "CAN-S_22a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_22a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      },
      {
        key: "CAN-S_22d",
        label:
          "22.4) DI QUANTO AIUTO IL SOGGETTO HA BISOGNO DA PARTE DEI SERVIZI LOCALI PER OTTENERE I SUSSIDI DI CUI HA DIRITTO?",
        if: [
          {
            key: "CAN-S_22a",
            comparator: "=",
            value: 1
          },
          {
            key: "CAN-S_22a",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuno"
          },
          {
            value: 1,
            label: "1= Aiuto scarso"
          },
          {
            value: 2,
            label: "2= Aiuto moderato"
          },
          {
            value: 3,
            label: "3= Aiuto elevato"
          },
          {
            value: 9,
            label: "9= Sconosciuto"
          }
        ]
      }
    ]
  },
  {
    type: "ZTPI",
    name: "ZIMBARDO TIME PERSPECTIVE INVENTORY (ZTPI) ",
    codiceDataCompletamento: "DATA_09",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Ambulatoriale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      }
    ],
    domande: [
      {
        key: "DATA_09",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "ZTPI_0",
        label:
          "Ti preghiamo di leggere ciascuna frase e, più sinceramente che puoi, rispondi alla domanda: “Quanto questo è tipico o vero di te?” Fai un segno nella casella corrispondente\nusando la scala da:\n1 (Molto falso) a 3 (Neutro, Né l’uno né l’altro) a 5 (Molto vero).",
        if: null,
        type: "description"
      },
      {
        key: "ZTPI_1",
        label:
          "1) Credo che festeggiare con gli amici sia uno dei piaceri importanti della vita ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_2",
        label:
          "2) Le immagini, i suoni, gli odori familiari dell’infanzia richiamano una quantità di ricordi meravigliosi ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_3",
        label: "3) Il destino conta molto nella mia vita ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_4",
        label:
          "4) Spesso penso alle cose che avrei dovuto agire in modo diverso nella mia vita ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_5",
        label:
          "5) Le mie decisioni sono molto influenzate dalle persone e dalle cose intorno a me ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_6",
        label:
          "6) Io penso che la giornata di una persona dovrebbe essere programmata in anticipo ogni mattina ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_7",
        label: "7) Mi da piacere pensare al mio passato ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_8",
        label: "8) Io faccio le cose impulsivamente ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_9",
        label: "9) Non mi preoccupo se le cose non sono fatte in tempo ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_10",
        label:
          "10) Quando io voglio raggiungere qualcosa, io stabilisco gli obiettivi e considero i mezzi specifici per raggiungerli ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_11",
        label:
          "11) Complessivamente ci sono più cose belle che brutte da ricordare nel mio passato ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_12",
        label:
          "12) Io spesso perdo la nozione del tempo, quando ascolto la mia musica preferita ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_13",
        label:
          "13) Rispettare le scadenze di domani ed eseguire il lavoro che è necessario completare viene prima del divertimento di stasera ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_14",
        label:
          "14) Dato che quello che sarà sarà, non è così importante ciò che faccio ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_15",
        label:
          "15) Mi piace sentir raccontare storie di: “come erano le cose nei bei vecchi tempi” ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_16",
        label:
          "16) Le esperienze dolorose che ho vissuto in passato continuano a tornarmi in mente ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_17",
        label: "17) Cerco di vivere giorno dopo giorno pienamente la mia vita ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_18",
        label: "18) Mi disturba molto arrivare in ritardo agli appuntamenti ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_19",
        label:
          "19) Per me l’ideale sarebbe vivere ogni giorno come se fosse l’ultimo ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_20",
        label:
          "20) I ricordi felici dei bei tempi nascono spesso nella mia mente ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_21",
        label:
          "21) Rispetto sempre nei tempi stabiliti gli impegni verso i miei amici e le autorità ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_22",
        label:
          "22) Ho avuto la mia parte di maltrattamenti e\\o di rifiuti nel passato ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_23",
        label: "23) Io prendo decisioni su due piedi ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_24",
        label:
          "24) Vivo la mia giornata come viene piuttosto che programmarla ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_25",
        label:
          "25) Il passato ha troppi brutti ricordi a cui io preferisco non pensare ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_26",
        label: "26) È importante rendere eccitante la mia vita ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_27",
        label:
          "27) Vorrei poter cancellare gli sbagli che ho fatto in passato ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_28",
        label:
          "28) Io penso che sia molto più importante goderti ciò che stai facendo che farlo in tempo ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_29",
        label: "29) Ho nostalgia della mia infanzia ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_30",
        label: "30) Prima di prendere una decisione valuto i pro ed i contro ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_31",
        label: "31) Rischiare tiene lontana la noia dalla mia vita ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_32",
        label:
          "32) E’ più importante per me godersi il viaggio che concentrarsi sulla destinazione ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_33",
        label: "33) Le cose raramente riescono come me le aspettavo ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_34",
        label:
          "34) Per me è molto difficile dimenticare i ricordi spiacevoli della mia gioventù ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_35",
        label:
          "35) Traggo piacere dall’andamento delle mie attività se non devo pensare agli scopi, ai risultati ed esiti ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_36",
        label:
          "36) Anche quando mi sto godendo il presente non posso fare a meno di fare paragoni con eventi simili passati ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_37",
        label:
          "37) Tu non puoi in realtà fare piani per il futuro perché le cose cambiano tanto ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_38",
        label:
          "38) Il percorso della mia vita è controllato da forze che io non posso influenzare ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_39",
        label:
          "39) Non ha senso preoccuparsi per il futuro perché in ogni modo non c’è niente che io possa fare ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_40",
        label:
          "40) Attraverso una costante progressione io completo i progetti in tempo ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_41",
        label:
          "41) Io non mi trovo in sintonia quando i membri della mia famiglia parlano del modo in cui andavano le cose un tempo ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_42",
        label: "42) Assumo rischi per rendere la mia vita più eccitante ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_43",
        label: "43) Io faccio un elenco delle cose da fare ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_44",
        label: "44) Io spesso seguo più il mio cuore che la mia testa ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_45",
        label:
          "45) Io so resistere alle tentazioni quando so che c’è del lavoro da fare ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_46",
        label: "46) Io mi trovo travolto dall’eccitazione del momento ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_47",
        label:
          "47) La vita oggi è troppo complicata, io preferirei la vita più semplice del passato ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_48",
        label: "48) Io preferisco amici spontanei più che prevedibili ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_49",
        label:
          "49) Mi piacciono i rituali familiari e le tradizioni che si ripetono regolarmente ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_50",
        label: "50) Ripenso alle cose brutte che mi sono successe in passato ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_51",
        label:
          "51) Io continuo a lavorare su cose difficili, non interessanti, se esse mi aiutano a progredire ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_52",
        label:
          "52) Per me è meglio spendere ciò che guadagno in divertimenti, piuttosto che risparmiarlo per la sicurezza di domani ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_53",
        label: "53) Spesso la fortuna paga meglio che il duro lavoro ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_54",
        label: "54) Ripenso alle belle cose che mi sono perso nella vita ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_55",
        label: "55) Mi piace che le mie relazioni intime siano appassionate ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      },
      {
        key: "ZTPI_56",
        label:
          "56) Ci sarà sempre tempo di mettersi in pari con le cose da fare ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Molto falso"
          },
          {
            value: 2,
            label: "2= Falso"
          },
          {
            value: 3,
            label: "3= Neutro"
          },
          {
            value: 4,
            label: "4= Vero"
          },
          {
            value: 5,
            label: "5= Molto vero"
          }
        ]
      }
    ]
  },
  {
    type: "P SCALE",
    name: "POSITIVITY SCALE (P SCALE)",
    codiceDataCompletamento: "DATA_10",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Ambulatoriale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      }
    ],
    domande: [
      {
        key: "DATA_10",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "P_SCALE_0",
        label:
          "Per ciascuna delle affermazioni che seguono, valuti il suo grado di accordo/ disaccordo su una scala da 1 (massimo disaccordo) a 5 (massimo accordo) mettendo una crocetta sul numero corrispondente alla sua opinione.\nLegga attentamente le affermazioni, e cerchi di rispondere con la massima spontaneità. Non ci sono risposte giuste o sbagliate.",
        if: null,
        type: "description"
      },
      {
        key: "P_SCALE_1",
        label: "1) Ho una grande fiducia nel futuro",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= SONO FORTEMENTE IN DISACCORDO"
          },
          {
            value: 2,
            label: "2=  SONO PARZIALMENTE IN DISACCORDO"
          },
          {
            value: 3,
            label: "3= NON SONO NÉ D’ACCORDO NÉ IN DISACCORDO"
          },
          {
            value: 4,
            label: "4= SONO PARZIALMENTE D’ACCORDO"
          },
          {
            value: 5,
            label: "5= SONO FORTEMENTE D’ACCORDO"
          }
        ]
      },
      {
        key: "P_SCALE_2",
        label: "2)  Sono soddisfatto della mia vita",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= SONO FORTEMENTE IN DISACCORDO"
          },
          {
            value: 2,
            label: "2=  SONO PARZIALMENTE IN DISACCORDO"
          },
          {
            value: 3,
            label: "3= NON SONO NÉ D’ACCORDO NÉ IN DISACCORDO"
          },
          {
            value: 4,
            label: "4= SONO PARZIALMENTE D’ACCORDO"
          },
          {
            value: 5,
            label: "5= SONO FORTEMENTE D’ACCORDO"
          }
        ]
      },
      {
        key: "P_SCALE_3",
        label:
          "3) Quando ne ho bisogno, solitamente ho qualcuno sul quale posso contare",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= SONO FORTEMENTE IN DISACCORDO"
          },
          {
            value: 2,
            label: "2=  SONO PARZIALMENTE IN DISACCORDO"
          },
          {
            value: 3,
            label: "3= NON SONO NÉ D’ACCORDO NÉ IN DISACCORDO"
          },
          {
            value: 4,
            label: "4= SONO PARZIALMENTE D’ACCORDO"
          },
          {
            value: 5,
            label: "5= SONO FORTEMENTE D’ACCORDO"
          }
        ]
      },
      {
        key: "P_SCALE_4",
        label: "4) Guardo al futuro con speranza ed entusiasmo",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= SONO FORTEMENTE IN DISACCORDO"
          },
          {
            value: 2,
            label: "2=  SONO PARZIALMENTE IN DISACCORDO"
          },
          {
            value: 3,
            label: "3= NON SONO NÉ D’ACCORDO NÉ IN DISACCORDO"
          },
          {
            value: 4,
            label: "4= SONO PARZIALMENTE D’ACCORDO"
          },
          {
            value: 5,
            label: "5= SONO FORTEMENTE D’ACCORDO"
          }
        ]
      },
      {
        key: "P_SCALE_5",
        label: "5) Complessivamente sono soddisfatto di me stesso",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= SONO FORTEMENTE IN DISACCORDO"
          },
          {
            value: 2,
            label: "2=  SONO PARZIALMENTE IN DISACCORDO"
          },
          {
            value: 3,
            label: "3= NON SONO NÉ D’ACCORDO NÉ IN DISACCORDO"
          },
          {
            value: 4,
            label: "4= SONO PARZIALMENTE D’ACCORDO"
          },
          {
            value: 5,
            label: "5= SONO FORTEMENTE D’ACCORDO"
          }
        ]
      },
      {
        key: "P_SCALE_6",
        label: "6) A volte il futuro mi sembra davvero oscuro",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= SONO FORTEMENTE IN DISACCORDO"
          },
          {
            value: 2,
            label: "2=  SONO PARZIALMENTE IN DISACCORDO"
          },
          {
            value: 3,
            label: "3= NON SONO NÉ D’ACCORDO NÉ IN DISACCORDO"
          },
          {
            value: 4,
            label: "4= SONO PARZIALMENTE D’ACCORDO"
          },
          {
            value: 5,
            label: "5= SONO FORTEMENTE D’ACCORDO"
          }
        ]
      },
      {
        key: "P_SCALE_7",
        label: "7) Penso di avere molto di cui essere fiero",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= SONO FORTEMENTE IN DISACCORDO"
          },
          {
            value: 2,
            label: "2=  SONO PARZIALMENTE IN DISACCORDO"
          },
          {
            value: 3,
            label: "3= NON SONO NÉ D’ACCORDO NÉ IN DISACCORDO"
          },
          {
            value: 4,
            label: "4= SONO PARZIALMENTE D’ACCORDO"
          },
          {
            value: 5,
            label: "5= SONO FORTEMENTE D’ACCORDO"
          }
        ]
      },
      {
        key: "P_SCALE_8",
        label: "8) Generalmente ho molta fiducia in me stesso/a",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= SONO FORTEMENTE IN DISACCORDO"
          },
          {
            value: 2,
            label: "2=  SONO PARZIALMENTE IN DISACCORDO"
          },
          {
            value: 3,
            label: "3= NON SONO NÉ D’ACCORDO NÉ IN DISACCORDO"
          },
          {
            value: 4,
            label: "4= SONO PARZIALMENTE D’ACCORDO"
          },
          {
            value: 5,
            label: "5= SONO FORTEMENTE D’ACCORDO"
          }
        ]
      }
    ]
  },
  {
    type: "WHODAS 2.0",
    name: "WHO DISABILITY ASSESSMENT SCHEDULE (WHODAS) ",
    codiceDataCompletamento: "DATA_11",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Ambulatoriale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      }
    ],
    domande: [
      {
        key: "DATA_11",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "WHODAS_0",
        label:
          "Questo questionario esplora le difficoltà che sono in relazione a problemi di salute. Per problemi di salute si intendono malattie o altri disturbi che possono essere di breve o di lunga durata, traumi, problemi mentali o emotivi e problemi con alcol o droghe.\nRipensi agli ultimi 30 giorni e risponda alle domande pensando a quanta difficoltà ha avuto nello svolgere le attività di seguito elencate.\nPer ogni domanda, deve indicare un solo numero per ciascuna voce.",
        if: null,
        type: "description"
      },
      {
        key: "WHODAS_1",
        label: "S1) Stare in piedi per un lungo periodo, come per 30 minuti? ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuna"
          },
          {
            value: 1,
            label: "1= Poca"
          },
          {
            value: 2,
            label: "2= Moderata"
          },
          {
            value: 3,
            label: "3= Molta"
          },
          {
            value: 4,
            label: "4= Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_2",
        label:
          "S2) Prendersi cura della casa e della famiglia per quanto\nè di sua responsabilità?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuna"
          },
          {
            value: 1,
            label: "1= Poca"
          },
          {
            value: 2,
            label: "2= Moderata"
          },
          {
            value: 3,
            label: "3= Molta"
          },
          {
            value: 4,
            label: "4= Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_3",
        label:
          "S3) Imparare cose nuove, come, per esempio, imparare a raggiungere un posto nuovo?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuna"
          },
          {
            value: 1,
            label: "1= Poca"
          },
          {
            value: 2,
            label: "2= Moderata"
          },
          {
            value: 3,
            label: "3= Molta"
          },
          {
            value: 4,
            label: "4= Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_4",
        label:
          "S4) Quanti problemi ha avuto nel partecipare ad attività comunitarie (per esempio, feste, attività religiose o di altro tipo) come chiunque altro?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuna"
          },
          {
            value: 1,
            label: "1= Poca"
          },
          {
            value: 2,
            label: "2= Moderata"
          },
          {
            value: 3,
            label: "3= Molta"
          },
          {
            value: 4,
            label: "4= Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_5",
        label:
          "S5) Quanto è stato coinvolto emotivamente dal suo problema di salute?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuna"
          },
          {
            value: 1,
            label: "1= Poca"
          },
          {
            value: 2,
            label: "2= Moderata"
          },
          {
            value: 3,
            label: "3= Molta"
          },
          {
            value: 4,
            label: "4= Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_6",
        label: "S6) Concentrarsi nel fare qualcosa per dieci minuti?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuna"
          },
          {
            value: 1,
            label: "1= Poca"
          },
          {
            value: 2,
            label: "2= Moderata"
          },
          {
            value: 3,
            label: "3= Molta"
          },
          {
            value: 4,
            label: "4= Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_7",
        label: "S7) Camminare per una lunga distanza, come per un chilometro?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuna"
          },
          {
            value: 1,
            label: "1= Poca"
          },
          {
            value: 2,
            label: "2= Moderata"
          },
          {
            value: 3,
            label: "3= Molta"
          },
          {
            value: 4,
            label: "4= Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_8",
        label: "S8) Lavarsi tutto il corpo?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuna"
          },
          {
            value: 1,
            label: "1= Poca"
          },
          {
            value: 2,
            label: "2= Moderata"
          },
          {
            value: 3,
            label: "3= Molta"
          },
          {
            value: 4,
            label: "4= Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_9",
        label: "S9) Vestirsi?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuna"
          },
          {
            value: 1,
            label: "1= Poca"
          },
          {
            value: 2,
            label: "2= Moderata"
          },
          {
            value: 3,
            label: "3= Molta"
          },
          {
            value: 4,
            label: "4= Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_10",
        label: "S10)  Interagire con persone che non conosce?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuna"
          },
          {
            value: 1,
            label: "1= Poca"
          },
          {
            value: 2,
            label: "2= Moderata"
          },
          {
            value: 3,
            label: "3= Molta"
          },
          {
            value: 4,
            label: "4= Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_11",
        label: "S11) Mantenere un’amicizia?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuna"
          },
          {
            value: 1,
            label: "1= Poca"
          },
          {
            value: 2,
            label: "2= Moderata"
          },
          {
            value: 3,
            label: "3= Molta"
          },
          {
            value: 4,
            label: "4= Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_12",
        label: "S12) Svolgere l’attività lavorativa/scolastica quotidiana?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Nessuna"
          },
          {
            value: 1,
            label: "1= Poca"
          },
          {
            value: 2,
            label: "2= Moderata"
          },
          {
            value: 3,
            label: "3= Molta"
          },
          {
            value: 4,
            label: "4= Moltissima o non posso farlo"
          }
        ]
      },
      {
        key: "WHODAS_13",
        label:
          "H1) Complessivamente, negli ultimi 30 giorni, per quanti giorni ha avuto queste difficoltà?",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: null
      },
      {
        key: "WHODAS_14",
        label:
          "H2) Negli ultimi 30 giorni, per quanti giorni è stato impossibilitato a svolgere le attività o il lavoro abituali a causa delle sue condizioni di salute?",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: null
      },
      {
        key: "WHODAS_15",
        label:
          "H3) Negli ultimi 30 giorni, senza contare i giorni in cui è stato impossibilitato, per quanti giorni ha diminuito o ridotto le attività o il lavoro abituale a causa delle sue condizioni di salute?",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: null
      }
    ]
  },
  {
    type: "WHOQOL",
    name:
      "WORLD HEALTH ORGANIZATION QUALITY OF LIFE - Versione breve  (WHOQOL-Bref)",
    codiceDataCompletamento: "DATA_12",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Ambulatoriale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      }
    ],
    domande: [
      {
        key: "DATA_12",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "WHOQOL_0",
        label:
          "Questo questionario riguarda quello che Lei pensa della qualità della Sua vita, della Sua salute, e di altri aspetti della Sua vita. Per favore, risponda a tutte le domande:\nse è incerto sulla risposta da fornire ad una domanda, scelga, per favore, quella che corrisponde di più alla sua opinione; spesso si tratterà della risposta che Le è venuta in mente per prima.\nPer favore, nel rispondere tenga conto delle sue abitudini di vita, delle Sue speranze, dei Suoi gusti e delle Sue preoccupazioni e pensi a quello che Le è successo negli ultimi 15 giorni. Lei deve fare un segno sul numero che meglio esprime in che misura Lei ha ricevuto l’aiuto di cui aveva bisogno negli ultimi 15 giorni.\nQuindi, Lei deve fare un segno sul numero 4 se ha ricevuto “Molto” aiuto dagli altri, o sul numero 1 se non ha ricevuto “Per niente” l’aiuto di cui aveva bisogno.\nLegga attentamente ogni domanda, rifletta sui Suoi stati d’animo e, come Le abbiamo già suggerito, faccia un segno sulla risposta che meglio corrisponde alla sua opinione o ai sui sentimenti.",
        if: null,
        type: "description"
      },
      {
        key: "WHOQOL_1",
        label: "1) Come valuta la qualità della sua vita?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto cattiva"
          },
          {
            value: 2,
            label: "2= Cattiva"
          },
          {
            value: 3,
            label: "3= Nè cattiva nè buona"
          },
          {
            value: 4,
            label: "4= Buona"
          },
          {
            value: 5,
            label: "5= Molto buona"
          }
        ]
      },
      {
        key: "WHOQOL_2",
        label: "2) È soddisfatto/a della sua salute?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_3",
        label:
          "3) In che misura i dolori fisici le impediscono di fare le cose che deve fare?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_4",
        label:
          "4) Ha bisogno di trattamenti o interventi medici per poter affrontare la vita di tutti i giorni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_5",
        label: "5) Quanto si gode la vita?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_6",
        label:
          "6) In che misura lei pensa che la sua vita abbia un significato?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_7",
        label: "7) Riesce a concentrarsi nelle cose che fa?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_8",
        label: "8) Quanto si sente al sicuro nella sua vita di tutti i giorni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_9",
        label:
          "9) L’ambiente in cui vive è sicuro per la salute?\n(nel senso del rumore, inquinamento, clima ed altre caratteristiche ambientali)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_10",
        label:
          "10) Ha l’energia necessaria per poter svolgere le attività di tutti i giorni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= In gran parte"
          },
          {
            value: 5,
            label: "5= Completamente"
          }
        ]
      },
      {
        key: "WHOQOL_11",
        label: "11) Accetta di buon grado il suo aspetto esteriore?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_12",
        label:
          "12) Le sue risorse economiche le bastano per soddisfare i suoi bisogni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= In gran parte"
          },
          {
            value: 5,
            label: "5= Completamente"
          }
        ]
      },
      {
        key: "WHOQOL_13",
        label:
          "13) Le informazioni di cui dispone le bastano per la vita di tutti i giorni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= In gran parte"
          },
          {
            value: 5,
            label: "5= Completamente"
          }
        ]
      },
      {
        key: "WHOQOL_14",
        label:
          "14) Ha la possibilità di dedicarsi ad attività di svago nel tempo libero?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= In gran parte"
          },
          {
            value: 5,
            label: "5= Completamente"
          }
        ]
      },
      {
        key: "WHOQOL_15",
        label: "15) In che misura riesce a muoversi?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Per niente"
          },
          {
            value: 2,
            label: "2= Poco"
          },
          {
            value: 3,
            label: "3= Abbastanza"
          },
          {
            value: 4,
            label: "4= Molto"
          },
          {
            value: 5,
            label: "5= Moltissimo"
          }
        ]
      },
      {
        key: "WHOQOL_16",
        label: "16) È soddisfatto/a di come dorme?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_17",
        label:
          "17) È soddisfatto/a di come riesce a fare le cose di tutti i giorni?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_18",
        label:
          "18) È soddisfatto/a della sua capacità di impegnarsi in attività?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_19",
        label: "19) È soddisfatto/a di sé stesso?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_20",
        label: "20) È soddisfatto/a dei suoi rapporti personali con gli altri?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_21",
        label: "21) È soddisfatto/a della sua vita sessuale? ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_22",
        label: "22) È soddisfatto/a del sostegno che riceve dai suoi amici?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_23",
        label: "23) È soddisfatto/a delle condizioni della sua abitazione?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_24",
        label:
          "24) È soddisfatto/a della disponibilità ed accessibilità dei servizi sanitari?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_25",
        label:
          "25) È soddisfatto/a dei mezzi di trasporto che ha a disposizione?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Molto insoddisfatto/a"
          },
          {
            value: 2,
            label: "2= Insoddisfatto/a"
          },
          {
            value: 3,
            label: "3= Né insoddisfatto/a né soddisfatto/a"
          },
          {
            value: 4,
            label: "4= Soddisfatto/a"
          },
          {
            value: 5,
            label: "5= Molto soddisfatto/a"
          }
        ]
      },
      {
        key: "WHOQOL_26",
        label:
          "26) Quanto spesso prova sentimenti negativi, come cattivo umore, disperazione, ansia o depressione?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= Mai"
          },
          {
            value: 2,
            label: "2= Raramente"
          },
          {
            value: 3,
            label: "3= Abbastanza Spesso"
          },
          {
            value: 4,
            label: "4= Molto Spesso"
          },
          {
            value: 5,
            label: "5= Sempre"
          }
        ]
      }
    ]
  },
  {
    type: "AOTS",
    name: "Autostima - Ottimismo - Soddisfazione di vita (AOTS)",
    codiceDataCompletamento: "DATA_13",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Ambulatoriale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      }
    ],
    domande: [
      {
        key: "DATA_13",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "AOTS_0",
        label:
          "Per ciascuna delle affermazioni che seguono, valuti il suo grado di accordo/disaccordo su una scala da 1 (massimo disaccordo) a 5 (massimo accordo) mettendo una crocetta sul numero corrispondente alla sua opinione. Legga attentamente le affermazioni, e cerchi di rispondere con la massima spontaneità. Non ci sono risposte giuste o sbagliate.",
        if: null,
        type: "description"
      },
      {
        key: "AOTS_1",
        label: "1) Penso di valere almeno quanto gli altri",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= SONO FORTEMENTE IN DISACCORDO"
          },
          {
            value: 2,
            label: "2= SONO PARZIALMENTE IN DISACCORDO"
          },
          {
            value: 3,
            label: "3= NON SONO NÉ D’ACCORDO NÉ IN DISACCORDO"
          },
          {
            value: 4,
            label: "4= SONO PARZIALMENTE D’ACCORDO"
          },
          {
            value: 5,
            label: "5= SONO FORTEMENTE D’ACCORDO"
          }
        ]
      },
      {
        key: "AOTS_2",
        label:
          "2) Sono in grado di fare le cose bene almeno come la maggior parte delle altre persone",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= SONO FORTEMENTE IN DISACCORDO"
          },
          {
            value: 2,
            label: "2= SONO PARZIALMENTE IN DISACCORDO"
          },
          {
            value: 3,
            label: "3= NON SONO NÉ D’ACCORDO NÉ IN DISACCORDO"
          },
          {
            value: 4,
            label: "4= SONO PARZIALMENTE D’ACCORDO"
          },
          {
            value: 5,
            label: "5= SONO FORTEMENTE D’ACCORDO"
          }
        ]
      },
      {
        key: "AOTS_3",
        label:
          "3) Se qualcosa può andare per me per il verso sbagliato, sicuramente ci andrà",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= SONO FORTEMENTE IN DISACCORDO"
          },
          {
            value: 2,
            label: "2= SONO PARZIALMENTE IN DISACCORDO"
          },
          {
            value: 3,
            label: "3= NON SONO NÉ D’ACCORDO NÉ IN DISACCORDO"
          },
          {
            value: 4,
            label: "4= SONO PARZIALMENTE D’ACCORDO"
          },
          {
            value: 5,
            label: "5= SONO FORTEMENTE D’ACCORDO"
          }
        ]
      },
      {
        key: "AOTS_4",
        label:
          "4) In generale mi aspetto che mi accadranno più cose positive che negative",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= SONO FORTEMENTE IN DISACCORDO"
          },
          {
            value: 2,
            label: "2= SONO PARZIALMENTE IN DISACCORDO"
          },
          {
            value: 3,
            label: "3= NON SONO NÉ D’ACCORDO NÉ IN DISACCORDO"
          },
          {
            value: 4,
            label: "4= SONO PARZIALMENTE D’ACCORDO"
          },
          {
            value: 5,
            label: "5= SONO FORTEMENTE D’ACCORDO"
          }
        ]
      },
      {
        key: "AOTS_5",
        label:
          "5) Se potessi vivere nuovamente la mia vita non cambierei praticamente nulla",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= SONO FORTEMENTE IN DISACCORDO"
          },
          {
            value: 2,
            label: "2= SONO PARZIALMENTE IN DISACCORDO"
          },
          {
            value: 3,
            label: "3= NON SONO NÉ D’ACCORDO NÉ IN DISACCORDO"
          },
          {
            value: 4,
            label: "4= SONO PARZIALMENTE D’ACCORDO"
          },
          {
            value: 5,
            label: "5= SONO FORTEMENTE D’ACCORDO"
          }
        ]
      }
    ]
  },
  {
    type: "WAI-P",
    name: "WORKING ALLIANCE INVENTORY - Versione Paziente (WAI-P)",
    codiceDataCompletamento: "DATA_14",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      }
    ],
    domande: [
      {
        key: "DATA_14",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "WAI-P_0",
        label:
          "Le presentiamo una serie di quesiti che provano a definire alcuni aspetti del rapporto con il suo terapeuta. Mentre legge i quesiti inserisca mentalmente il nome del/la suo/a terapeuta al posto dello spazio lasciato libero nel testo. Utilizzando la scala a 7 punti di seguito riportata, indichi, cerchiando il numero, il grado con cui ciascun item descrive ciò che lei pensa.\nQuesto questionario è strettamente personale: né al suo terapeuta né alla struttura dove ha luogo la terapia verranno comunicate le sue risposte. Mi raccomando, risponda ad ogni quesito senza pensarci troppo: ciò che conta sono le sue prime impressioni.\nSi ricordi inoltre di rispondere a tutti i quesiti. Grazie per la collaborazione.",
        if: null,
        type: "description"
      },
      {
        key: "WAI-P_1",
        label:
          "1) ............................. e io siamo d’accordo sulle cose di cui ho bisogno in terapia per migliorare la mia situazione.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-P_2",
        label:
          "2) Ciò che sto facendo in terapia mi dà la possibilità di guardare in modo diverso i miei problemi.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-P_3",
        label:
          "3) Credo di piacere a .....................................................",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-P_4",
        label:
          "4) ........................................... non capisce ciò che io sto cercando di ottenere dalla terapia.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-P_5",
        label:
          "5) Ho fiducia nelle capacità di ....................................... nell’aiutarmi.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-P_6",
        label:
          "6) .................................. e io siamo impegnati in uno sforzo comune per raggiungere obiettivi concordati",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-P_7",
        label:
          "7) Sento che ....................................................... mi apprezza.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-P_8",
        label:
          "8) Siamo d’accordo sulle cose su cui è importante che io lavori.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-P_9",
        label:
          "9) .............................................. e io ci fidiamo l’uno dell’altro.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-P_10",
        label:
          "10) ............................................. e io abbiamo idee differenti su quali sono i miei reali problemi.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-P_11",
        label:
          "11) Abbiamo stabilito un buon livello di comprensione reciproca sul tipo di cambiamenti che sarebbero giusti per me.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-P_12",
        label:
          "12) Credo che la strada intrapresa per risolvere i miei problemi sia quella giusta.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      }
    ]
  },
  {
    type: "VSSS",
    name: "VERONA SERVICE SATISFACTION SCALE (VSSS)",
    codiceDataCompletamento: "DATA_15",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      }
    ],
    domande: [
      {
        key: "DATA_15",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "VSSS_0",
        label:
          "LE DOMANDE CONTENUTE NEL QUESTIONARIO RIGUARDANO LA SUA ESPERIENZA NELL’ ULTIMO ANNO CON LA STRUTTURA RESIDENZIALE ........................................\nÈ molto importante che le sue risposte siano sincere; esprima liberamente la Sua opinione, qualunque essa sia. Ci interessa particolarmente conoscere le Sue critiche e gli aspetti negativi della Sua esperienza con il Servizio.\nTutte le risposte saranno trattate con la massima riservatezza.\nNessuno, compreso il personale del Servizio ed i Suoi familiari, verrà mai a conoscenza delle opinioni da Lei espresse in questa sede.\nPer favore, contatti l’operatore se qualche domanda non Le sembra chiara o se ha qualche problema nel compilare il questionario.\nLegga con attenzione le domande e rifletta con calma prima di rispondere.\nRicordi che è molto importante che ogni risposta rappresenti la Sua vera opinione!\nDI SEGUITO SONO ELENCATI VARI ASPETTI DELLA SUA ESPERIENZA CON LA STRUTTURA RESIDENZIALE DI ............................................... NELL’ ULTIMO ANNO. Per favore, legga ciascuna frase ed indichi la risposta che meglio descrive la Sua esperienza con la Struttura residenziale in questione nell’ultimo anno: QUAL  E' LA SUA IMPRESSIONE COMPLESSIVA SU ..................................... Legga con attenzione le domande e rifletta con calma prima di rispondere.\nRicordi che per noi è molto importante che ogni risposta rappresenti la Sua vera opinione.",
        if: null,
        type: "description"
      },
      {
        key: "VSSS_1",
        label:
          "1) L’efficacia della Struttura residenziale nell’aiutarLa ad affrontare i Suoi problemi.",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_2",
        label:
          "2) L’aspetto, la disposizione e la funzionalità degli ambienti (ad esempio, le sale d’attesa e gli ambulatori).",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_3",
        label:
          "3) La capacità degli psichiatri e degli psicologi di ascoltare e comprendere i Suoi problemi.",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_4",
        label:
          "4) Il comportamento e le maniere degli psichiatri e degli psicologi",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_5",
        label:
          "5) Il costo a Suo carico dei servizi offerti (ad es. le spese per i farmaci, il ticket, gli esami, escluse le spese coperte dal Servizio Sanitario Nazionale)",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_6",
        label: "6) La risposta della struttura residenziale alle emergenze.",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_7",
        label:
          "7) La capacità degli psichiatri e degli psicologi di collaborare con il Suo medico di famiglia o con altri medici specialisti, se necessario.",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_8",
        label: "8) Il servizio che ha ricevuto, in generale.",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_9",
        label: "9) Il comportamento e le maniere degli infermieri.",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_10",
        label:
          "10) L’efficacia della Struttura residenziale nel migliorare i rapporti fra Lei ed il Suo familiare più stretto.",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_11",
        label:
          "11) L’efficacia della Struttura residenziale nell’aiutare il Suo familiare più stretto a conoscere e capire meglio i problemi che Lei ha",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_12",
        label:
          "12)  La conoscenza da parte degli infermieri dei Suoi disturbi attuali e passati.",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_13",
        label:
          "13) Le informazioni da Lei ricevute sulla diagnosi e sulla possibile evoluzione dei Suoi disturbi.",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_14",
        label:
          "14) L’efficacia della Struttura nell’aiutarLa a migliorare i rapporti con le persone al di fuori del Suo ambiente familiare (ad esempio, con gli amici, i vicini di casa, i colleghi di lavoro).",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_15",
        label:
          "15) La chiarezza e la concretezza delle indicazioni ricevute su cosa fare fra una visita e l’altra.",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_16",
        label:
          "16) L’efficacia della struttura residenziale nell’aiutarLa a migliorare la cura di sé (ad esempio, l’igiene personale, l’alimentazione, la stanza dove vive, ecc.).",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_17",
        label:
          "17) La capacità degli infermieri e degli assistenti sociali di ascoltare e comprendere i Suoi problemi",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_18",
        label:
          "18) L’aiuto ricevuto per gli effetti collaterali o indesiderati dei farmaci (se ce ne sono stati).",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_19",
        label: "19) Nell’ultimo anno, Le sono stati prescritti farmaci? ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "VSSS_19a",
        label:
          "Qual è la Sua impressione complessiva sui farmaci che Le sono stati prescritti?",
        if: [
          {
            key: "VSSS_19",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_19b",
        label: "Avrebbe desiderato che Le fossero prescritti dei farmaci?",
        if: [
          {
            key: "VSSS_19",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 6,
            label: "6= No"
          },
          {
            value: 7,
            label: "7= Non lo so"
          },
          {
            value: 8,
            label: "8= Sì"
          }
        ]
      },
      {
        key: "VSSS_20",
        label:
          "20) Nell’ultimo anno, è stato aiutato dagli operatori della Struttura a migliorare capacità utili per la Sua vita sociale e lavorativa (come, ad esempio, riuscire ad andare negli uffici pubblici, riuscire a sbrigare le faccende di casa, sentirsi a proprio agio con i familiari e con gli altri)?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "VSSS_20a",
        label:
          "Qual è la Sua impressione complessiva sull’aiuto ricevuto per imparare a fare queste cose?",
        if: [
          {
            key: "VSSS_20",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_20b",
        label: "Avrebbe desiderato ricevere questo tipo d’aiuto?",
        if: [
          {
            key: "VSSS_20",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 6,
            label: "6= No"
          },
          {
            value: 7,
            label: "7= Non lo so"
          },
          {
            value: 8,
            label: "8= Sì"
          }
        ]
      },
      {
        key: "VSSS_21",
        label:
          "21) Nell’ultimo anno, ha avuto colloqui individuali con il Suo terapeuta (con l’obiettivo di migliorare la comprensione che Lei ha dei Suoi problemi e/o cambiare in qualche modo il Suo\ncomportamento)?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "VSSS_21a",
        label:
          "Qual è la Sua impressione complessiva sui colloqui individuali che Lei ha avuto?",
        if: [
          {
            key: "VSSS_21",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_21b",
        label: "Avrebbe desiderato avere colloqui individuali?",
        if: [
          {
            key: "VSSS_21",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 6,
            label: "6= No"
          },
          {
            value: 7,
            label: "7= Non lo so"
          },
          {
            value: 8,
            label: "8= Sì"
          }
        ]
      },
      {
        key: "VSSS_22",
        label:
          " 22) Nell’ultimo anno, è stato ricoverato obbligatoriamente nel reparto psichiatrico (cioè contro la Sua volontà)?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "VSSS_22a",
        label:
          "Qual è la Sua impressione sui ricoveri obbligatori che ha ricevuto?",
        if: [
          {
            key: "VSSS_22",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_22b",
        label:
          "Avrebbe desiderato essere ricoverato obbligatoriamente nel reparto psichiatrico?",
        if: [
          {
            key: "VSSS_22",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 6,
            label: "6= No"
          },
          {
            value: 7,
            label: "7= Non lo so"
          },
          {
            value: 8,
            label: "8= Sì"
          }
        ]
      },
      {
        key: "VSSS_23",
        label:
          "23) Nell’ultimo anno, ha avuto colloqui con il terapeuta insieme ai suoi familiari (con l’obiettivo di ottenere un miglioramento e/o un cambiamento dei rapporti nella famiglia)?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "VSSS_23a",
        label:
          "Qual è la Sua impressione complessiva sui colloqui di questo tipo che Lei ha avuto?",
        if: [
          {
            key: "VSSS_23",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_23b",
        label: "Avrebbe desiderato effettuare colloqui di questo tipo?",
        if: [
          {
            key: "VSSS_23",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 6,
            label: "6= No"
          },
          {
            value: 7,
            label: "7= Non lo so"
          },
          {
            value: 8,
            label: "8= Sì"
          }
        ]
      },
      {
        key: "VSSS_24",
        label:
          " 24) Nell’ultimo anno, è stato ospitato in case-alloggio con personale di assistenza?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "VSSS_24a",
        label:
          "Qual è la sua impressione complessiva sull’ospitalità ricevuta nelle case alloggio?",
        if: [
          {
            key: "VSSS_24",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_24b",
        label: "Avrebbe desiderato essere ospitato in case alloggio?",
        if: [
          {
            key: "VSSS_24",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 6,
            label: "6= No"
          },
          {
            value: 7,
            label: "7= Non lo so"
          },
          {
            value: 8,
            label: "8= Sì"
          }
        ]
      },
      {
        key: "VSSS_25",
        label:
          "25) Nell’ultimo anno, ha partecipato ad attività ricreative organizzate dalla Struttura residenziale?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "VSSS_25a",
        label:
          "Qual è la Sua impressione complessiva sulle attività ricreative cui ha partecipato?",
        if: [
          {
            key: "VSSS_25",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_25b",
        label:
          "Avrebbe desiderato partecipare ad attività ricreative organizzate dalla Struttura residenziale?",
        if: [
          {
            key: "VSSS_25",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 6,
            label: "6= No"
          },
          {
            value: 7,
            label: "7= Non lo so"
          },
          {
            value: 8,
            label: "8= Sì"
          }
        ]
      },
      {
        key: "VSSS_26",
        label:
          " 26) Nell’ultimo anno, ha effettuato una psicoterapia di gruppo (cioè incontri di un gruppo di pazienti con uno o più terapeuti con l’obiettivo di migliorare la comprensione che i pazienti hanno dei propri problemi e/o di ottenere qualche cambiamento del loro comportamento)?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "VSSS_26a",
        label:
          "Qual è la Sua impressione complessiva sulla psicoterapia di gruppo che Lei ha effettuato?",
        if: [
          {
            key: "VSSS_26",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_26b",
        label: "Avrebbe desiderato effettuare una psicoterapia di gruppo?",
        if: [
          {
            key: "VSSS_26",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 6,
            label: "6= No"
          },
          {
            value: 7,
            label: "7= Non lo so"
          },
          {
            value: 8,
            label: "8= Sì"
          }
        ]
      },
      {
        key: "VSSS_27",
        label:
          "27) Nell’ultimo anno, è stato inserito in un lavoro protetto (cioè in un ambiente di lavoro tollerante verso i Suoi problemi, così da rendere più facile il Suo inserimento)?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "VSSS_27a",
        label:
          "Qual è la Sua impressione complessiva su questo lavoro protetto?",
        if: [
          {
            key: "VSSS_27",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_27b",
        label: "Avrebbe desiderato essere inserito in un lavoro protetto?",
        if: [
          {
            key: "VSSS_27",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 6,
            label: "6= No"
          },
          {
            value: 7,
            label: "7= Non lo so"
          },
          {
            value: 8,
            label: "8= Sì"
          }
        ]
      },
      {
        key: "VSSS_28",
        label:
          "28) Nell’ultimo anno, è stato ricoverato volontariamente nel reparto psichiatrico?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "VSSS_28a",
        label:
          "Qual è la Sua impressione sui ricoveri volontari che ha ricevuto?",
        if: [
          {
            key: "VSSS_28",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_28b",
        label:
          "Avrebbe desiderato essere ricoverato volontariamente nel reparto psichiatrico?",
        if: [
          {
            key: "VSSS_28",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 6,
            label: "6= No"
          },
          {
            value: 7,
            label: "7= Non lo so"
          },
          {
            value: 8,
            label: "8= Sì"
          }
        ]
      },
      {
        key: "VSSS_29",
        label:
          "29) Nell’ultimo anno, ha ricevuto aiuto domiciliare da parte della Struttura (cioè, compagnia, aiuto nelle attività domestiche, ecc.)?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "VSSS_29a",
        label:
          "Qual è la Sua impressione complessiva sull’aiuto domiciliare ricevuto?",
        if: [
          {
            key: "VSSS_29",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_29b",
        label: "Avrebbe desiderato ricevere aiuto domiciliare?",
        if: [
          {
            key: "VSSS_29",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 6,
            label: "6= No"
          },
          {
            value: 7,
            label: "7= Non lo so"
          },
          {
            value: 8,
            label: "8= Sì"
          }
        ]
      },
      {
        key: "VSSS_30",
        label:
          "30) Nell’ultimo anno, ha ricevuto aiuto dagli operatori della Struttura per ottenere un sussidio economico o una pensione?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "VSSS_30a",
        label:
          "Qual è la Sua impressione complessiva sull’aiuto ricevuto per ottenere un sussidio economico o una pensione?",
        if: [
          {
            key: "VSSS_30",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_30b",
        label:
          "Avrebbe desiderato ricevere aiuto dalla Struttura per ottenere un sussidio economico o una pensione?",
        if: [
          {
            key: "VSSS_30",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 6,
            label: "6= No"
          },
          {
            value: 7,
            label: "7= Non lo so"
          },
          {
            value: 8,
            label: "8= Sì"
          }
        ]
      },
      {
        key: "VSSS_31",
        label:
          "31) Nell’ultimo anno, è stato aiutato dagli operatori della Struttura residenziale a trovare lavoro (un lavoro senza facilitazioni o tolleranza verso i Suoi problemi)?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "VSSS_31a",
        label:
          "Qual è la Sua impressione complessiva sull’aiuto ricevuto per trovare un lavoro?",
        if: [
          {
            key: "VSSS_31",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_31b",
        label:
          "Avrebbe desiderato essere aiutato dalla Struttura residenziale a trovare lavoro?",
        if: [
          {
            key: "VSSS_31",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 6,
            label: "6= No"
          },
          {
            value: 7,
            label: "7= Non lo so"
          },
          {
            value: 8,
            label: "8= Sì"
          }
        ]
      },
      {
        key: "VSSS_32",
        label:
          "32) Nell’ultimo anno, è stato aiutato dagli operatori della Struttura ad inserirsi in attività ricreative esterne alla Struttura (ad esempio, frequentare circoli sportivi, circoli culturali, ecc.)",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Sì"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "VSSS_32a",
        label:
          "Qual è la Sua impressione complessiva sull’aiuto ricevuto per inserirsi in attività ricreative esterne alla Struttura residenziale?",
        if: [
          {
            key: "VSSS_32",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "1= Pessima"
          },
          {
            value: 2,
            label: "2= Per lo più insoddisfacente"
          },
          {
            value: 3,
            label: "3= Mista"
          },
          {
            value: 4,
            label: "4= Per lo più soddisfacente"
          },
          {
            value: 5,
            label: "5= Ottima"
          }
        ]
      },
      {
        key: "VSSS_32b",
        label:
          "Avrebbe desiderato essere aiutato ad inserirsi in attività ricreative esterne alla Struttura residenziale?",
        if: [
          {
            key: "VSSS_32",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button-su-riga",
        values: [
          {
            value: 6,
            label: "6= No"
          },
          {
            value: 7,
            label: "7= Non lo so"
          },
          {
            value: 8,
            label: "8= Sì"
          }
        ]
      }
    ]
  },
  {
    type: "WAS-P",
    name: "WARD ATMOSPHERE SCALE - Versione Paziente (WAS-P) ",
    codiceDataCompletamento: "DATA_16",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      }
    ],
    domande: [
      {
        key: "DATA_16",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "WAS-P_0",
        label:
          "In questo questionario lei troverà 100 brevi affermazioni che si riferiscono al reparto. Lei deve decidere quali affermazioni sono vere per il suo reparto e quali no.\nMetta una croce in corrisponza di VERO quando pensa che l’affermazione è vera o prevalentemente vera per il suo reparto; metta invece una croce in corrispondenza di FALSO quando pensa che l’affermazione è falsa o prevalentemente falsa.",
        if: null,
        type: "description"
      },
      {
        key: "WAS-P_1",
        label:
          "1) In questo posto i pazienti mettono energia ed entusiasmo in quello che fanno",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_2",
        label: "2) I medici hanno pochissimo tempo per incoraggiare i pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_3",
        label:
          "3) I pazienti tendono a nascondersi l’un l’altro i loro sentimenti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_4",
        label: "4) Il personale agisce in base ai suggerimenti dei pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_5",
        label:
          "5) Nuovi approcci di trattamento vengono provati spesso in questo reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_6",
        label: "6) I pazienti non discutono quasi mai della loro vita sessuale",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_7",
        label: "7) I pazienti brontolano spesso",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_8",
        label: "8) Le attività dei pazienti sono organizzate con cura",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_9",
        label: "9) I pazienti sanno quando i medici saranno in reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_10",
        label:
          "10) È molto raro che il personale punisca i pazienti limitando la loro libertà",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_11",
        label: "11) Questo è un reparto vivace",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_12",
        label: "12)  Il personale conosce i bisogni dei pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_13",
        label: "13) I pazienti dicono ai medici tutto quello che vogliono",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_14",
        label:
          "14) Pochissimi pazienti hanno una qualche responsabilità nel reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_15",
        label:
          "15) Qui si dà pochissima importanza a sviluppare il senso pratico dei pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_16",
        label:
          "16) I pazienti si raccontano l’un l’altro i loro problemi personali",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_17",
        label:
          "17) I pazienti spesso criticano il personale del reparto o ci scherzano su",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_18",
        label: "18) Questo è un reparto molto ben organizzato",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_19",
        label: "19) I medici non spiegano ai pazienti a cosa serve la cura",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_20",
        label:
          "20) I pazienti possono interrompere un medico quando sta parlando",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_21",
        label: "21) I pazienti sono orgogliosi di questo reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_22",
        label:
          "22) Il personale è interessato a seguire i pazienti dopo la dimissione",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_23",
        label:
          "23) È difficile dire come si sentono i pazienti in questo reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_24",
        label:
          "24) Dai pazienti ci si aspetta che prendano in mano l’organizzazione del reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_25",
        label:
          "25) I pazienti vengono incoraggiati a fare progetti per il futuro",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_26",
        label: "26) Si parla apertamente di problemi personali",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_27",
        label: "27) I pazienti di questo reparto litigano raramente",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_28",
        label: "28) Il personale fa in modo che il reparto sia sempre pulito",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_29",
        label:
          "29) Se ad un paziente viene cambiata la medicina, un infermiere o un medico gli\ncomunicano sempre il perché",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_30",
        label:
          "30) I pazienti che infrangono le regole del reparto sono puniti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_31",
        label: "31) In questo reparto c’è pochissimo spirito di gruppo",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_32",
        label:
          "32) Gli infermieri hanno pochissimo tempo per incoraggiare i pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_33",
        label:
          "33) I pazienti stanno attenti a quello che dicono quando il personale è nelle vicinanze",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_34",
        label: "34) I pazienti qui vengono incoraggiati ad essere indipendenti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_35",
        label:
          "35) Si dà pochissima importanza a ciò che i pazienti faranno dopo la dimissione",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_36",
        label:
          "36) Ci si aspetta che i pazienti si confidino l’un l’altro i loro problemi personali",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_37",
        label: "37) I membri del personale talora litigano fra di loro",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_38",
        label: "38) Il reparto è talora molto in disordine",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_39",
        label:
          "39) Le regole del reparto sono comprese chiaramente dai pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_40",
        label:
          "40) Se un paziente litiga con un altro paziente, finirà nei guai con il personale",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_41",
        label:
          "41) In questo posto nessuno offre mai volontariamente la sua disponibilità",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_42",
        label:
          "42) I medici passano più tempo con alcuni pazienti che con altri",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_43",
        label:
          "43) I pazienti organizzano le proprie attività senza essere stimolati dal personale",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_44",
        label:
          "44) I pazienti possono lasciare il reparto in qualsiasi momento lo vogliano",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_45",
        label:
          "45) Si dà pochissima importanza al fare progetti per la dimissione",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_46",
        label: "46) I pazienti parlano pochissimo del loro passato",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_47",
        label:
          "47) I pazienti talora si fanno l’un l’altro scherzi di cattivo gusto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_48",
        label:
          " 48) La maggior parte dei pazienti segue ogni giorno un programma regolato da orari precisi",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_49",
        label:
          "49) I pazienti non sanno mai quando il medico verrà a visitarli",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_50",
        label: "50) Il personale non dà troppi ordini ai pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_51",
        label: "51) I pazienti sono sempre molto occupati",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_52",
        label:
          "52) In questo reparto i pazienti più sani aiutano ad assistere i meno sani",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_53",
        label:
          "53) Quando i pazienti sono in disaccordo l’uno con l’altro, non lo fanno vedere",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_54",
        label: "54)I pazienti possono vestirsi come vogliono",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_55",
        label:
          "55) Questo reparto dà importanza a ciò che serve per imparare un nuovo mestiere",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_56",
        label:
          "56) Il personale raramente fa domande di carattere privato ai pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_57",
        label: "57) In questo posto è difficile far litigare la gente",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_58",
        label: "58) Molti pazienti hanno un aspetto disordinato",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_59",
        label: "59) In questo reparto tutti sanno chi comanda",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_60",
        label:
          "60) Una volta stabilito un programma per il paziente, ii paziente deve seguirlo",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_61",
        label: "61) Il reparto ha pochissime attività sociali",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_62",
        label: "62) I pazienti si aiutano raramente l’un l’altro",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_63",
        label: "63) In questo posto è permesso comportarsi da matto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_64",
        label: "64) In questo reparto i pazienti non comandano per nulla",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_65",
        label:
          "65) La maggior parte dei pazienti si occupa di più del passato che del futuro",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_66",
        label:
          "66) Il personale è soprattutto interessato a conoscere i sentimenti dei pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_67",
        label:
          "67) Il personale non provoca mai litigi nelle riunioni di gruppo",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_68",
        label: "68) In questo posto talora c’è molta disorganizzazione",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_69",
        label:
          "69) Se un paziente infrange una regola, sa che cosa gli succederà",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_70",
        label:
          "70) I pazienti possono dare del tu al personale infermieristico",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_71",
        label:
          "71) In questo posto pochissime cose mettono la gente in eccitazione",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_72",
        label:
          "72) Il personale aiuta i nuovi pazienti ad ambientarsi in reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_73",
        label:
          "73) I pazienti tendono a nascondere i loro sentimenti al personale",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_74",
        label:
          "74) I pazienti possono lasciare il reparto senza dire dove stanno andando",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_75",
        label:
          "75) I pazienti sono incoraggiati a imparare modi nuovi di fare le cose",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_76",
        label: "76) I pazienti parlano raramente dei loro problemi personali",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_77",
        label:
          "77) In questo reparto il personale pensa.che litigare fa bene alla salute",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_78",
        label: "78) Il personale dà l’esempio quanto a pulizia e ordine",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_79",
        label: "79) Qui la gente continua a cambiare idea",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_80",
        label:
          "80) I pazienti saranno dimessi da questo reparto se non obbediscono alle regole",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_81",
        label: "81) Le discussioni sono molto interessanti in questo reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_82",
        label:
          "82) I medici talora non si presentano agli appuntamenti presi con i pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_83",
        label:
          "83) I pazienti vengono incoraggiati a mostrare i loro sentimenti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_84",
        label: "84) Il personale cede raramente alle insistenze dei pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_85",
        label:
          "85) Il personale si occupa di più dei sentimenti che dei problemi pratici dei pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_86",
        label:
          "86) Il personale incoraggia fortemente i pazienti a parlare del loro passato",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_87",
        label: "87) I pazienti qui si arrabbiano raramente",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_88",
        label:
          "88) Raramente i pazienti sono costretti ad aspettare quando hanno un appuntamento con il personale",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_89",
        label:
          "89) I pazienti non sanno mai quando saranno trasferiti o dimessi da questo reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_90",
        label:
          "90) Non è prudente per i pazienti discutere i loro problemi personali in questo posto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_91",
        label:
          "91) I pazienti spesso si organizzano per passare insieme il fine settimana",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_92",
        label:
          "92) Il personale si impegna oltre il dovuto per aiutare i pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_93",
        label: "93) Il reparto rimane sempre più o meno lo stesso",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_94",
        label: "94) Il personale scoraggia ogni tendenza a fare critiche",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_95",
        label:
          "95) I pazienti devono avere dei progetti prima di lasciare l’ospedale",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_96",
        label:
          "96) È difficile mettere insieme un gruppo per giocare a carte o per altre attività",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_97",
        label:
          "97) Molti pazienti in reparto hanno l’aria di stare solo a passare il tempo",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_98",
        label: "98) Il soggiorno è spesso in disordine",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_99",
        label: "99) Il personale dice ai pazienti quando stanno migliorando",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-P_100",
        label:
          "100) È una buona idea lasciar capire al medico che è lui che comanda",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      }
    ]
  },
  {
    type: "WAI-T_1",
    name: "WORKING ALLIANCE INVENTORY - Versione Clinico (WAI-T) Paziente 1",
    codiceDataCompletamento: "DATA_17",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Operatore"
      }
    ],
    domande: [
      {
        key: "DATA_17",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "WAI-T1_0a",
        label: "Indicare il codice del paziente a cui ci si riferisce",
        if: null,
        type: "free-text"
      },
      {
        key: "WAI-T1_0",
        label:
          "Le proponiamo una serie di quesiti che provano a definire alcuni aspetti del rapporto con questo paziente. Mentre legge i quesiti inserisca mentalmente il nome del suo paziente al posto dello spazio lasciato libero nel testo. Utilizzando la scala a 7 punti di seguito riportata, indichi, cerchiando il numero, il grado con cui ciascun item descrive ciò che lei pensa. Mi raccomando, risponda ad ogni quesito senza pensarci troppo: ciò che conta sono le sue prime impressioni. Si ricordi inoltre di rispondere a tutti i quesiti. Grazie per la collaborazione",
        if: null,
        type: "description"
      },
      {
        key: "WAI-T1_1",
        label:
          "1) ...................................... e io siamo d’accordo circa i passaggi che vanno affrontati per migliorare la sua situazione.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T1_2",
        label:
          "2) ........................................... e io ci sentiamo fiduciosi sulla utilità della nostra attività terapeutica.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T1_3",
        label:
          "3) Credo di piacere a .....................................................",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T1_4",
        label:
          "4) Ho dei dubbi su ciò che stiamo cercando di realizzare con la terapia.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T1_5",
        label:
          "5) Confido nella mia capacità di aiutare .....................................",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T1_6",
        label:
          "6) .................................. e io siamo impegnati in uno sforzo comune per raggiungere obiettivi concordati",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T1_7",
        label:
          "7) Stimo ....................................................... come persona.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T1_8",
        label:
          "8) Siamo d’accordo sulle cose su cui ................................ deve lavorare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T1_9",
        label:
          "9) .............................................. e io abbiamo costruito un rapporto di fiducia reciproca.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T1_10",
        label:
          "10) ............................................ e io abbiamo idee differenti su quali sono i suoi reali problemi.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T1_11",
        label:
          "11) Abbiamo stabilito un buon livello di comprensione reciproca sul tipo di cambiamenti che sarebbero giusti per .....................................",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T1_12",
        label:
          "12) Credo che la strada intrapresa per risolvere i suoi problemi sia quella giusta.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      }
    ]
  },
  {
    type: "WAI-T_2",
    name: "WORKING ALLIANCE INVENTORY - Versione Clinico (WAI-T) Paziente 2",
    codiceDataCompletamento: "DATA_18",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Operatore"
      }
    ],
    domande: [
      {
        key: "DATA_18",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "WAI-T2_0a",
        label: "Indicare il codice del paziente a cui ci si riferisce",
        if: null,
        type: "free-text"
      },
      {
        key: "WAI-T2_0",
        label:
          "Le proponiamo una serie di quesiti che provano a definire alcuni aspetti del rapporto con questo paziente. Mentre legge i quesiti inserisca mentalmente il nome del suo paziente al posto dello spazio lasciato libero nel testo. Utilizzando la scala a 7 punti di seguito riportata, indichi, cerchiando il numero, il grado con cui ciascun item descrive ciò che lei pensa. Mi raccomando, risponda ad ogni quesito senza pensarci troppo: ciò che conta sono le sue prime impressioni. Si ricordi inoltre di rispondere a tutti i quesiti. Grazie per la collaborazione",
        if: null,
        type: "description"
      },
      {
        key: "WAI-T2_1",
        label:
          "1) ...................................... e io siamo d’accordo circa i passaggi che vanno affrontati per migliorare la sua situazione.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T2_2",
        label:
          "2) ........................................... e io ci sentiamo fiduciosi sulla utilità della nostra attività terapeutica.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T2_3",
        label:
          "3) Credo di piacere a .....................................................",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T2_4",
        label:
          "4) Ho dei dubbi su ciò che stiamo cercando di realizzare con la terapia.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T2_5",
        label:
          "5) Confido nella mia capacità di aiutare .....................................",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T2_6",
        label:
          "6) .................................. e io siamo impegnati in uno sforzo comune per raggiungere obiettivi concordati",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T2_7",
        label:
          "7) Stimo ....................................................... come persona.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T2_8",
        label:
          "8) Siamo d’accordo sulle cose su cui ................................ deve lavorare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T2_9",
        label:
          "9) .............................................. e io abbiamo costruito un rapporto di fiducia reciproca.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T2_10",
        label:
          "10) ............................................ e io abbiamo idee differenti su quali sono i suoi reali problemi.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T2_11",
        label:
          "11) Abbiamo stabilito un buon livello di comprensione reciproca sul tipo di cambiamenti che sarebbero giusti per .....................................",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T2_12",
        label:
          "12) Credo che la strada intrapresa per risolvere i suoi problemi sia quella giusta.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      }
    ]
  },
  {
    type: "WAI-T_3",
    name: "WORKING ALLIANCE INVENTORY - Versione Clinico (WAI-T) Paziente 3",
    codiceDataCompletamento: "DATA_19",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Operatore"
      }
    ],
    domande: [
      {
        key: "DATA_19",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "WAI-T3_0a",
        label: "Indicare il codice del paziente a cui ci si riferisce",
        if: null,
        type: "free-text"
      },
      {
        key: "WAI-T3_0",
        label:
          "Le proponiamo una serie di quesiti che provano a definire alcuni aspetti del rapporto con questo paziente. Mentre legge i quesiti inserisca mentalmente il nome del suo paziente al posto dello spazio lasciato libero nel testo. Utilizzando la scala a 7 punti di seguito riportata, indichi, cerchiando il numero, il grado con cui ciascun item descrive ciò che lei pensa. Mi raccomando, risponda ad ogni quesito senza pensarci troppo: ciò che conta sono le sue prime impressioni. Si ricordi inoltre di rispondere a tutti i quesiti.",
        if: null,
        type: "description"
      },
      {
        key: "WAI-T3_1",
        label:
          "1) ...................................... e io siamo d’accordo circa i passaggi che vanno affrontati per migliorare la sua situazione.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T3_2",
        label:
          "2) ........................................... e io ci sentiamo fiduciosi sulla utilità della nostra attività terapeutica.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T3_3",
        label:
          "3) Credo di piacere a .....................................................",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T3_4",
        label:
          "4) Ho dei dubbi su ciò che stiamo cercando di realizzare con la terapia.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T3_5",
        label:
          "5) Confido nella mia capacità di aiutare .....................................",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T3_6",
        label:
          "6) .................................. e io siamo impegnati in uno sforzo comune per raggiungere obiettivi concordati",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T3_7",
        label:
          "7) Stimo ....................................................... come persona.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T3_8",
        label:
          "8) Siamo d’accordo sulle cose su cui ................................ deve lavorare.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T3_9",
        label:
          "9) .............................................. e io abbiamo costruito un rapporto di fiducia reciproca.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T3_10",
        label:
          "10) ............................................ e io abbiamo idee differenti su quali sono i suoi reali problemi.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T3_11",
        label:
          " 11) Abbiamo stabilito un buon livello di comprensione reciproca sul tipo di cambiamenti che sarebbero giusti per .....................................",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      },
      {
        key: "WAI-T3_12",
        label:
          "12) Credo che la strada intrapresa per risolvere i suoi problemi sia quella giusta.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1= MAI"
          },
          {
            value: 2,
            label: "2= RARAMENTE"
          },
          {
            value: 3,
            label: "3= OGNI TANTO"
          },
          {
            value: 4,
            label: "4= QUALCHE VOLTA"
          },
          {
            value: 5,
            label: "5= SPESSO"
          },
          {
            value: 6,
            label: "6= MOLTO SPESSO"
          },
          {
            value: 7,
            label: "7= SEMPRE"
          }
        ]
      }
    ]
  },
  {
    type: "WAS-S",
    name: "WARD ATMOSPHERE SCALE - Versione Clinico (WAS-T) ",
    codiceDataCompletamento: "DATA_20",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Operatore"
      }
    ],
    domande: [
      {
        key: "DATA_20",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "WAS-S_0",
        label:
          "In questo questionario lei troverà 100 brevi affermazioni che si riferiscono al reparto. Lei deve decidere quali affermazioni sono vere per il suo reparto e quali no.\nMetta una croce in corrisponza di VERO quando pensa che l’affermazione è vera o prevalentemente vera per il suo reparto; metta invece una croce in corrispondenza di\nFALSO quando pensa che l’affermazione è falsa o prevalentemente falsa.",
        if: null,
        type: "description"
      },
      {
        key: "WAS-S_1",
        label:
          "1) In questo posto i pazienti mettono energia ed entusiasmo in quello che fanno",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_2",
        label: "2) I medici hanno pochissimo tempo per incoraggiare i pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_3",
        label:
          "3) I pazienti tendono a nascondersi l’un l’altro i loro sentimenti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_4",
        label: "4) Il personale agisce in base ai suggerimenti dei pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_5",
        label:
          "5) Nuovi approcci di trattamento vengono provati spesso in questo reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_6",
        label: "6) I pazienti non discutono quasi mai della loro vita sessuale",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_7",
        label: "7) I pazienti brontolano spesso",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_8",
        label: "8) Le attività dei pazienti sono organizzate con cura",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_9",
        label: "9) I pazienti sanno quando i medici saranno in reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_10",
        label:
          "10) È molto raro che il personale punisca i pazienti limitando la loro libertà",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_11",
        label: "11) Questo è un reparto vivace",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_12",
        label: "12)  Il personale conosce i bisogni dei pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_13",
        label: "13) I pazienti dicono ai medici tutto quello che vogliono",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_14",
        label:
          "14) Pochissimi pazienti hanno una qualche responsabilità nel reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_15",
        label:
          "15) Qui si dà pochissima importanza a sviluppare il senso pratico dei pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_16",
        label:
          "16) I pazienti si raccontano l’un l’altro i loro problemi personali",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_17",
        label:
          "17) I pazienti spesso criticano il personale del reparto o ci scherzano su",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_18",
        label: "18) Questo è un reparto molto ben organizzato",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_19",
        label: "19) I medici non spiegano ai pazienti a cosa serve la cura",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_20",
        label:
          "20) I pazienti possono interrompere un medico quando sta parlando",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_21",
        label: "21) I pazienti sono orgogliosi di questo reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_22",
        label:
          "22) Il personale è interessato a seguire i pazienti dopo la dimissione",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_23",
        label:
          "23) È difficile dire come si sentono i pazienti in questo reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_24",
        label:
          "24) Dai pazienti ci si aspetta che prendano in mano l’organizzazione del reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_25",
        label:
          "25) I pazienti vengono incoraggiati a fare progetti per il futuro",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_26",
        label: "26) Si parla apertamente di problemi personali",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_27",
        label: "27) I pazienti di questo reparto litigano raramente",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_28",
        label: "28) Il personale fa in modo che il reparto sia sempre pulito",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_29",
        label:
          "29) Se ad un paziente viene cambiata la medicina, un infermiere o un medico gli\ncomunicano sempre il perché",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_30",
        label:
          "30) I pazienti che infrangono le regole del reparto sono puniti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_31",
        label: "31) In questo reparto c’è pochissimo spirito di gruppo",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_32",
        label:
          "32) Gli infermieri hanno pochissimo tempo per incoraggiare i pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_33",
        label:
          "33) I pazienti stanno attenti a quello che dicono quando il personale è nelle vicinanze",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_34",
        label: "34) I pazienti qui vengono incoraggiati ad essere indipendenti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_35",
        label:
          "35) Si dà pochissima importanza a ciò che i pazienti faranno dopo la dimissione",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_36",
        label:
          "36) Ci si aspetta che i pazienti si confidino l’un l’altro i loro problemi personali",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_37",
        label: "37) I membri del personale talora litigano fra di loro",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_38",
        label: "38) Il reparto è talora molto in disordine",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_39",
        label:
          "39) Le regole del reparto sono comprese chiaramente dai pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_40",
        label:
          "40) Se un paziente litiga con un altro paziente, finirà nei guai con il personale",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_41",
        label:
          "41) In questo posto nessuno offre mai volontariamente la sua disponibilità",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_42",
        label:
          "42) I medici passano più tempo con alcuni pazienti che con altri",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_43",
        label:
          "43) I pazienti organizzano le proprie attività senza essere stimolati dal personale",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_44",
        label:
          "44) I pazienti possono lasciare il reparto in qualsiasi momento lo vogliano",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_45",
        label:
          "45) Si dà pochissima importanza al fare progetti per la dimissione",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_46",
        label: "46) I pazienti parlano pochissimo del loro passato",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_47",
        label:
          "47) I pazienti talora si fanno l’un l’altro scherzi di cattivo gusto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_48",
        label:
          " 48) La maggior parte dei pazienti segue ogni giorno un programma regolato da orari precisi",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_49",
        label:
          "49) I pazienti non sanno mai quando il medico verrà a visitarli",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_50",
        label: "50) Il personale non dà troppi ordini ai pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_51",
        label: "51) I pazienti sono sempre molto occupati",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_52",
        label:
          "52) In questo reparto i pazienti più sani aiutano ad assistere i meno sani",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_53",
        label:
          "53) Quando i pazienti sono in disaccordo l’uno con l’altro, non lo fanno vedere",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_54",
        label: "54)I pazienti possono vestirsi come vogliono",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_55",
        label:
          "55) Questo reparto dà importanza a ciò che serve per imparare un nuovo mestiere",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_56",
        label:
          "56) Il personale raramente fa domande di carattere privato ai pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_57",
        label: "57) In questo posto è difficile far litigare la gente",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_58",
        label: "58) Molti pazienti hanno un aspetto disordinato",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_59",
        label: "59) In questo reparto tutti sanno chi comanda",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_60",
        label:
          "60) Una volta stabilito un programma per il paziente, ii paziente deve seguirlo",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_61",
        label: "61) Il reparto ha pochissime attività sociali",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_62",
        label: "62) I pazienti si aiutano raramente l’un l’altro",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_63",
        label: "63) In questo posto è permesso comportarsi da matto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_64",
        label: "64) In questo reparto i pazienti non comandano per nulla",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_65",
        label:
          "65) La maggior parte dei pazienti si occupa di più del passato che del futuro",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_66",
        label:
          "66) Il personale è soprattutto interessato a conoscere i sentimenti dei pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_67",
        label:
          "67) Il personale non provoca mai litigi nelle riunioni di gruppo",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_68",
        label: "68) In questo posto talora c’è molta disorganizzazione",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_69",
        label:
          "69) Se un paziente infrange una regola, sa che cosa gli succederà",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_70",
        label:
          "70) I pazienti possono dare del tu al personale infermieristico",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_71",
        label:
          "71) In questo posto pochissime cose mettono la gente in eccitazione",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_72",
        label:
          "72) Il personale aiuta i nuovi pazienti ad ambientarsi in reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_73",
        label:
          "73) I pazienti tendono a nascondere i loro sentimenti al personale",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_74",
        label:
          "74) I pazienti possono lasciare il reparto senza dire dove stanno andando",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_75",
        label:
          "75) I pazienti sono incoraggiati a imparare modi nuovi di fare le cose",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_76",
        label: "76) I pazienti parlano raramente dei loro problemi personali",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_77",
        label:
          "77) In questo reparto il personale pensa.che litigare fa bene alla salute",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_78",
        label: "78) Il personale dà l’esempio quanto a pulizia e ordine",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_79",
        label: "79) Qui la gente continua a cambiare idea",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_80",
        label:
          "80) I pazienti saranno dimessi da questo reparto se non obbediscono alle regole",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_81",
        label: "81) Le discussioni sono molto interessanti in questo reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_82",
        label:
          "82) I medici talora non si presentano agli appuntamenti presi con i pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_83",
        label:
          "83) I pazienti vengono incoraggiati a mostrare i loro sentimenti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_84",
        label: "84) Il personale cede raramente alle insistenze dei pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_85",
        label:
          "85) Il personale si occupa di più dei sentimenti che dei problemi pratici dei pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_86",
        label:
          "86) Il personale incoraggia fortemente i pazienti a parlare del loro passato",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_87",
        label: "87) I pazienti qui si arrabbiano raramente",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_88",
        label:
          "88) Raramente i pazienti sono costretti ad aspettare quando hanno un appuntamento con il personale",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_89",
        label:
          "89) I pazienti non sanno mai quando saranno trasferiti o dimessi da questo reparto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_90",
        label:
          "90) Non è prudente per i pazienti discutere i loro problemi personali in questo posto",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_91",
        label:
          "91) I pazienti spesso si organizzano per passare insieme il fine settimana",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_92",
        label:
          "92) Il personale si impegna oltre il dovuto per aiutare i pazienti",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_93",
        label: "93) Il reparto rimane sempre più o meno lo stesso",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_94",
        label: "94) Il personale scoraggia ogni tendenza a fare critiche",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_95",
        label:
          "95) I pazienti devono avere dei progetti prima di lasciare l’ospedale",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_96",
        label:
          "96) È difficile mettere insieme un gruppo per giocare a carte o per altre attività",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_97",
        label:
          "97) Molti pazienti in reparto hanno l’aria di stare solo a passare il tempo",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_98",
        label: "98) Il soggiorno è spesso in disordine",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_99",
        label: "99) Il personale dice ai pazienti quando stanno migliorando",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      },
      {
        key: "WAS-S_100",
        label:
          "100) È una buona idea lasciar capire al medico che è lui che comanda",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 1,
            label: "Vero"
          },
          {
            value: 2,
            label: "Falso"
          }
        ]
      }
    ]
  },
  {
    type: "MBI",
    name: "MASLACH BURNOUT INVENTORY (MBI) ",
    codiceDataCompletamento: "DATA_21",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Operatore"
      }
    ],
    domande: [
      {
        key: "DATA_21",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "MBI_0",
        label:
          "Per ciascuna di queste domane la preghiamo di segnare il numero corrispondente a come si sente.",
        if: null,
        type: "description"
      },
      {
        key: "MBI_1",
        label: "1) Mi sento emotivamente sfinito dal mio lavoro",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_2",
        label: "2) Mi sento sfinito alla fine della giornata",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_3",
        label:
          "3) Mi sento stanco quando mi alzo alla mattina e devo affrontare un’altra giornata di lavoro",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_4",
        label: "4) Posso capire facilmente come la pensano i miei pazienti",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_5",
        label:
          "5) Mi pare di trattare alcuni pazienti come se fossero degli oggetti",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_6",
        label: "6) Mi pare che lavorare tutto il giorno con la gente mi pesi",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_7",
        label: "7) Affronto efficacemente i problemi dei pazienti.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_8",
        label: "8) Mi sento esaurito dal mio lavoro.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_9",
        label:
          "9) Credo di influenzare positivamente la vita di altre persone attraverso il mio lavoro.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_10",
        label:
          "10) Da quando ho cominciato a lavorare qui sono diventato più insensibile con la gente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_11",
        label: "11) Ho paura che questo lavoro mi possa indurire emotivamente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_12",
        label: "12) Mi sento pieno di energie.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_13",
        label: "13) Sono frustrato dal mio lavoro.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_14",
        label: "14) Credo di lavorare troppo duramente.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_15",
        label:
          "15) Non mi importa veramente di ciò che succede ad alcuni pazienti.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_16",
        label:
          "16) Lavorare direttamente a contatto con la gente mi crea troppa tensione.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_17",
        label:
          "17) Riesco facilmente a rendere i pazienti rilassati e a proprio agio.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_18",
        label: "18) Mi sento rallegrato dopo aver lavorato con i pazienti.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_19",
        label: "19) Ho realizzato molte cose di valore nel mio lavoro.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_20",
        label: "20) Sento di non farcela più.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_21",
        label: "21) Nel mio lavoro affronto i problemi emotivi con calma.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      },
      {
        key: "MBI_22",
        label:
          "22) Ho l’impressione che i pazienti diano la colpa a me per i loro problemi.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= Mai"
          },
          {
            value: 1,
            label: "1= Qualche volta all'anno"
          },
          {
            value: 2,
            label: "2= Una volta al mese o meno"
          },
          {
            value: 3,
            label: "3= Qualche volta al mese"
          },
          {
            value: 4,
            label: "4= Una volta alla settimana"
          },
          {
            value: 5,
            label: "5= Qualche volta alla settimana"
          },
          {
            value: 6,
            label: "6= Ogni giorno"
          }
        ]
      }
    ]
  },
  {
    type: "GHQ-12",
    name: "GENERAL HEALTH QUESTIONNAIRE (GHQ12)",
    codiceDataCompletamento: "DATA_22",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Operatore"
      }
    ],
    domande: [
      {
        key: "DATA_22",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "GHQ-12_0",
        label:
          "Per ciascuna di queste domande la preghiamo di segnare il numero corrispondente a come si è sentito nelle ultime 2 settimane.",
        if: null,
        type: "description"
      },
      {
        key: "GHQ-12_1",
        label:
          "1) In grado di concentrarsi su ciò che stava facendo? (“Es. Riesce a seguire il filo del discorso, a concentrarsi nella lettura, sul lavoro etc.”)",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Più del solito"
          },
          {
            value: 1,
            label: "1= Come al solito"
          },
          {
            value: 2,
            label: "2= Meno del solito"
          },
          {
            value: 3,
            label: "3= Molto meno del solito"
          }
        ]
      },
      {
        key: "GHQ-12_2",
        label: "2) Di aver perso molto sonno tanto da preoccuparsi?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Molto meno del solito"
          },
          {
            value: 1,
            label: "1= Meno del solito"
          },
          {
            value: 2,
            label: "2= Come al solito"
          },
          {
            value: 3,
            label: "3=  Più del solito"
          }
        ]
      },
      {
        key: "GHQ-12_3",
        label:
          "3) Di essere produttivo (aver fatto tante cose) nella maggior parte delle attività?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Più del solito"
          },
          {
            value: 1,
            label: "1= Come al solito"
          },
          {
            value: 2,
            label: "2= Meno del solito"
          },
          {
            value: 3,
            label: "3= Molto meno del solito"
          }
        ]
      },
      {
        key: "GHQ-12_4",
        label:
          "4) In grado di prendere decisioni nella maggior parte dei casi?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Più del solito"
          },
          {
            value: 1,
            label: "1= Come al solito"
          },
          {
            value: 2,
            label: "2= Meno del solito"
          },
          {
            value: 3,
            label: "3= Molto meno del solito"
          }
        ]
      },
      {
        key: "GHQ-12_5",
        label: "5) Costantemente sotto pressione?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Molto meno del solito"
          },
          {
            value: 1,
            label: "1= Meno del solito"
          },
          {
            value: 2,
            label: "2= Come al solito"
          },
          {
            value: 3,
            label: "3=  Più del solito"
          }
        ]
      },
      {
        key: "GHQ-12_6",
        label: "6) Di non essere in grado di superare le difficoltà?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Molto meno del solito"
          },
          {
            value: 1,
            label: "1= Meno del solito"
          },
          {
            value: 2,
            label: "2= Come al solito"
          },
          {
            value: 3,
            label: "3=  Più del solito"
          }
        ]
      },
      {
        key: "GHQ-12_7",
        label: "7) In grado di ritagliarsi del tempo libero e goderne?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Più del solito"
          },
          {
            value: 1,
            label: "1= Come al solito"
          },
          {
            value: 2,
            label: "2= Meno del solito"
          },
          {
            value: 3,
            label: "3= Molto meno del solito"
          }
        ]
      },
      {
        key: "GHQ-12_8",
        label: "8) In grado di risolvere i suoi problemi?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Più del solito"
          },
          {
            value: 1,
            label: "1= Come al solito"
          },
          {
            value: 2,
            label: "2= Meno del solito"
          },
          {
            value: 3,
            label: "3= Molto meno del solito"
          }
        ]
      },
      {
        key: "GHQ-12_9",
        label: "9) Infelice o depresso?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Molto meno del solito"
          },
          {
            value: 1,
            label: "1= Meno del solito"
          },
          {
            value: 2,
            label: "2= Come al solito"
          },
          {
            value: 3,
            label: "3=  Più del solito"
          }
        ]
      },
      {
        key: "GHQ-12_10",
        label: "10) Come se avesse perso la fiducia in se stesso?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Molto meno del solito"
          },
          {
            value: 1,
            label: "1= Meno del solito"
          },
          {
            value: 2,
            label: "2= Come al solito"
          },
          {
            value: 3,
            label: "3=  Più del solito"
          }
        ]
      },
      {
        key: "GHQ-12_11",
        label: "11) Come se avesse minore stima di sé?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Più del solito"
          },
          {
            value: 1,
            label: "1= Come al solito"
          },
          {
            value: 2,
            label: "2= Meno del solito"
          },
          {
            value: 3,
            label: "3= Molto meno del solito"
          }
        ]
      },
      {
        key: "GHQ-12_12",
        label: "12) Con uno stato emotivo nel complesso felice?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "0= Più del solito"
          },
          {
            value: 1,
            label: "1= Come al solito"
          },
          {
            value: 2,
            label: "2= Meno del solito"
          },
          {
            value: 3,
            label: "3= Molto meno del solito"
          }
        ]
      }
    ]
  },
  {
    type: "TUS1",
    name: "DIARIO GIORNALIERO - Giorno 1",
    codiceDataCompletamento: "DATA_23",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Ambulatoriale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Controllo"
      }
    ],
    domande: [
      {
        key: "DATA_23",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "GIORNO_1",
        label: "Giorno della settimana",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Lunedì"
          },
          {
            value: 1,
            label: "Martedì"
          },
          {
            value: 2,
            label: "Mercoledì"
          },
          {
            value: 3,
            label: "Giovedì"
          },
          {
            value: 4,
            label: "Venerdì"
          },
          {
            value: 5,
            label: "Domenica"
          }
        ]
      },
      {
        type: "group-table",
        header: [
          {
            label: ""
          },
          {
            label: "0"
          },
          {
            label: "1"
          },
          {
            label: "2"
          },
          {
            label: "3"
          },
          {
            label: "4"
          },
          {
            label: "5"
          },
          {
            label: "6"
          },
          {
            label: "7"
          },
          {
            label: "8"
          },
          {
            label: "9"
          },
          {
            label: "10"
          },
          {
            label: "11"
          },
          {
            label: "12"
          },
          {
            label: "13"
          },
          {
            label: "14"
          },
          {
            label: "15"
          },
          {
            label: "16"
          },
          {
            label: "17"
          },
          {
            label: "18"
          },
          {
            label: "19"
          },
          {
            label: "20"
          },
          {
            label: "21"
          },
          {
            label: "22"
          },
          {
            label: "23"
          }
        ],
        rows: [
          {
            key: "TUS_INTRO1",
            label: "COSA STAI FACENDO?",
            if: null,
            type: "group-table-row-descrizione"
          },
          {
            key: "TUS1_1",
            label: "Dormire",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ],
            firstOfDiario: true
          },
          {
            key: "TUS1_2",
            label: "Stare a letto malato",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS1_3",
            label: "Mangiare/bere/fare colazione/merenda",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS1_4",
            label: "Cura di sé (lavarsi, vestirsi, ecc.)",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS1_5",
            label: "Lavorare o fare stage/tirocinio (o cercare lavoro)",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS1_6",
            label: "Studiare/frequentare corsi di formazione",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS1_7",
            label: "Pulire, cucinare, riordinare casa o automobile, fare spese",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS1_8",
            label:
              "Prendersi cura di qualcuno o qualcosa (persone, animali, piante)",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS1_9",
            label: "Attività di volontariato",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS1_10",
            label:
              "Attività di svago (es. vita sociale, giocare, chiacchierare, leggere, andare al cinema, suonare uno strumento, ecc.)",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS1_11",
            label: "Pensare, rilassarsi, non fare nulla (senza dormire)",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS1_12",
            label: "Fare sport, attività fisica",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS1_13",
            label: "Spostarsi (a piedi o con bicicletta, auto, mezzi pubblici)",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS1_14",
            label: "Guardare la tv/ascoltare la radio",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS1_15",
            label:
              "Partecipare ad attività religiose (es. andare a messa, pregare, ecc.)",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ],
            lastOfDiario: true
          },
          {
            key: "TUS_INTRO2",
            label: "CON CHI SEI?",
            if: null,
            type: "group-table-row-descrizione"
          },
          {
            key: "TUS1_16",
            label: "Da solo",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ],
            unoSoloPerOgniOra: {
              TUS1_16: true,
              TUS1_17: true
            },
            firstOfDiario: true
          },
          {
            key: "TUS1_17",
            label: "Con altre persone",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ],
            unoSoloPerOgniOra: {
              TUS1_16: true,
              TUS1_17: true
            },
            lastOfDiario: true
          },
          {
            key: "TUS_SPAZIO",
            label: " ",
            if: null,
            type: "group-table-row-descrizione"
          },
          {
            key: "TUS1_18",
            label: "Indica qui se stai usando un telefono o un computer",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ],
            firstOfDiario: true,
            lastOfDiario: true
          }
        ]
      }
    ]
  },
  {
    type: "TUS2",
    name: "DIARIO GIORNALIERO - Giorno 2",
    codiceDataCompletamento: "DATA_24",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Ambulatoriale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Residenziale"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Controllo"
      }
    ],
    domande: [
      {
        key: "DATA_24",
        label: "Data completamento",
        type: "date"
      },
      {
        key: "GIORNO_2",
        label: "Giorno della settimana",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Lunedì"
          },
          {
            value: 1,
            label: "Martedì"
          },
          {
            value: 2,
            label: "Mercoledì"
          },
          {
            value: 3,
            label: "Giovedì"
          },
          {
            value: 4,
            label: "Venerdì"
          },
          {
            value: 5,
            label: "Domenica"
          }
        ]
      },
      {
        type: "group-table",
        header: [
          {
            label: ""
          },
          {
            label: "0"
          },
          {
            label: "1"
          },
          {
            label: "2"
          },
          {
            label: "3"
          },
          {
            label: "4"
          },
          {
            label: "5"
          },
          {
            label: "6"
          },
          {
            label: "7"
          },
          {
            label: "8"
          },
          {
            label: "9"
          },
          {
            label: "10"
          },
          {
            label: "11"
          },
          {
            label: "12"
          },
          {
            label: "13"
          },
          {
            label: "14"
          },
          {
            label: "15"
          },
          {
            label: "16"
          },
          {
            label: "17"
          },
          {
            label: "18"
          },
          {
            label: "19"
          },
          {
            label: "20"
          },
          {
            label: "21"
          },
          {
            label: "22"
          },
          {
            label: "23"
          }
        ],
        rows: [
          {
            key: "TUS2_INTRO1",
            label: "COSA STAI FACENDO?",
            if: null,
            type: "group-table-row-descrizione"
          },
          {
            key: "TUS2_1",
            label: "Dormire",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ],
            firstOfDiario: true
          },
          {
            key: "TUS2_2",
            label: "Stare a letto malato",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS2_3",
            label: "Mangiare/bere/fare colazione/merenda",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS2_4",
            label: "Cura di sé (lavarsi, vestirsi, ecc.)",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS2_5",
            label: "Lavorare o fare stage/tirocinio (o cercare lavoro)",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS2_6",
            label: "Studiare/frequentare corsi di formazione",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS2_7",
            label: "Pulire, cucinare, riordinare casa o automobile, fare spese",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS2_8",
            label:
              "Prendersi cura di qualcuno o qualcosa (persone, animali, piante)",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS2_9",
            label: "Attività di volontariato",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS2_10",
            label:
              "Attività di svago (es. vita sociale, giocare, chiacchierare, leggere, andare al cinema, suonare uno strumento, ecc.)",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS2_11",
            label: "Pensare, rilassarsi, non fare nulla (senza dormire)",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS2_12",
            label: "Fare sport, attività fisica",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS2_13",
            label: "Spostarsi (a piedi o con bicicletta, auto, mezzi pubblici)",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS2_14",
            label: "Guardare la tv/ascoltare la radio",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ]
          },
          {
            key: "TUS2_15",
            label:
              "Partecipare ad attività religiose (es. andare a messa, pregare, ecc.)",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ],
            lastOfDiario: true
          },
          {
            key: "TUS2_INTRO2",
            label: "CON CHI SEI?",
            if: null,
            type: "group-table-row-descrizione"
          },
          {
            key: "TUS2_16",
            label: "Da solo",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ],
            unoSoloPerOgniOra: {
              TUS2_16: true,
              TUS2_17: true
            },
            firstOfDiario: true
          },
          {
            key: "TUS2_17",
            label: "Con altre persone",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ],
            unoSoloPerOgniOra: {
              TUS2_16: true,
              TUS2_17: true
            },
            lastOfDiario: true
          },
          {
            key: "TUS2_SPAZIO",
            label: " ",
            if: null,
            type: "group-table-row-descrizione"
          },
          {
            key: "TUS2_18",
            label: "Indica qui se stai usando un telefono o un computer",
            if: null,
            type: "group-table-row-radio-button-multi",
            values: [
              {
                value: 0,
                label: "0"
              },
              {
                value: 1,
                label: "1"
              },
              {
                value: 2,
                label: "2"
              },
              {
                value: 3,
                label: "3"
              },
              {
                value: 4,
                label: "4"
              },
              {
                value: 5,
                label: "5"
              },
              {
                value: 6,
                label: "6"
              },
              {
                value: 7,
                label: "7"
              },
              {
                value: 8,
                label: "8"
              },
              {
                value: 9,
                label: "9"
              },
              {
                value: 10,
                label: "10"
              },
              {
                value: 11,
                label: "11"
              },
              {
                value: 12,
                label: "12"
              },
              {
                value: 13,
                label: "13"
              },
              {
                value: 14,
                label: "14"
              },
              {
                value: 15,
                label: "15"
              },
              {
                value: 16,
                label: "16"
              },
              {
                value: 17,
                label: "17"
              },
              {
                value: 18,
                label: "18"
              },
              {
                value: 19,
                label: "19"
              },
              {
                value: 20,
                label: "20"
              },
              {
                value: 21,
                label: "21"
              },
              {
                value: 22,
                label: "22"
              },
              {
                value: 23,
                label: "23"
              }
            ],
            firstOfDiario: true,
            lastOfDiario: true
          }
        ]
      }
    ]
  }
];
