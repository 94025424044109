import components from "../components";
import { get } from "lodash";

export default function getComponent(component) {
  const C = get(components, component.split("/").join("."));
  if (!C || !C.index) {
    console.error("Component not found: " + component);
    return null;
  }
  return C.index;
}
