import React from "react";
import { Component } from "react";
import MainLoggedPage from "app/mobile-ui/MainLoggedPage.js";
import { get } from "lodash";
import { connect } from "react-redux";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <MainLoggedPage user={get(this.props, "user")} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: get(state, "auth.user")
  };
};
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
