import React from "react";
import { Component } from "react";
import PropTypes, { number } from "prop-types";
import classNames from "classnames";
import { Row, Col, Button, Table, Input } from "reactstrap";
import NumberInput from "app/elements/NumberInput";
import DatetimePicker from "app/elements/DatetimePicker";
import { each } from "lodash";

import "./DomandaQuestionario.scss";
import {
  checkDomandaIf,
  domandaMissingRisposta,
  checkForRisposteAuto,
  domandeFacoltative
} from "../../common/utils/questionarioCheck";

function removeMultiChoiceFromValue(risposteString, valueToRemove) {
  const parsedValue = risposteString ? risposteString.split(",") : [];
  const filtered = parsedValue.filter(x => x !== "" + valueToRemove);
  return filtered.join(",");
}

class DomandaQuestionario extends Component {
  static propTypes = {
    questionarioData: PropTypes.object.isRequired,
    domanda: PropTypes.object.isRequired,
    risposte: PropTypes.object.isRequired,
    updateRisposteQuestionario: PropTypes.func.isRequired,
    updateRisposteErrors: PropTypes.object
  };

  setValue(value, othersSet = {}) {
    const {
      risposte,
      domanda,
      updateRisposteQuestionario,
      questionarioData
    } = this.props;
    const newRisposte = {
      ...risposte,
      [domanda.key]: value,
      ...othersSet
    };
    checkForRisposteAuto(questionarioData, newRisposte);
    updateRisposteQuestionario(newRisposte);
  }

  renderError() {
    const { updateRisposteErrors, domanda } = this.props;
    let error =
      updateRisposteErrors &&
      updateRisposteErrors.errors &&
      updateRisposteErrors.errors[domanda.key];

    if (!error) {
      if (domanda.type === "number") {
        const { risposte } = this.props;
        const value = risposte && risposte[domanda.key];
        if (domanda.range && typeof domanda.range.from === "number") {
          if (value < domanda.range.from) {
            error = "Valore inserito non corretto";
          }
        }
        if (domanda.range && typeof domanda.range.to === "number") {
          if (value > domanda.range.to) {
            error = "Valore inserito non corretto";
          }
        }
      }
    }

    return error ? <div className="text-danger">{error}</div> : null;
  }

  renderRadioButton(label, value) {
    const { risposte, domanda } = this.props;
    const isSelected = risposte && risposte[domanda.key] === value;
    return (
      <Button
        color="info"
        outline
        active={isSelected}
        onClick={event => {
          this.setValue(isSelected ? null : value);
          event.target.blur();
        }}
      >
        {label}
      </Button>
    );
  }

  renderRadioButtonMulti(label, value, maxRisposte) {
    const { risposte, domanda } = this.props;
    const parsedValue =
      risposte && risposte[domanda.key] ? risposte[domanda.key].split(",") : [];
    const isSelected = parsedValue.indexOf("" + value) !== -1;
    return (
      <Button
        color="info"
        outline
        active={isSelected}
        onClick={event => {
          const parsedValue =
            risposte && risposte[domanda.key]
              ? risposte[domanda.key].split(",").filter(x => x)
              : [];
          const i = parsedValue.indexOf("" + value);
          let othersSet = {};
          if (i === -1) {
            if (maxRisposte && parsedValue.length >= maxRisposte) {
              alert("Puoi selezionare massimo " + maxRisposte + " risposte");
              event.target.blur();
              return;
            }
            parsedValue.push(value);

            if (domanda.unoSoloPerOgniOra) {
              each(domanda.unoSoloPerOgniOra, (_, key2) => {
                if (domanda.key !== key2) {
                  othersSet[key2] = removeMultiChoiceFromValue(
                    risposte && risposte[key2],
                    value
                  );
                }
              });
            }
          } else {
            parsedValue.splice(i, 1);
          }
          parsedValue.sort((a, b) => a - b);
          this.setValue(parsedValue.join(","), othersSet);
          const target = event.target;
          target.blur();
        }}
      >
        {label}
      </Button>
    );
  }

  renderFreeTextInput() {
    const { risposte, domanda } = this.props;
    const value = (risposte && risposte[domanda.key]) || "";
    return (
      <Input
        value={value}
        onChange={event => {
          this.setValue(event.target.value);
        }}
      />
    );
  }

  renderNumberInput() {
    const { risposte, domanda } = this.props;
    const value = risposte && risposte[domanda.key];
    const min = domanda.range && domanda.range.from;
    const max = domanda.range && domanda.range.to;
    return (
      <Input
        type="number"
        min={typeof min === "number" ? min : null}
        max={typeof max === "number" ? max : null}
        value={typeof value === "number" ? value : ""}
        step={domanda.step || 1}
        onChange={event => {
          const v = parseFloat(event.target.value);
          this.setValue(typeof v === "number" ? v : null);
        }}
        onWheel={event => {
          event.target.blur();
        }}
        disabled={domanda.auto}
      />
    );
  }

  renderDateInput() {
    const { risposte, domanda } = this.props;
    const value = risposte && risposte[domanda.key];
    return (
      <DatetimePicker
        date={true}
        time={false}
        value={value}
        onChange={(event, v) => {
          this.setValue(v);
        }}
      />
    );
  }

  render() {
    const { domanda, risposte, questionarioData } = this.props;
    const { type } = domanda;

    if (!checkDomandaIf(domanda, risposte, questionarioData)) {
      return null;
    }

    const baseClassName = "domanda";

    const missingRisposta = domandaMissingRisposta(domanda, risposte);
    const missingRispostaClassName = missingRisposta
      ? domandeFacoltative[domanda.key]
        ? ""
        : "domanda-missing-risposta"
      : "domanda-has-risposta";

    switch (type) {
      case "radio-button":
      case "radio-button-su-riga":
        return (
          <Row
            className={classNames(
              baseClassName,
              missingRispostaClassName,
              "domanda--" + type
            )}
          >
            <Col>
              <div className="domanda-label">
                {domanda.label}
                {domanda.subtitle && (
                  <div className="text-muted">{domanda.subtitle}</div>
                )}
              </div>
              <div className="domanda-values">
                {domanda.values.map(({ label, value }, index) => {
                  return (
                    <React.Fragment key={index}>
                      {this.renderRadioButton(label, value)}
                    </React.Fragment>
                  );
                })}
              </div>
            </Col>
            <Col sm={12}>{this.renderError()}</Col>
          </Row>
        );

      case "radio-button-multiple-answers":
        return (
          <Row
            className={classNames(
              baseClassName,
              missingRispostaClassName,
              "domanda--" + type
            )}
          >
            <Col>
              <div className="domanda-label">
                {domanda.label}
                {domanda.subtitle && (
                  <div className="text-muted">{domanda.subtitle}</div>
                )}
                <div className="text-muted">Seleziona una o più risposte</div>
              </div>
              <div className="domanda-values">
                {domanda.values.map(({ label, value }, index) => {
                  return (
                    <React.Fragment key={index}>
                      {this.renderRadioButtonMulti(
                        label,
                        value,
                        domanda.maxRisposte
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </Col>
            <Col sm={12}>{this.renderError()}</Col>
          </Row>
        );

      case "group-table":
        return (
          <Row className={classNames(baseClassName, "domanda--group-table")}>
            <Col>
              <Table responsive bordered>
                {domanda.header && (
                  <thead>
                    <tr>
                      {domanda.header.map((child, index) => {
                        return <th key={index}>{child.label}</th>;
                      })}
                    </tr>
                  </thead>
                )}
                <tbody>
                  {domanda.rows.map((child, index) => {
                    return (
                      <DomandaQuestionario
                        key={index}
                        {...this.props}
                        domanda={child}
                        parentDomanda={domanda}
                      />
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        );

      case "group-table-row-radio-button":
      case "group-table-row-radio-button-multi":
        return (
          <tr
            className={classNames(
              type === "group-table-row-radio-button-multi"
                ? null
                : missingRispostaClassName, // no error/success per diari!!
              "domanda-table-row domanda--group-table-row"
            )}
          >
            <td>
              <div className="domanda-label">
                {domanda.label}
                {domanda.subtitle && (
                  <div className="text-muted">{domanda.subtitle}</div>
                )}
              </div>
            </td>
            {domanda.values.map(({ label, value }, index) => {
              return (
                <td key={index}>
                  <div className="domanda-value">
                    {type === "group-table-row-radio-button"
                      ? this.renderRadioButton(label, value)
                      : this.renderRadioButtonMulti(label, value)}
                  </div>
                  <div className="domanda-error">{this.renderError()}</div>
                </td>
              );
            })}
          </tr>
        );

      case "group-table-row-descrizione":
        return (
          <tr
            className={classNames(
              "domanda-table-row domanda--group-table-row group-table-row-descrizione"
            )}
          >
            <td colSpan={this.props.parentDomanda.header.length + 1}>
              <div className="domanda-label">
                {domanda.label}
                {domanda.subtitle && (
                  <div className="text-muted">{domanda.subtitle}</div>
                )}
              </div>
            </td>
          </tr>
        );

      case "free-text":
        return (
          <Row
            className={classNames(
              baseClassName,
              missingRispostaClassName,
              "domanda--" + type
            )}
          >
            <Col>
              <div className="domanda-label">
                {domanda.label}
                {domanda.subtitle && (
                  <div className="text-muted">{domanda.subtitle}</div>
                )}
              </div>
              <div className="domanda-free-text">
                {this.renderFreeTextInput()}
              </div>
            </Col>
            <Col sm={12}>{this.renderError()}</Col>
          </Row>
        );

      case "number":
        return (
          <Row
            className={classNames(
              baseClassName,
              missingRispostaClassName,
              "domanda--" + type
            )}
          >
            <Col>
              <div className="domanda-label">
                {domanda.label}
                {domanda.subtitle && (
                  <div className="text-muted">{domanda.subtitle}</div>
                )}
              </div>
              <div className="domanda-free-text">
                {this.renderNumberInput()}
              </div>
              {domanda.range && (
                <div className="text-muted">
                  {typeof domanda.range.from === "number" ? (
                    <span>
                      Min: {domanda.range.from.toString().replace(".", ",")}
                    </span>
                  ) : null}{" "}
                  {typeof domanda.range.to === "number" ? (
                    <span>
                      Max: {domanda.range.to.toString().replace(".", ",")}
                    </span>
                  ) : null}
                </div>
              )}
            </Col>
            <Col sm={12}>{this.renderError()}</Col>
          </Row>
        );

      case "description":
        return (
          <Row className={classNames(baseClassName, "domanda--" + type)}>
            <Col>
              <div className="domanda-label">
                {domanda.label}
                {domanda.subtitle && (
                  <div className="text-muted">{domanda.subtitle}</div>
                )}
              </div>
            </Col>
          </Row>
        );

      case "date":
        return (
          <Row
            className={classNames(
              baseClassName,
              missingRispostaClassName,
              "domanda--" + type
            )}
          >
            <Col>
              <div className="domanda-label">
                {domanda.label}
                {domanda.subtitle && (
                  <div className="text-muted">{domanda.subtitle}</div>
                )}
              </div>
              <div className="domanda-free-text">{this.renderDateInput()}</div>
            </Col>
            <Col sm={12}>{this.renderError()}</Col>
          </Row>
        );
    }

    return <div className="text-danger">Tipo di domanda sconosciuta</div>;
  }
}

export default DomandaQuestionario;
