import React from "react";
import { Component } from "react";
import { get, map } from "lodash";
import { centroHaControlli } from "common/utils/codiceCentro.js";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Alert,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import fpSet from "lodash/fp/set";
import LegendaColoriQuestionari from "app/elements/LegendaColoriQuestionari.js";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import {
  getControlli,
  getCompletamentoQuestionariPerUtente,
  creaPaziente
} from "app/actions";
import EsmActigrafo from "app/elements/EsmActigrafo.js";
import HaDatiActigrafo from "app/elements/HaDatiActigrafo.js";
import RaccoltaDatiCompletata from "app/elements/RaccoltaDatiCompletata.js";
import StatoQuestionari from "app/elements/StatoQuestionari.js";
import { Link } from "react-router-dom";
import ControlloForm from "app/components/ControlloForm.js";
import onCreateRedirect from "app/elements/onCreateRedirect";
import { connect } from "react-redux";

class ListaControlli extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  creaPaziente = () => {
    this.props
      .creaPaziente(get(this.state, "creaPazienteModal.user"))
      .then(action => {
        if (action.callApiSuccess) {
          onCreateRedirect(
            get(action, "response.data"),
            get(this.props, "history")
          );
        } else {
          this.setState(
            fpSet(
              "creaPazienteModal.error",
              get(action, "response"),
              this.state
            )
          );
        }
      });
  };

  render() {
    return get(this.props, "user.userType") === "Admin" ||
      centroHaControlli(get(this.props, "user.codiceCentro")) ? (
      <Row>
        <Col sm={12}>
          <Card>
            <CardHeader>Lista controlli </CardHeader>

            <CardBody>
              <Row style={{ marginBottom: 10 }}>
                <Col>
                  <Button
                    color="primary"
                    onClick={() => {
                      this.setState(
                        fpSet(
                          "creaPazienteModal",
                          {
                            user: {
                              userType: "Controllo",
                              partecipaObiettivo2: true
                            }
                          },
                          this.state
                        )
                      );
                    }}
                  >
                    Crea partecipante (controllo){" "}
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col>
                  <LegendaColoriQuestionari />
                </Col>
              </Row>

              <Row>
                <Col>
                  <AsyncData loaderAction={this.props.getControlli}>
                    {({ loading, loadingError, data }) => {
                      console.log({ loading, loadingError, data });
                      if (loading) {
                        return <Loading />;
                      }
                      if (loadingError) {
                        return (
                          <Alert color="danger">{loadingError.message}</Alert>
                        );
                      }
                      return (
                        <Table responsive={true} bordered={true}>
                          <thead>
                            <tr>
                              <th>N </th>
                              <th>Codice utente </th>
                              <th>Tipo </th>
                              <th>Età </th>
                              <th>Genere </th>
                              <th>ESM / Actigrafo </th>
                              <th>Dati Actigrafo </th>
                              <th>Raccolta completata </th>
                              <th>Stato </th>
                              <th style={{ width: 150 }}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {map(data, (row, rowIndex) => (
                              <tr key={rowIndex}>
                                <td>{rowIndex + 1}</td>
                                <td>{row.codiceUtente}</td>
                                <td>{row.userType}</td>
                                <td>{row.etaAllInizio}</td>
                                <td>{row.genere}</td>
                                <td>
                                  <EsmActigrafo user={row} />
                                </td>
                                <td>
                                  <HaDatiActigrafo user={row} />
                                </td>
                                <td>
                                  <RaccoltaDatiCompletata user={row} />
                                </td>
                                <td>
                                  <AsyncData
                                    loaderAction={
                                      this.props
                                        .getCompletamentoQuestionariPerUtente
                                    }
                                    loaderArgs={[get(row, "id")]}
                                  >
                                    {({ loading, loadingError, data }) => {
                                      console.log({
                                        loading,
                                        loadingError,
                                        data
                                      });
                                      if (loading) {
                                        return <Loading />;
                                      }
                                      if (loadingError) {
                                        return (
                                          <Alert color="danger">
                                            {loadingError.message}
                                          </Alert>
                                        );
                                      }
                                      return (
                                        <StatoQuestionari
                                          questionariCompletamento={data}
                                          user={row}
                                        />
                                      );
                                    }}
                                  </AsyncData>
                                </td>
                                <td>
                                  <Button
                                    className=""
                                    color="primary"
                                    size="sm"
                                    outline={true}
                                    tag={Link}
                                    to={
                                      "/controlli/" +
                                      get(row, "id") +
                                      "/dettaglio"
                                    }
                                  >
                                    Dettaglio partecipante{" "}
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      );
                    }}
                  </AsyncData>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Modal size="lg" isOpen={!!get(this.state, "creaPazienteModal")}>
          <ModalHeader
            toggle={() => {
              this.setState(fpSet("creaPazienteModal", false, this.state));
            }}
          >
            {!!get(this.state, "creaPazienteModal") && (
              <>Crea partecipante (controllo) </>
            )}
          </ModalHeader>

          <ModalBody>
            {!!get(this.state, "creaPazienteModal") && (
              <>
                <ControlloForm
                  paziente={get(this.state, "creaPazienteModal.user")}
                  updatePaziente={obj => {
                    this.setState(
                      fpSet("creaPazienteModal.user", obj, this.state)
                    );
                  }}
                  pazienteErrors={get(this.state, "creaPazienteModal.error")}
                  isCreate={true}
                />
              </>
            )}
          </ModalBody>

          <ModalFooter>
            {!!get(this.state, "creaPazienteModal") && (
              <>
                {get(this.state, "creaPazienteModal.error") &&
                get(this.state, "creaPazienteModal.error").message ? (
                  <div className="text-danger">
                    {get(this.state, "creaPazienteModal.error").message}
                  </div>
                ) : null}

                <Button
                  color="secondary"
                  onClick={() => {
                    this.setState(
                      fpSet("creaPazienteModal", false, this.state)
                    );
                  }}
                >
                  Annulla{" "}
                </Button>

                <Button
                  color="primary"
                  onClick={() => {
                    this.creaPaziente();
                  }}
                >
                  Crea partecipante (controllo){" "}
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      </Row>
    ) : (
      <Row>
        <Col sm={12}>
          <Alert color="warning">
            Non sei abilitato all&#x27;inserimento dei controlli. Contattaci se
            pensi che questo sia un errore.{" "}
          </Alert>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: get(state, "auth.user")
  };
};
const mapDispatchToProps = {
  getControlli: getControlli,
  getCompletamentoQuestionariPerUtente: getCompletamentoQuestionariPerUtente,
  creaPaziente: creaPaziente
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListaControlli);
