import React from "react";
import { Component } from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";

class RaccoltaDatiCompletata extends Component {
  static propTypes = {
    user: PropTypes.object
  };

  render() {
    const { user } = this.props;

    let content = null;

    const completato = user && user.completato;

    if (completato) {
      content = (
        <span style={{ display: "block", textAlign: "center", fontSize: 18 }}>
          ☑
        </span>
      );
    }

    return content;
  }
}

export default RaccoltaDatiCompletata;
