import React from "react";
import { Component } from "react";
import PropTypes from "prop-types";
import { Prompt } from "react-router-dom";

class PreventClose extends Component {
  static propTypes = {
    shouldPrevent: PropTypes.bool,
    message: PropTypes.any.isRequired
  };

  componentDidMount() {
    window.onbeforeunload = this.onWindowsBeforeUnload;
  }

  componentWillUnmount() {
    if (window.onbeforeunload === this.onWindowsBeforeUnload) {
      window.onbeforeunload = null;
    }
  }

  shouldPreventClose() {
    const { shouldPrevent } = this.props;
    return shouldPrevent;
  }

  getMessage() {
    return this.props.message;
  }

  onWindowsBeforeUnload = () => {
    const isBlockingClose = this.shouldPreventClose();
    if (!isBlockingClose) {
      return;
    }
    return this.getMessage();
  };

  render() {
    const isBlockingClose = this.shouldPreventClose();

    return (
      <Prompt when={isBlockingClose} message={location => this.getMessage()} />
    );
  }
}

export default PreventClose;
