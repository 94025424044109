import React from "react";
import { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Alert,
  Button,
  Table
} from "reactstrap";
import { get, map } from "lodash";
import { Link } from "react-router-dom";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import { exportDataListaUtentiConDatiActigrafo } from "app/actions";
import LinkDownloadFileActigrafo from "app/elements/LinkDownloadFileActigrafo.js";
import { connect } from "react-redux";

class EsportaDati extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Row>
        <Col sm={12}>
          <Card>
            <CardHeader>Esporta dati </CardHeader>

            <CardBody>
              {get(this.props, "user.userType") == "Operatore" ? (
                get(this.props, "user.adminCentro") ? (
                  <Row>
                    <Col sm={12}>
                      <Alert color="info">
                        Puoi scaricare solo i dati del tuo centro{" "}
                      </Alert>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col sm={12}>
                      <Alert color="info">
                        Puoi scaricare solo i dati dei tuoi pazienti{" "}
                      </Alert>
                    </Col>
                  </Row>
                )
              ) : null}

              <Row>
                <Col sm={12}>
                  <Button
                    color="primary"
                    size="lg"
                    tag={Link}
                    to={"/api/export/data"}
                    target={"_blank"}
                  >
                    Scarica tutti i questionari{" "}
                  </Button>

                  <div className="text-muted">
                    Scarica i dati raccolti con i questionari{" "}
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 30 }}>
                <Col sm={12}>
                  <Button
                    color="primary"
                    size="lg"
                    tag={Link}
                    to={"/api/export/dataESM"}
                    target={"_blank"}
                  >
                    Scarica dati ESM{" "}
                  </Button>

                  <div className="text-muted">
                    Scarica i dati relativi all&#x27;ESM{" "}
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 30 }}>
                <Col sm={12}>
                  <Button
                    color="primary"
                    size="lg"
                    tag={Link}
                    to={"/api/export/dataDiari"}
                    target={"_blank"}
                  >
                    Scarica dati diari{" "}
                  </Button>

                  <div className="text-muted">
                    Scarica i dati relativi ai diari{" "}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col sm={12}>
          <Card>
            <CardHeader>Dati actigrafo </CardHeader>

            <CardBody>
              <Row>
                <Col sm={12}>
                  <AsyncData
                    loaderAction={
                      this.props.exportDataListaUtentiConDatiActigrafo
                    }
                  >
                    {({ loading, loadingError, data }) => {
                      console.log({ loading, loadingError, data });
                      if (loading) {
                        return <Loading />;
                      }
                      if (loadingError) {
                        return (
                          <Alert color="danger">{loadingError.message}</Alert>
                        );
                      }
                      return (
                        <Table responsive={true} bordered={true} striped={true}>
                          <thead>
                            <tr>
                              <th style={{}}>Codice soggetto </th>
                              <th style={{}}>Tipo di soggetto </th>
                              <th style={{}}>Centro </th>
                              <th style={{}}>File </th>
                            </tr>
                          </thead>
                          <tbody>
                            {map(data, (row, rowIndex) => (
                              <tr key={rowIndex}>
                                <td style={{}}>{row.codiceUtente}</td>
                                <td style={{}}>{row.userType}</td>
                                <td style={{}}>{row.codiceCentro}</td>
                                <td style={{}}>
                                  <LinkDownloadFileActigrafo user={row} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      );
                    }}
                  </AsyncData>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = {
  exportDataListaUtentiConDatiActigrafo: exportDataListaUtentiConDatiActigrafo
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EsportaDati);
