import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import DebugInfo from "./elements/DebugInfo";
import { routes } from "common/routes";
import LayoutWrapper from "./pages/LayoutWrapper";

class App extends Component {
  render() {
    const DebugInfoOrNull =
      process.env.NODE_ENV !== "production" ? DebugInfo : null;

    return (
      <>
        {DebugInfoOrNull && <DebugInfoOrNull />}
        <BrowserRouter>
          <Switch>
            {routes.map(function(route, index) {
              const routeRender = props => (
                <LayoutWrapper route={route} {...props} />
              );

              if (!route.path) {
                return <Route key={index} render={routeRender} />;
              }
              return (
                <Route
                  key={index}
                  exact={true}
                  path={route.path}
                  render={routeRender}
                />
              );
            })}
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

let _App = App;

if (process.env.NODE_ENV === "development") {
  const hot = require("react-hot-loader/root").hot;
  _App = hot(_App);
}

export default _App;
