import { find, some } from "lodash";

export const sezioni = [
  {
    domande: [
      {
        key: "cosa_stai_facendo",
        label: "In questo momento, quale attività stai svolgendo?",
        type: "multi-choice",
        options: [
          {
            label: "Dormire",
            value: 1
          },
          {
            label: "Stare a letto malato",
            value: 2
          },
          {
            label: "Mangiare/bere/fare colazione/merenda",
            value: 3
          },
          {
            label: "Cura di sé (lavarsi, vestirsi, ecc.)",
            value: 4
          },
          {
            label: "Lavorare o fare stage/tirocinio (o cercare lavoro)",
            value: 5
          },
          {
            label: "Studiare/frequentare corsi di formazione",
            value: 6
          },
          {
            label: "Pulire, cucinare, riordinare casa o automobile, fare spese",
            value: 7
          },
          {
            label:
              "Prendersi cura di qualcuno o qualcosa (persone, animali, piante)",
            value: 8
          },
          {
            label: "Attività di volontariato",
            value: 9
          },
          {
            label:
              "Attività di svago (es. vita sociale, giocare, chiacchierare, leggere, andare al cinema, suonare uno strumento, ecc.)",
            value: 10
          },
          {
            label: "Pensare, riposarsi, non fare nulla (senza dormire)",
            value: 11
          },
          {
            label: "Fare sport, attività fisica",
            value: 12
          },
          {
            label: "Spostarsi (a piedi o con bicicletta, auto, mezzi pubblici)",
            value: 13
          },
          {
            label: "Guardare la tv/ascoltare la radio",
            value: 14
          },
          {
            label:
              "Partecipare ad attività religiose (es. andare a messa, pregare, ecc.)",
            value: 15
          }
        ]
      },
      {
        key: "con_chi_sei",
        label: "In questo momento, con chi sei?",
        type: "single-choice",
        options: [
          {
            label: "Da solo",
            value: 16
          },
          {
            label: "Con altre persone",
            value: 17
          }
        ]
      }
    ]
  },
  {
    randomSort: true,
    domande: [
      "Felice",
      "Triste",
      "Stanco/a",
      "Rilassato/a",
      "Agitato/a",
      "Tranquillo/a",
      "Pieno/a di energie"
    ].map(stato => ({
      preLabel: "In questo momento, mi sento...",
      key: stato,
      label: stato,
      type: "barra0-100"
    }))
  }
];

export const domandaGiornataSpeciale = {
  key: "giornata_tipica",
  label: "Pensando ad oggi, diresti che è stata una giornata tipica per te?",
  type: "single-choice",
  options: [
    {
      label: "Sì",
      value: 1
    },
    {
      label: "No",
      value: 2
    }
  ]
};

export const domandaGiornataSpeciale2 = {
  key: "giornata_non_tipica_motivo",
  label: "Specifica il motivo:",
  type: "text",
  ifDomandaGiornataSpecialeValue: 2
};

export function getPrimaDomandaNonRisposta(notifica, sezioni, risposte, user) {
  let domandaAttuale = null;
  find(sezioni, s => {
    return find(s.domande, d => {
      if (risposte[d.key] == null) {
        domandaAttuale = d;
        return true;
      }
      return false;
    });
  });

  if (!domandaAttuale) {
    if (notifica.domandaGiornataSpeciale) {
      // DOMANDA SPECIALE!
      // check if we have already answered for this day!
      const alreadyDone = some(user.notifiche || [], n => {
        if (isSameDay(n.questionarioKey, notifica.questionarioKey)) {
          if (
            n.risposte &&
            // ha risposto alla domanda speciale
            (n.risposte[domandaGiornataSpeciale.key] != null &&
              // e doveva rispondere alla seconda domanda e ha risposto
              (n.risposte[domandaGiornataSpeciale.key] !==
                domandaGiornataSpeciale2.ifDomandaGiornataSpecialeValue ||
                n.risposte[domandaGiornataSpeciale2.key]))
          ) {
            return true;
          }
        }
        return false;
      });

      if (!alreadyDone) {
        if (risposte[domandaGiornataSpeciale.key] == null) {
          domandaAttuale = domandaGiornataSpeciale;
        } else if (
          risposte[domandaGiornataSpeciale.key] ===
            domandaGiornataSpeciale2.ifDomandaGiornataSpecialeValue &&
          !risposte[domandaGiornataSpeciale2.key]
        ) {
          domandaAttuale = domandaGiornataSpeciale2;
        }
      }
    }
  }

  return domandaAttuale;
}

export function checkCompletato(notifica, user) {
  const risposte = notifica.risposte || {};
  let domandaAttuale = getPrimaDomandaNonRisposta(
    notifica,
    sezioni,
    risposte,
    user
  );
  if (!domandaAttuale && !notifica.dataCompilazione) {
    notifica.dataCompilazione = new Date().toISOString();
  }
}

function getKeyDayPrefix(key) {
  return key.replace(/_n_[0-9]+/, "");
}

function isSameDay(key1, key2) {
  return getKeyDayPrefix(key1) === getKeyDayPrefix(key2);
}
