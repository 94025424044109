import React from "react";
import { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Alert
} from "reactstrap";
import { get, map } from "lodash";
import fpSet from "lodash/fp/set";
import {
  getCodiciCentroPossibilePerUser,
  centroHaEsmActigrafo
} from "common/utils/codiceCentro.js";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import { getOperatori } from "app/actions";
import DatetimePicker from "app/elements/DatetimePicker";
import NumberInput from "app/elements/NumberInput";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class PazienteForm extends Component {
  static propTypes = {
    paziente: PropTypes.object.isRequired,
    updatePaziente: PropTypes.func,
    pazienteErrors: PropTypes.object,
    isCreate: PropTypes.bool,
    isEditable: PropTypes.bool
  };

  static defaultProps = {
    isCreate: false,
    isEditable: true
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Row>
        <Col sm="12">
          <Form>
            <FormGroup>
              <Label>Codice paziente </Label>

              <Input
                type="text"
                placeholder="Autogenerato..."
                disabled={{
                  elementType: "variable",
                  context: "constant",
                  value: true
                }}
                value={get(this.props, "paziente.codiceUtente") || ""}
                onChange={event => {
                  this.props.updatePaziente(
                    fpSet(
                      "codiceUtente",
                      event.target.value,
                      this.props.paziente
                    )
                  );
                }}
                invalid={
                  !!get(this.props, "pazienteErrors.errors.codiceUtente")
                }
              />
            </FormGroup>

            <FormGroup>
              <Label>Centro </Label>

              <Input
                type="select"
                value={get(this.props, "paziente.codiceCentro") || ""}
                onChange={event => {
                  this.props.updatePaziente(
                    fpSet(
                      "codiceCentro",
                      event.target.value,
                      this.props.paziente
                    )
                  );
                }}
                invalid={
                  !!get(this.props, "pazienteErrors.errors.codiceCentro")
                }
              >
                <option></option>
                {map(
                  getCodiciCentroPossibilePerUser(get(this.props, "user")),
                  (option, index) => {
                    const value = get(option, "codiceCentro");
                    const label = option.label;
                    return (
                      <option key={index} value={value}>
                        {label}
                      </option>
                    );
                  }
                )}
              </Input>

              <FormFeedback>
                {get(this.props, "pazienteErrors.errors.codiceCentro")}
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label>Tipo di paziente </Label>

              <Input
                type="select"
                value={get(this.props, "paziente.userType") || ""}
                onChange={event => {
                  this.props.updatePaziente(
                    fpSet("userType", event.target.value, this.props.paziente)
                  );
                }}
                invalid={!!get(this.props, "pazienteErrors.errors.userType")}
              >
                <option value={""}></option>
                <option value={"Residenziale"}>Residenziale</option>
                <option value={"Ambulatoriale"}>Ambulatoriale</option>
              </Input>

              <FormFeedback>
                {get(this.props, "pazienteErrors.errors.userType")}
              </FormFeedback>
            </FormGroup>

            {get(this.props, "paziente.userType") == "Residenziale" ? (
              <FormGroup>
                <Label>
                  Operatore di riferimento (solo pazienti residenziali){" "}
                </Label>

                <AsyncData loaderAction={this.props.getOperatori}>
                  {({ loading, loadingError, data }) => {
                    console.log({ loading, loadingError, data });
                    if (loading) {
                      return <Loading />;
                    }
                    if (loadingError) {
                      return (
                        <Alert color="danger">{loadingError.message}</Alert>
                      );
                    }
                    return (
                      <Input
                        type="select"
                        value={
                          get(this.props, "paziente.operatoreAssociatoId") || ""
                        }
                        onChange={event => {
                          this.props.updatePaziente(
                            fpSet(
                              "operatoreAssociatoId",
                              event.target.value,
                              this.props.paziente
                            )
                          );
                        }}
                        invalid={
                          !!get(
                            this.props,
                            "pazienteErrors.errors.operatoreAssociatoId"
                          )
                        }
                      >
                        <option></option>
                        {map(data, (option, index) => {
                          const value = get(option, "id");
                          const label = get(option, "codiceUtente");
                          return (
                            <option key={index} value={value}>
                              {label}
                            </option>
                          );
                        })}
                      </Input>
                    );
                  }}
                </AsyncData>
              </FormGroup>
            ) : null}

            <hr />

            <h5>Dati paziente </h5>

            <FormGroup>
              <Label>Data di nascita </Label>

              <DatetimePicker
                date={true}
                value={get(this.props, "paziente.dataDiNascita") || null}
                onChange={(event, value) => {
                  this.props.updatePaziente(
                    fpSet("dataDiNascita", value, this.props.paziente)
                  );
                }}
                invalid={
                  !!get(this.props, "pazienteErrors.errors.dataDiNascita")
                }
              />
            </FormGroup>

            <FormGroup>
              <Label>Età </Label>

              <NumberInput
                value={get(this.props, "paziente.etaAllInizio") || null}
                onChange={(event, value) => {
                  this.props.updatePaziente(
                    fpSet("etaAllInizio", value, this.props.paziente)
                  );
                }}
                invalid={
                  !!get(this.props, "pazienteErrors.errors.etaAllInizio")
                }
              />
            </FormGroup>

            <FormGroup>
              <Label>Genere </Label>

              <Input
                type="select"
                value={get(this.props, "paziente.genere") || ""}
                onChange={event => {
                  this.props.updatePaziente(
                    fpSet("genere", event.target.value, this.props.paziente)
                  );
                }}
                invalid={!!get(this.props, "pazienteErrors.errors.genere")}
              >
                <option value={""}></option>
                <option value={"Maschio"}>Maschio</option>
                <option value={"Femmina"}>Femmina</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label>Scolarità (in anni) </Label>

              <NumberInput
                value={get(this.props, "paziente.scolaritaAnni") || null}
                onChange={(event, value) => {
                  this.props.updatePaziente(
                    fpSet("scolaritaAnni", value, this.props.paziente)
                  );
                }}
                invalid={
                  !!get(this.props, "pazienteErrors.errors.scolaritaAnni")
                }
              />
            </FormGroup>

            <hr />

            <h5>Dati sperimentazione </h5>

            <FormGroup style={{ marginTop: 20 }} check={true}>
              <Input
                type="checkbox"
                checked={!!get(this.props, "paziente.completato")}
                onChange={event => {
                  this.props.updatePaziente(
                    fpSet(
                      "completato",
                      event.target.checked,
                      this.props.paziente
                    )
                  );
                }}
                invalid={!!get(this.props, "pazienteErrors.errors.completato")}
              />

              <Label check={true}>Raccolta dati completata </Label>
            </FormGroup>

            <FormGroup style={{ marginTop: 20 }} check={true}>
              <Input
                type="checkbox"
                checked={!!get(this.props, "paziente.ritirato")}
                onChange={event => {
                  this.props.updatePaziente(
                    fpSet("ritirato", event.target.checked, this.props.paziente)
                  );
                }}
                invalid={!!get(this.props, "pazienteErrors.errors.ritirato")}
              />

              <Label check={true}>Il paziente si è ritirato </Label>
            </FormGroup>

            {get(this.props, "paziente.ritirato") ? (
              <div style={{ marginTop: 20, marginLeft: 30 }}>
                <FormGroup>
                  <Label>Motivazione ritiro: </Label>

                  <Input
                    type="select"
                    value={
                      get(this.props, "paziente.ritiratoMotivazione") || ""
                    }
                    onChange={event => {
                      this.props.updatePaziente(
                        fpSet(
                          "ritiratoMotivazione",
                          event.target.value,
                          this.props.paziente
                        )
                      );
                    }}
                    invalid={
                      !!get(
                        this.props,
                        "pazienteErrors.errors.ritiratoMotivazione"
                      )
                    }
                  >
                    <option value={""}></option>
                    <option value={0}>Troppo impegnato</option>
                    <option value={1}>Rifiuta la valutazione iniziale</option>
                    <option value={3}>Nessuna ragione specifica</option>
                    <option value={4}>
                      Spostamento di DSM di riferimento, motivi di salute,
                      cambio area geografica
                    </option>
                    <option value={5}>Non vuole spiegare</option>
                  </Input>

                  <FormFeedback>
                    {get(this.props, "pazienteErrors.errors.userType")}
                  </FormFeedback>
                </FormGroup>

                <FormGroup style={{ marginTop: 20 }} check={true}>
                  <Input
                    type="checkbox"
                    checked={!!get(this.props, "paziente.ritiratoUsoDati")}
                    onChange={event => {
                      this.props.updatePaziente(
                        fpSet(
                          "ritiratoUsoDati",
                          event.target.checked,
                          this.props.paziente
                        )
                      );
                    }}
                    invalid={
                      !!get(this.props, "pazienteErrors.errors.ritiratoUsoDati")
                    }
                  />

                  <Label check={true}>
                    Il paziente/soggetto ha accettato che i dati già raccolti
                    possono essere utilizzati per l&#x27;analisi dei dati{" "}
                  </Label>
                </FormGroup>
              </div>
            ) : null}

            {centroHaEsmActigrafo(get(this.props, "paziente.codiceCentro")) ? (
              <FormGroup style={{ marginTop: 20 }} check={true}>
                <Input
                  type="checkbox"
                  checked={!!get(this.props, "paziente.partecipaObiettivo2")}
                  onChange={event => {
                    this.props.updatePaziente(
                      fpSet(
                        "partecipaObiettivo2",
                        event.target.checked,
                        this.props.paziente
                      )
                    );
                  }}
                  invalid={
                    !!get(
                      this.props,
                      "pazienteErrors.errors.partecipaObiettivo2"
                    )
                  }
                />

                <Label check={true}>
                  Il paziente partecipa allo studio ESM/Actigrafo{" "}
                </Label>
              </FormGroup>
            ) : null}

            {get(this.props, "paziente.partecipaObiettivo2") &&
            get(this.props, "paziente.id") ? (
              <Row style={{ marginTop: 20 }}>
                <Col md={6}>
                  <FormGroup>
                    <Label>Data di inizio </Label>

                    <DatetimePicker
                      date={true}
                      value={
                        get(this.props, "paziente.inizioObiettivo2") || null
                      }
                      onChange={(event, value) => {
                        this.props.updatePaziente(
                          fpSet("inizioObiettivo2", value, this.props.paziente)
                        );
                      }}
                      invalid={
                        !!get(
                          this.props,
                          "pazienteErrors.errors.inizioObiettivo2"
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            ) : null}
          </Form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: get(state, "auth.user")
  };
};
const mapDispatchToProps = {
  getOperatori: getOperatori
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PazienteForm);
