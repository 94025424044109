// before React is loaded... disable react devtools in production
if (process.env.NODE_ENV === "production") {
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
    __REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function() {};
  }
}

import "abortcontroller-polyfill";
import "./fetch.js";
import "react-app-polyfill/ie9";
import "core-js";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./configureStore";

// disable right-click

// check if mobile
import MobileDetect from "mobile-detect";
const md = new MobileDetect(window.navigator && window.navigator.userAgent);
if (md.mobile()) {
  document.body.classList.add("app-mobile");
}
if (md.phone()) {
  document.body.classList.add("app-phone");
}
if (md.tablet()) {
  document.body.classList.add("app-tablet");
}
const chromeVersion = md.version("Chrome");
if (chromeVersion) {
  document.body.classList.add("app-chrome");
}

// Styles
import "./style.scss";

// Locale
import "./setupLocale";

const store = configureStore();

// Import components after the store has been created!

import App from "./App.js";
import closeLoadingScreen from "./closeLoadingScreen";

let render = AppComponent => {
  let RenderComponent = (
    <Provider store={store}>
      <AppComponent />
    </Provider>
  );

  ReactDOM.render(RenderComponent, document.getElementById("root"), () =>
    closeLoadingScreen()
  );
};

render(App);
