import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import apiMiddleware from "./middleware/api";

const middlewares = [thunk, apiMiddleware];

if (process.env.NODE_ENV === `development`) {
  const { createLogger } = require(`redux-logger`);
  const loggerMiddleware = createLogger();
  middlewares.push(loggerMiddleware);
}

var preloadedState = window.__PRELOADED_STATE__ || {};
delete window.__PRELOADED_STATE__;

export default function configureStore() {
  const store = createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(...middlewares)
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./reducers", () => {
      const nextRootReducer = require("./reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
