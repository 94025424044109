function getFromUrl() {
  const q = window && window.location && window.location.search;

  if (q) {
    const index = q.indexOf("ent=");
    if (index !== -1) {
      const token = q.substring(index + 4);
      return token && decodeURIComponent(token);
    }
  }
}

export function mobileUiGetPushNotificationToken() {
  let token =
    (window && window.EXPO_NOTIFICATION_TOKEN) || getFromUrl() || null;

  console.log("mobileUiGetPushNotificationToken: ", token);

  return token;
}
