import React from "react";
import { Component } from "react";
import { Badge } from "reactstrap";

import "./LegendaColoriQuestionari.scss";

const colori = [
  {
    color: "success",
    descrizione: "Completato"
  },
  {
    color: "warning",
    descrizione: "Iniziato ma non completato"
  },
  {
    color: "danger",
    descrizione: "Non iniziato"
  }
];

class LegendaColoriQuestionari extends Component {
  render() {
    return (
      <div className="legenda-colori-questionari">
        {colori.map((coloreObject, i) => {
          return (
            <div key={i} className="legenda-colori-questionari-item">
              <Badge color={coloreObject.color}> </Badge>
              <span className="legenda-colori-questionari-item--descrizione">
                {coloreObject.descrizione}
              </span>
            </div>
          );
        })}
      </div>
    );
  }
}

export default LegendaColoriQuestionari;
