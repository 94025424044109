const codiciCentri = [
  // {
  //   codiceCentro: 'BS001',
  //   nomeCentro: 'Brescia ...'
  // },
  // {
  //   codiceCentro: 'MI002',
  //   nomeCentro: 'Milano ...'
  // },

  {
    codiceCentro: "AN01",
    nomeCentro: "CSM di Ancona - SRR CASA ROSSA ",
    haControlli: false
  },
  {
    codiceCentro: "AQ01",
    nomeCentro: "CTR PASSAGGI Srl- Oricola",
    haControlli: false
  },
  {
    codiceCentro: "BA01",
    nomeCentro: "DSM ASL Bari",
    haControlli: false
  },
  {
    codiceCentro: "BG01",
    nomeCentro: "DSMD ASST Bergamo Ovest",
    haControlli: false
  },
  {
    codiceCentro: "BS01",
    nomeCentro: "DSMD ASST Spedali Civili di Brescia",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "BS02",
    nomeCentro: "IRCCS Fatebenefratelli Brescia",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "CA01",
    nomeCentro:
      "Centro Ippocrate - SR RESIDENZA TURCHESE e SR RESIDENZA SMERALDO",
    haControlli: false
  },
  {
    codiceCentro: "MI01",
    nomeCentro: "Fatebenefratelli Sant’Ambrogio - Centro Sacro Cuore di Gesù",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "CR01",
    nomeCentro: "DSMD ASST di Cremona",
    haControlli: false
  },
  {
    codiceCentro: "PO01",
    nomeCentro: "DSM USL Toscana Centro - DSM Prato",
    haControlli: false
  },
  {
    codiceCentro: "MI02",
    nomeCentro: "DSMD ASST Rhodense - CRA RHO e CRA BOLLATE ",
    haControlli: false
  },
  {
    codiceCentro: "GE01",
    nomeCentro: "DSMD ASL 3 - Distretto 8 e Distretto 11",
    haControlli: false
  },
  {
    codiceCentro: "BG02",
    nomeCentro: "CREST ‘La Perla’",
    haControlli: false
  },
  {
    codiceCentro: "LO01",
    nomeCentro: "DSMD ASST di Lodi",
    haControlli: false
  },
  {
    codiceCentro: "MI03",
    nomeCentro:
      "DSMD ASST di Melegnano e della Martesana - CPS Gorgonzola, CRA Vaprio d'adda, CRA Melegnano",
    haControlli: false
  },
  {
    codiceCentro: "MI04",
    nomeCentro: "Fondazione Castellini ONLUS - La casa di Anania",
    haControlli: false
  },
  {
    codiceCentro: "MO01",
    nomeCentro: "DSMDP AUSL Modena",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "MB01",
    nomeCentro: "DSMD ASST di Monza - CRA Cesano Maderno e CRA Brugherio",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "NA01",
    nomeCentro: "DSM ASL Napoli 2 Nord",
    haControlli: false
  },
  {
    codiceCentro: "PR01",
    nomeCentro: "DSMDP AUSL Parma",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "PV01",
    nomeCentro: "Fondazione Giuseppe Costantino Pavia",
    haControlli: false
  },
  {
    codiceCentro: "PV02",
    nomeCentro: "DSMD ASST Pavia",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "PE01",
    nomeCentro: "DSM ASL Pescara - CSM area Montana",
    haControlli: false
  },
  {
    codiceCentro: "RM01",
    nomeCentro:
      "DSM ASL Roma1 - UOC Distretto 1 (SR Montesanto, SR Comunità Sabrata, SR Marcigliana)",
    haControlli: false
  },
  {
    codiceCentro: "TE01",
    nomeCentro: "DSM ASL Teramo",
    haControlli: false
  },
  {
    codiceCentro: "TO01",
    nomeCentro: "P.O. Fatebenefratelli Beata Vergine Consolata ",
    haControlli: false
  },
  {
    codiceCentro: "TO02",
    nomeCentro: "DSM ASL Torino 5",
    haControlli: false
  },
  {
    codiceCentro: "TO03",
    nomeCentro: "DSM ASL Città di Torino",
    haControlli: false
  },
  {
    codiceCentro: "TO04",
    nomeCentro: "Associazione Il Porto ONLUS",
    haControlli: false
  },
  {
    codiceCentro: "TO05",
    nomeCentro: "Progetto Du Parc",
    haControlli: false
  },
  {
    codiceCentro: "TN01",
    nomeCentro: "DSM APSS PA di Trento -- centro di Rovereto",
    haControlli: false
  },
  {
    codiceCentro: "TV01",
    nomeCentro: "DSM Azienda ULSS 2 Marca Trevigiana ",
    haControlli: false
  },
  {
    codiceCentro: "MI05",
    nomeCentro: " Le Vele ONLUS ‘Villa Gatta’",
    haControlli: false
  },
  {
    codiceCentro: "TS01",
    nomeCentro:
      "DSM ASUITS Azienda Sanitaria Universitaria Integrata di Trieste",
    haControlli: false
  },
  {
    codiceCentro: "MI06",
    nomeCentro: "Le Vele ONLUS La Nostra Locanda ",
    haControlli: false
  },
  {
    codiceCentro: "VR01",
    nomeCentro: 'CTRP tipo "A" - Associazione Don Giuseppe Girelli',
    haControlli: false
  },
  {
    codiceCentro: "VR02",
    nomeCentro: "DSM Azienda Ospedaliera Universitaria Integrata di Verona",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "VI01",
    nomeCentro: "DSM Azienda ULSS 8 Berica",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "VA01",
    nomeCentro: "CREST di Vinago",
    haControlli: false
  }
];

codiciCentri.forEach(obj => {
  obj.label = obj.codiceCentro + " - " + obj.nomeCentro;
});

export const codiceCentroMap = {};
codiciCentri.forEach(obj => {
  codiceCentroMap[obj.codiceCentro] = obj;
});

export function getCodiciCentroPossibilePerUser(user) {
  if (user.userType === "Admin") {
    return codiciCentri;
  }
  return [codiceCentroMap[user.codiceCentro]];
}

export function centroHaControlli(codiceCentro) {
  return (
    codiceCentroMap[codiceCentro] && codiceCentroMap[codiceCentro].haControlli
  );
}

export function centroHaEsmActigrafo(codiceCentro) {
  return (
    (codiceCentroMap[codiceCentro] &&
      codiceCentroMap[codiceCentro].haEsmActigrafo) ||
    false
  );
}
