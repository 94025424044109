import React from "react";
import { Row, Col, Button, Input } from "reactstrap";
import {
  sezioni,
  getPrimaDomandaNonRisposta
} from "../../common/utils/domadeQuestionarioMobile";
import { find, shuffle } from "lodash";
import Barra0_100 from "./Barra0_100";

if (
  window &&
  window.location &&
  window.location.pathname &&
  window.location.pathname.startsWith("/mobile-app/ui/")
) {
  document.body.classList.add("diapason-mobile-ui");
}

export default class NotificaContainer extends React.Component {
  state = {
    risposte: {}
  };

  isSelected(domandaAttuale, option) {
    const risposte = this.state.risposte || {};
    if (domandaAttuale.type === "multi-choice") {
      const a = (risposte[domandaAttuale.key] || "").split(",");
      return a.indexOf("" + option.value) !== -1;
    }
    return risposte[domandaAttuale.key] === option.value;
  }

  toggle(domandaAttuale, option) {
    const risposte = this.state.risposte || {};
    let newValue;
    if (domandaAttuale.type === "multi-choice") {
      const a = (risposte[domandaAttuale.key] || "").split(",").filter(x => x);
      if (a.indexOf("" + option.value) === -1) {
        a.push("" + option.value);
      } else {
        a.splice(a.indexOf("" + option.value), 1);
      }
      newValue = a.length > 0 ? a.join(",") : null;
    } else {
      const wasSelected = this.isSelected(domandaAttuale, option);
      newValue = wasSelected ? null : option.value;
    }

    const newRisposte = {
      ...risposte,
      [domandaAttuale.key]: newValue
    };

    this.setState({
      risposte: newRisposte
    });
  }

  getDomandaValue(domandaAttuale) {
    const risposte = this.state.risposte || {};
    return risposte[domandaAttuale.key];
  }

  setDomandaValue(domandaAttuale, v) {
    const risposte = this.state.risposte || {};
    const newRisposte = {
      ...risposte,
      [domandaAttuale.key]: v
    };
    this.setState({
      risposte: newRisposte
    });
  }

  onNext = () => {
    const risposte = this.state.risposte || {};
    this.props.setRisposte(risposte);
  };

  onInputCreated = inputEl => {
    setTimeout(() => {
      inputEl && inputEl.focus();
    }, 400);
  };

  renderDomanda(domandaAttuale) {
    return (
      <>
        <div className="mobile-notifica-domanda">
          {domandaAttuale.preLabel && (
            <div className="pre-label">{domandaAttuale.preLabel}</div>
          )}
          <div className="domanda-label">{domandaAttuale.label}</div>
          {domandaAttuale.options && (
            <table>
              <tbody>
                {domandaAttuale.options.map((o, i) => {
                  let selected = this.isSelected(domandaAttuale, o);
                  let onClick = e => {
                    e.preventDefault();
                    this.toggle(domandaAttuale, o);
                    const target = e.target;
                    target.blur();
                  };
                  return (
                    <tr key={i}>
                      <td>
                        <Button
                          color="primary"
                          outline={!selected}
                          onClick={onClick}
                        >
                          {o.label}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {domandaAttuale.type === "barra0-100" && (
            <Barra0_100
              value={this.getDomandaValue(domandaAttuale)}
              onChange={v => {
                this.setDomandaValue(domandaAttuale, v);
              }}
            />
          )}
          {domandaAttuale.type === "text" && (
            <Input
              innerRef={this.onInputCreated}
              className="text-value"
              autoFocus={true}
              placeholder="Inserisci qui il motivo"
              value={this.getDomandaValue(domandaAttuale) || ""}
              onChange={e => {
                const v = e.target.value || null;
                this.setDomandaValue(domandaAttuale, v);
              }}
            />
          )}
        </div>
      </>
    );
  }

  renderNoMoreDomande() {
    return (
      <div>
        <h4>Grazie!</h4>
        <p>La tua partecipazione è importante.</p>
        <p style={{ marginTop: 50 }}>Ora puoi chiudere l'applicazione.</p>
      </div>
    );
  }

  renderBottomBar(domandaAttuale) {
    const risposte = this.state.risposte || {};
    const isOk = risposte[domandaAttuale.key] != null;
    return (
      <div className="mobile-domanda-bottom-bar">
        <Button
          color="success"
          outline={!isOk}
          disabled={!isOk}
          onClick={this.onNext}
        >
          Avanti
        </Button>
      </div>
    );
  }

  getSezioni = () => {
    if (!this._randomSezioni) {
      this._randomSezioni = sezioni.map(s => {
        if (s.randomSort) {
          return {
            ...s,
            domande: shuffle(s.domande)
          };
        } else {
          return s;
        }
      });
    }
    return this._randomSezioni;
  };

  renderContent() {
    const { user, notifica } = this.props;
    const risposte = notifica.risposte || {};

    let domandaAttuale = getPrimaDomandaNonRisposta(
      notifica,
      this.getSezioni(),
      risposte,
      user
    );

    return (
      <div className="mobile-notifica-container">
        {domandaAttuale
          ? this.renderDomanda(domandaAttuale)
          : this.renderNoMoreDomande()}
        {domandaAttuale ? this.renderBottomBar(domandaAttuale) : null}
      </div>
    );
  }

  render() {
    return <>{this.renderContent()}</>;
  }
}
